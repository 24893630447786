const i18n =  {
    BUS: 'bus',
    WALK: 'walk',
    BIKE: 'velo',
    TRAIN: 'train',
    BUBBLEBIKE: 'velobulle',
    CAR: 'auto',
    TAKE_BUS: 'à l\'arrêt ',
    EXIT_BUS: 'et sortir à l\'arrêt ',
    TAKE_LINE: 'Prendre la ligne ',
    DIRECTION: 'Direction ',
}

export default (key) => {
    return i18n[key.toUpperCase()];
}