import React, {Component} from 'react';

import TabMain from 'components/navigations/TabMain';
import Interests from 'components/Interests';
import ListingHeader from 'components/listing/ListingHeader';
import ArticleInfos from 'components/article/ArticleInfos';
import ArticleContent from 'components/article/ArticleContent';

import ImagePMR from 'assets/img/text-PMR.png';
import BtnClose from 'assets/img/btn-close.png';

class DiscoverArticle extends Component {

    static defaultProps = {
        style: {},
        changePage: function () {
        }
    }

    render() {
        let {style, changePage, currentPage, dataPage} = this.props;

        let previousPage = currentPage === 'article' ? 'listing' : 'discover';
        if(typeof(dataPage.fromMap)){
            previousPage = 'discover';
        }
        return (
            <div className={'nq-c-Body nq-c-Body-single'} data-pattern={true} style={style}>
                <TabMain
                    currentPage={currentPage}
                    changePage={changePage}
                />
                <Interests/>

                <img src={ImagePMR} className="image-pmr image-pmr-single"/>

                <a href="#" className="nq-c-ListingHeader-prevcustom" onClick={changePage.bind(this, previousPage)}>

                    <img src={BtnClose} />

                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
                            <path fill="#3A3A3A" fillRule="evenodd"
                                  d="M9.2585369,7.5 L14.6402993,2.11823763 C15.1199002,1.63863665 15.1199002,0.839301701 14.6402993,0.359700729 C14.1606983,-0.119900243 13.3613633,-0.119900243 12.8817624,0.359700729 L7.5,5.7414631 L2.11823763,0.359700729 C1.63863665,-0.119900243 0.839301701,-0.119900243 0.359700729,0.359700729 C-0.119900243,0.839301701 -0.119900243,1.63863665 0.359700729,2.11823763 L5.7414631,7.5 L0.359700729,12.8817624 C-0.119900243,13.3613633 -0.119900243,14.1606983 0.359700729,14.6402993 C0.839301701,15.1199002 1.63863665,15.1199002 2.11823763,14.6402993 L7.5,9.2585369 L12.8817624,14.6402993 C13.3613633,15.1199002 14.1606983,15.1199002 14.6402993,14.6402993 C15.1199002,14.1606983 15.1199002,13.3613633 14.6402993,12.8817624 L9.2585369,7.5 Z"/>
                        </svg>
                    </span>
                </a>

                <ListingHeader
                    title={dataPage.name}
                    img={dataPage.image}
                    changePage={changePage}
                    currentPage={'article'}
                />

                <div className={'nq-c-Body-wrapper nq-c-Article'}>
                    <ArticleInfos dataPage={dataPage} />
                    <ArticleContent
                        dataPage={dataPage}
                        changePage={changePage}
                    />
                </div>
            </div>
        );
    }
}

export default DiscoverArticle;