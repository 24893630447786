import React, {Component} from 'react';

class MyPosition extends Component {

    static defaultProps = {
        short: false,
        absolute: true
    };

    onClick() {
        if (typeof (this.props.onClick) === 'function') {
            this.props.onClick();
        }
    }

    render() {

        let {short, absolute} = this.props;

        return (
            <div
                onClick={this.onClick.bind(this)}
                style={{position: absolute ? 'absolute' : 'relative'}}
                className="nq-c-Button nq-c-SearchItineraryItem-myposition"
                data-size="xs">
                <svg xmlns="http://www.w3.org/2000/svg" width="28"
                     height="28" viewBox="0 0 28 28">
                    <g fill="none" fillRule="evenodd">
                        <circle cx="14" cy="14" r="10" stroke="#FFF"
                                strokeWidth="2"/>
                        <rect width="8" height="2" x="20" y="13"
                              fill="#FFF"/>
                        <rect width="8" height="2" y="13" fill="#FFF"/>
                        <rect width="8" height="2" x="10" y="23" fill="#FFF"
                              transform="rotate(90 14 24)"/>
                        <rect width="8" height="2" x="10" y="3" fill="#FFF"
                              transform="rotate(90 14 4)"/>
                    </g>
                </svg>
                {!short && <span>Ma position</span>}
            </div>
        );
    }
}

export default MyPosition;
