/* global google */
import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import Lieu from 'components/map/infobulle/Lieu';
import Stop from 'components/map/infobulle/Stop';
import StopSNCF from 'components/map/infobulle/StopSNCF';

import * as MainStateActions from 'reducers/main';

import CONSTANTES from 'configs/defines';
import MARKERS_DEF from 'configs/markers';

class InfoBulle extends Component {

    state = {
        open: false,
        marker: false
    }

    loaded = [];
    categories = [];
    prevZindex = false;

    constructor(props) {
        super(props);
        this.state = {
            address: props.value,
            value: '',
            addresses: [],
            focused: false
        };


        //TODO REMOVE EVENT
        //document.addEventListener(CONSTANTES.keys.events.closeInfoBulle, this.onClose.bind(this));

        document.addEventListener(CONSTANTES.keys.events.actionMap, (event) => {
            this.goActionMap(event.detail);
        }, false);

    }

    onClose() {
        if (typeof (this.state.marker) === 'undefined') {
            return;
        }

        if (typeof (this.state.marker.nqData) === 'undefined') {
            return;
        }

        if (typeof (this.state.marker) === 'undefined') {
            return;
        }

        let dataIcon = this.getIcon(this.state.marker.nqData.img, false, this.state.marker.nqData);
        if (dataIcon) {
            this.state.marker.setIcon(dataIcon);
        }

        this.state.marker.setZIndex(this.prevZindex);

        if(typeof(this.state.marker.splider) !== 'undefined'){
            window.removeSpider = true;
            this.state.marker.splider.unspiderfy();
            delete(window.removeSpider);
        }
        this.setState({
            open: false,
            data: false,
            marker: false
        });
    }


    goTo(dataPlace) {
        document.dispatchEvent(new CustomEvent(CONSTANTES.keys.events.goTo, {
            detail: {
                name: dataPlace.name,
                id: 'ADDRESS|' + dataPlace.latlng.lat + ',' + dataPlace.latlng.lng,
                type: 'stop'
            }
        }));
        this.onClose();
    }

    componentWillReceiveProps(props) {
        if (props.markerCategories.isLoaded && this.loaded.indexOf('categories') === -1) {
            this.loaded.push('categories');
            this.categories = props.markerCategories.data;
        }
    }

    getIcon(url, active = false, data = {}) {
        if (typeof (MARKERS_DEF[url]) === 'undefined') {

            if (url.indexOf('icon/?name=bus-stop') !== -1) {
                let infoMarkerIcon = url;
                let width = data.width / 2;
                let height = data.height / 2;
                let scaledSizeWidth = data.width / 2;
                let scaledSizeHeight = data.height / 2;
                let anchorX = width / 2;
                let anchorY = height / 2;

                return {
                    url: infoMarkerIcon + (active ? '&active' : ''),
                    size: new google.maps.Size(width, height),
                    scaledSize: new google.maps.Size(scaledSizeWidth, scaledSizeHeight),
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(anchorX, anchorY)
                };

                return false;
            }
        }
        let icon = MARKERS_DEF[url].icon;

        if (typeof (icon['url' + (active ? 'Active' : '')]) === 'undefined') {
            active = false;
        }
        let infoMarkerIcon = icon['url' + (active ? 'Active' : '')];
        let width = icon['size' + (active ? 'Active' : '')][0];
        let height = icon['size' + (active ? 'Active' : '')][1];
        let scaledSizeWidth = icon['scaledSize' + (active ? 'Active' : '')][0];
        let scaledSizeHeight = icon['scaledSize' + (active ? 'Active' : '')][1];
        let anchorX = width / 2;
        let anchorY = height / 2;
        let originY = 0;
        let originX = 0;

        return {
            url: infoMarkerIcon,
            size: new google.maps.Size(width, height),
            scaledSize: new google.maps.Size(scaledSizeWidth, scaledSizeHeight),
            origin: new google.maps.Point(originX, originY),
            anchor: new google.maps.Point(anchorX, anchorY)
        };
    }

    goActionMap(eventData) {
        let {action, data} = eventData;

        if (action === 'open-marker') {

            if (this.state.marker) {
                this.onClose();
            }

            if (typeof (data.nqData.img) === 'undefined') {
                return;
            }

            let dataIcon = this.getIcon(data.nqData.img, true, data.nqData);
            if (dataIcon) {
                data.setIcon(dataIcon);
            }

            this.prevZindex = data.getZIndex();
            data.setZIndex(9999999999999);
            this.setState({
                open: true,
                marker: data
            });

        }

        if (action === 'close-marker') {
            this.onClose();
        }
    }

    render() {
        let {open, marker} = this.state;
        let {changePage} = this.props;
        if (typeof (marker) === 'undefined' || typeof (marker.nqData) === 'undefined') {
            return (null);
        }

        let data = marker.nqData;
        let dataPlace = data.data;


        if (open === false) {
            return (null);
        }

        let item = (
            <Lieu
                marker={marker}
                categories={this.categories}
                onClose={this.onClose.bind(this)}
                goTo={this.goTo.bind(this)}
                changePage={changePage}
            />
        );


        if (data.id === 'ID17362') {
            item = (
                <StopSNCF
                    marker={marker}
                    categories={this.categories}
                    onClose={this.onClose.bind(this)}
                    goTo={this.goTo.bind(this)}
                    changePage={changePage}
                />
            );
        } else if (typeof (dataPlace.id_siri) !== 'undefined') {
            item = (
                <Stop
                    marker={marker}
                    categories={this.categories}
                    onClose={this.onClose.bind(this)}
                    goTo={this.goTo.bind(this)}
                    changePage={changePage}
                />
            );
        }

        return (item);
    }
}

export default connect(
    state => ({
        map: state.map,
        markerCategories: state.markerCategories
    }),
    dispatch => ({
        mainStateActions: bindActionCreators(MainStateActions, dispatch)
    })
)(InfoBulle);


