import React, {Component} from 'react';



class HelpSidebar extends Component {

    buildTabs(tab, index) {
        let {activeIndex, toggleTab} = this.props;
        let activeTab = index === activeIndex;

        return (
            <div className={'nq-c-HelpSidebar-tab'} key={'helptabs_' + index} data-active={activeTab} onClick={() => toggleTab(index)}>
                <div className={'nq-c-HelpSidebar-title'}>{tab.title}</div>
                {!activeTab && (
                    <div className={'nq-c-HelpSidebar-icon'}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13"><path fill="#3A3A3A" d="M5,5 L5,1.5 C5,0.671572875 5.67157288,9.58043431e-16 6.5,8.8817842e-16 C7.32842712,8.18313409e-16 8,0.671572875 8,1.5 L8,5 L11.5,5 C12.3284271,5 13,5.67157288 13,6.5 C13,7.32842712 12.3284271,8 11.5,8 L8,8 L8,11.5 C8,12.3284271 7.32842712,13 6.5,13 C5.67157288,13 5,12.3284271 5,11.5 L5,8 L1.5,8 C0.671572875,8 9.09494702e-13,7.32842712 9.09494702e-13,6.5 C9.09494702e-13,5.67157288 0.671572875,5 1.5,5 L5,5 Z"/></svg>
                    </div>
                )}
            </div>
        )
    }

    render() {
        let {tabs} = this.props;

        return (
            <div className={'nq-c-HelpSidebar'}>
                {tabs.map((tab, index) => this.buildTabs(tab, index))}
            </div>
        );
    }
}

export default HelpSidebar;
