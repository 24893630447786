import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import moment from "moment";

import TabMain from 'components/navigations/TabMain';
import Interests from 'components/Interests';
import ListingSubCategory from 'components/listing/ListingSubCategory';
import ListingHeader from 'components/listing/ListingHeader';
import ListingArticle from 'components/listing/ListingArticle';

import * as ArticlesStateActions from 'reducers/articles';

import ImagePMR from 'assets/img/text-PMR.png';

class DiscoverListing extends Component {

    static defaultProps = {
        style: {},
        changePage: function () {
        }
    };

    state = {
        category: {
            key: '', //agenda
            label: '', //Agenda
            img: ''
        }, //false
        selected: []
    };

    constructor(props) {
        super(props);
        //props.articlesStateActions.fetch('agenda');
    }


    componentWillReceiveProps(props) {
        let {category} = this.state;
        let {dataPage, changePage} = props;

        if (
            (
                typeof (dataPage.key) !== 'undefined' &&
                typeof (category.key) !== 'undefined' &&
                dataPage.key !== category.key
            ) ||
            category === false
        ) {
            let selected = [];
            if (dataPage.key === 'agenda') {
                selected.push('month_' + (moment().format('M')));
            }

            this.setState({
                category: dataPage,
                selected
            }, () => {
                this.getArticles(dataPage.key);
            });
        }
    }

    getArticles(key) {
        this.props.articlesStateActions.fetch(key);
        let elm = document.querySelector('.nq-c-ListingArticles-list');
        if (elm !== null) {
            elm.scrollTop = 0;
        }
    }

    onChangeCategories(categories) {
        this.setState({
            selected: categories
        });
    }

    render() {
        let {style, changePage, currentPage, articles} = this.props;
        let {selected, category} = this.state;

        if (typeof (selected) === 'undefined') {
            alert('Connexion internet indisponible');
            document.location.reload();
            return;
        }

        let dataArticles = [];
        let dataArticlesFull = [];
        let dataCategories = [];
        let dataAssoc = [];
        if (articles.isLoaded) {
            dataArticles = articles.data.posts;
            dataArticlesFull = articles.data.posts;
            dataCategories = articles.data.categories;
            dataAssoc = articles.data.association;
        }

        if (selected.length > 0 && dataArticles.length > 0) {
            let cleanArticles = [];
            dataArticles.map((article) => {
                if (selected.indexOf('all') !== -1) {
                    cleanArticles.push(article);
                } else if (category.key === 'agenda') {
                    let start = moment(article.date_start);
                    let end = moment(article.date_end);


                    if (start === 'Invalid date' || end === 'Invalid date') {

                    } else if (article.date_start == article.date_end) {
                        let iStart = start.format('M');
                        if (selected.indexOf('month_' + iStart) !== -1) {
                            cleanArticles.push(article);
                        }
                    } else {
                        let iStart = start.format('M');
                        let iEnd = end.format('M');

                        for (let i = iStart; i < iEnd; i++) {
                            if (selected.indexOf('month_' + i) !== -1) {
                                cleanArticles.push(article);
                                break;
                            }
                        }
                    }

                } else if (selected.indexOf(article['category-blog']) !== -1) {
                    cleanArticles.push(article);
                }
            });
            dataArticles = cleanArticles;
        }

        return (
            <div className={'nq-c-Body nq-c-Body-listing-page'} data-pattern={true} data-infinite={true} style={style}>
                <TabMain
                    currentPage={currentPage}
                    changePage={changePage}
                />
                <Interests/>

                <img src={ImagePMR} className="image-pmr image-pmr-list"/>

                <ListingHeader
                    title={category.label}
                    currentPage={'listing'}
                    img={category.img}
                    changePage={changePage}
                />

                <div className={'nq-c-Body-wrapper'}>
                    <ListingSubCategory
                        articles={dataArticlesFull}
                        category={category.key}
                        subcategories={dataCategories}
                        association={dataAssoc}
                        selected={this.state.selected}
                        onChangeCategories={this.onChangeCategories.bind(this)}
                    />
                    <ListingArticle
                        articles={dataArticles}
                        category={category.key}
                        subcategories={dataCategories}
                        isFetching={articles.isFetching}
                        isLoaded={articles.isLoaded}
                        changePage={changePage}
                    />
                </div>
            </div>
        );
    }
}


export default connect(
    state => ({
        articles: state.articles
    }),
    dispatch => ({
        articlesStateActions: bindActionCreators(ArticlesStateActions, dispatch)
    })
)(DiscoverListing);
