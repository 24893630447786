/*global google MarkerClusterer OverlappingMarkerSpiderfier*/
import CONSTANTES from 'configs/defines';
import MARKERS_DEF from 'configs/markers';

//refgactore add remove ... (marker, polyline ...)
let map = null;
//window.map = map;
let markers = [];
let clusters = [];
let polylines = [];
let layers = [];
let spiders = [];

export const getAllMarkers = () => {
    return markers;
}


export const initSpider = () => {
    for (let key in checkDoublon) {
        let sameMarkers = checkDoublon[key];
        if (sameMarkers.length > 1) {
            let spider = getSpider(key, sameMarkers);
            sameMarkers.map((mark) => {
                mark.splider = spider;
            });
        }
    }
};

export const getSpider = (key, markers = []) => {

    if (map === null) {
        return;
    }

    let splider = getObject(spiders, key);

    if (splider !== false) {
        return splider;
    }

    splider = new OverlappingMarkerSpiderfier(map, {
        markersWontMove: true,
        markersWontHide: true,
        spiralFootSeparation: 50,
        spiralLengthStart: 25,
        spiralLengthFactor: 10,
        keepSpiderfied: true
    });
    splider.nqName = key;
    spiders.push(splider);


    markers.map((marker) => {
        splider.addMarker(marker);
    });

    return splider;
};

export const setMap = function (m) {
    map = m;
};

export const getCluster = (key, markers = [], options = {}) => {

    /*return {
        hide: () => {},
        show: () => {},
        clearMarkers: () => {},
        addMarkers: () => {},
        addMarker: () => {}
    };*/
    if (map === null) {
        return;
    }

    let cluster = getObject(clusters, key);

    if (cluster !== false) {
        if (markers.length > 0) {
            cluster.clearMarkers();
            cluster.addMarkers(markers);
        }
        return cluster;
    }

    let imagePrefix = 'https://totem.synchro-bus.fr/wp-content/themes/nq-keolis/assets/img/markers/cluster/unknown';

    let styles = [];
    for (var i = 1; i < 6; i++) {
        styles.push({
            height: 148 / 2,
            width: 114 / 2,
            url: imagePrefix + i + '.png',
            //anchor: [options.width, options.height],
            textColor: '#FFFFFF',
            textSize: 25
        });
    }

    cluster = new MarkerClusterer(
        map,
        markers,
        {
            imagePath: imagePrefix,
            styles: styles,
            gridSize: 100,
            maxZoom: 20
        }
    );

    cluster.hide = function () {
        this.clearMarkers();
    };

    cluster.show = function () {
        this.addMarkers(cluster.cacheMarkers);
    };

    cluster.nqName = key;
    //TODO Viré pour utilsation d'un getMarkers du cluster
    cluster.cacheMarkers = markers;
    clusters.push(cluster);
    return cluster;
};


export const zoomToObject = function (obj) {

    if (map === null) {
        return;
    }
    var bounds = new google.maps.LatLngBounds();
    var points = obj.getPath().getArray();
    for (var n = 0; n < points.length; n++) {
        bounds.extend(points[n]);
    }
    map.fitBounds(bounds);
};

export const addPolylineCurve = function (latlngStart, latlngStop, opts = {}) {
    return addPolyline(drawPathCurve(latlngStart, latlngStop), opts);
}

export const addPolyline = function (coordinates, opts = {}) {

    if (map === null) {
        return;
    }

    if (typeof (coordinates) == 'string') {
        coordinates = google.maps.geometry.encoding.decodePath(coordinates);
    }

    var polyline = new google.maps.Polyline({
        path: coordinates,
        ...opts
    });

    polyline.setMap(map);


    if (typeof (opts.type) !== 'undefined') {
        polyline.nqType = opts.type;
    }

    polylines.push(polyline);
    return polyline;
};


export const removePolylines = (type = false) => {
    if (type !== false) {
        let newPolylines = [];
        polylines.map((polyline) => {
            if (polyline.nqType === type) {
                polyline.setMap(null);
                return;
            }
            newPolylines.push(polyline);
        });
        polylines = newPolylines;
        return;
    }
    setMapOnAll(polylines, null);
    polylines = [];
};

export const setOpacityOnMarkers = function (opacity) {
    markers.map((marker) => {
        marker.setOpacity(opacity);
    });
}


let checkDoublon = [];
export const addMarker = function (latlng, url, options = {}, exclude = false) {
    if (map === null) {
        return;
    }


    let data = {};

    if (typeof (options.data) !== 'undefined') {
        data = options.data;
        data.type = 'marker';
        delete options.data;
    }

    let infos = {
        position: latlng,
        map: map,
        draggable: false,
        icon: {
            url: url,
            rotation: 180
        },
        zIndex: -20,
        ...options
    };


    /*if(checkDoublon[keyDoublon] > 1){
        console.log(data.name);
    }*/

    let marker = new google.maps.Marker(infos);
    marker.nqData = data;

    /*if(infos.icon.url.indexOf('http') === -1){
        markers.map((marker) => {
            if (
                marker.getPosition().lat() === latlng.lat &&
                marker.getPosition().lng() === latlng.lng
            ) {
                console.log('Same marker');
            }
        });


        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");

        let image = new Image();
        image.src = infos.icon.url;
        image.onload = function() {
            ctx.translate(image.width, image.height);
            ctx.rotate(180 * Math.PI / 180);
            ctx.drawImage(image, 0, 0);
            console.log(canvas.width, canvas.height);
            marker.setIcon({
                url: canvas.toDataURL()
            })
        };
    }*/


    if (typeof (options.type) !== 'undefined') {
        marker.nqType = options.type;
    }

    if (typeof (options.id) !== 'undefined') {
        marker.nqId = options.id;
    }

    marker.addListener('click', function () {
        if (typeof (options.click) === 'function') {
            options.click(this);
        }
    });


    let keyDoublon = latlng.lat + '-' + latlng.lng;
    if (typeof (checkDoublon[keyDoublon]) === 'undefined') {
        checkDoublon[keyDoublon] = [];
    }

    checkDoublon[keyDoublon].push(marker);

    if (!exclude) {
        markers.push(marker);
    }

    return marker;
};


export const removeMarkers = (type = false) => {

    if (type !== false) {
        let newMarkers = [];
        markers.map((marker) => {
            if (marker.nqType === type) {
                marker.setMap(null);
                return;
            }
            newMarkers.push(marker);
        });
        markers = newMarkers;
        return;
    }
    setMapOnAll(markers, null);
    markers = [];
};

export const findMarker = (id) => {
    let markerFind = false;
    markers.map((marker) => {
        if (marker.nqId == id) {
            markerFind = marker;
        }
    });

    return markerFind;
};

export const getObject = function (collection, name) {
    let itemFind = false;
    for (var i = 0, l = collection.length; i < l; i++) {
        let item = collection[i];
        if (item.nqName == name) {
            itemFind = item;
        }
    }
    return itemFind;
};

export const addLayer = function (url, name = '', type = false) {
    let layer = getObject(layers, name);

    if (layer !== false) {
        return layer;
    }

    url = url + (CONSTANTES.DEBUG ? (url.indexOf('?') !== -1 ? '&' : '?') + 'time=' + new Date().getTime() : '');

    if (CONSTANTES.DEBUG) {
        console.log('Layer', url);
    }
    layer = new google.maps.KmlLayer({
        url: url,
        map: map
    });

    layer.nqName = name;
    layers.push(layer);
    return layer;
};

export const removeLayers = (type = false) => {

    layers.map((layer) => {
        layer.setMap(null);
    });
    layers = [];
};


export const load = function (callback, key) {
    let callbackName = 'gMapsCallback' + Math.ceil(Math.random() * 1000);
    window[callbackName] = callback;

    let script_tag = document.createElement('script');
    script_tag.setAttribute("type", 'text/javascript');
    script_tag.setAttribute("src", 'https://maps.google.com/maps/api/js?callback=' + callbackName + '&key=' + key + '&libraries=geometry');
    (document.getElementsByTagName("head")[0] || document.documentElement).appendChild(script_tag);
};

export const loadScript = function (callback, url) {
    let script_tag = document.createElement('script');
    script_tag.setAttribute("type", 'text/javascript');
    script_tag.setAttribute("src", url);
    script_tag.onload = callback;
    (document.getElementsByTagName("head")[0] || document.documentElement).appendChild(script_tag);
};

export const setVisibility = (elements, visibility) => {
    visibility = visibility ? map : null;
    if (Array.isArray(elements)) {
        elements.map((element) => {
            element.setMap(visibility);
        });
        return;
    }
    elements.setMap(visibility);
};

export const setMapToogle = (element) => {
    element.setMap(element.getMap() ? null : map);
};

export const setMapOnAll = (elements, m) => {
    elements.map((element) => {
        element.setMap(m);
    });
};

export const averageGeolocation = (coords) => {
    if (coords.length === 1) {
        return coords[0];
    }

    let x = 0.0;
    let y = 0.0;
    let z = 0.0;

    for (let coord of coords) {
        let latitude = coord.lat * Math.PI / 180;
        let longitude = coord.lng * Math.PI / 180;

        x += Math.cos(latitude) * Math.cos(longitude);
        y += Math.cos(latitude) * Math.sin(longitude);
        z += Math.sin(latitude);
    }

    let total = coords.length;

    x = x / total;
    y = y / total;
    z = z / total;

    let centralLongitude = Math.atan2(y, x);
    let centralSquareRoot = Math.sqrt(x * x + y * y);
    let centralLatitude = Math.atan2(z, centralSquareRoot);

    return {
        lat: centralLatitude * 180 / Math.PI,
        lng: centralLongitude * 180 / Math.PI
    };
};


export const getDistance = (lat1, lon1, lat2, lon2) => {
    var R = 6371; // km (change this constant to get miles)
    var dLat = (lat2 - lat1) * Math.PI / 180;
    var dLon = (lon2 - lon1) * Math.PI / 180;
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return Math.round(d);
}


export const setCenterWithOffset = (latlng, offsetx = 0, offsety = 0) => {

    var scale = Math.pow(2, map.getZoom());

    var worldCoordinateCenter = map.getProjection().fromLatLngToPoint(latlng);
    var pixelOffset = new google.maps.Point((offsetx / scale) || 0, (offsety / scale) || 0);

    var worldCoordinateNewCenter = new google.maps.Point(
        worldCoordinateCenter.x - pixelOffset.x,
        worldCoordinateCenter.y + pixelOffset.y
    );

    var newCenter = map.getProjection().fromPointToLatLng(worldCoordinateNewCenter);

    map.setCenter(newCenter);
}


const drawPathCurve = (latlngStart, latlngStop) => {

    let P1 = new google.maps.LatLng(latlngStart);
    let P2 = new google.maps.LatLng(latlngStop);

    var lineLength = google.maps.geometry.spherical.computeDistanceBetween(P1, P2);
    var lineHeading = google.maps.geometry.spherical.computeHeading(P1, P2);
    if (lineHeading < 0) {
        var lineHeading1 = lineHeading + 45;
        var lineHeading2 = lineHeading + 135;
    } else {
        var lineHeading1 = lineHeading + -45;
        var lineHeading2 = lineHeading + -135;
    }
    var pA = google.maps.geometry.spherical.computeOffset(P1, lineLength / 2.2, lineHeading1);
    var pB = google.maps.geometry.spherical.computeOffset(P2, lineLength / 2.2, lineHeading2);


    return new GmapsCubicBezier(P1, pA, pB, P2, 0.01, map);
};


const GmapsCubicBezier = function (latlong1, latlong2, latlong3, latlong4, resolution, map) {
    var lat1 = latlong1.lat();
    var long1 = latlong1.lng();
    var lat2 = latlong2.lat();
    var long2 = latlong2.lng();
    var lat3 = latlong3.lat();
    var long3 = latlong3.lng();
    var lat4 = latlong4.lat();
    var long4 = latlong4.lng();

    var points = [];

    for (var it = 0; it <= 1; it += resolution) {
        points.push(this.getBezier({
            x: lat1,
            y: long1
        }, {
            x: lat2,
            y: long2
        }, {
            x: lat3,
            y: long3
        }, {
            x: lat4,
            y: long4
        }, it));
    }
    var path = [];
    for (var i = 0; i < points.length - 1; i++) {
        path.push(new google.maps.LatLng(points[i].x, points[i].y));
        path.push(new google.maps.LatLng(points[i + 1].x, points[i + 1].y, false));
    }

    return path;
};


GmapsCubicBezier.prototype = {

    B1: function (t) {
        return t * t * t;
    },
    B2: function (t) {
        return 3 * t * t * (1 - t);
    },
    B3: function (t) {
        return 3 * t * (1 - t) * (1 - t);
    },
    B4: function (t) {
        return (1 - t) * (1 - t) * (1 - t);
    },
    getBezier: function (C1, C2, C3, C4, percent) {
        var pos = {};
        pos.x = C1.x * this.B1(percent) + C2.x * this.B2(percent) + C3.x * this.B3(percent) + C4.x * this.B4(percent);
        pos.y = C1.y * this.B1(percent) + C2.y * this.B2(percent) + C3.y * this.B3(percent) + C4.y * this.B4(percent);
        return pos;
    }
};


export const RotateIcon = function (options) {
    this.options = options || {};
    this.rImg = options.img || new Image();
    this.rImg.src = this.rImg.src || this.options.url || '';
    this.options.width = this.options.width || this.rImg.width || 52;
    this.options.height = this.options.height || this.rImg.height || 60;
    var canvas = document.createElement("canvas");
    canvas.width = this.options.width;
    canvas.height = this.options.height;
    this.context = canvas.getContext("2d");
    this.canvas = canvas;
};

RotateIcon.makeIcon = function (url) {
    return new RotateIcon({src: url});
};

RotateIcon.prototype.setRotation = function (options) {
    var canvas = this.context,
        angle = options.deg ? options.deg * Math.PI / 180 :
            options.rad,
        centerX = this.options.width / 2,
        centerY = this.options.height / 2;

    canvas.clearRect(0, 0, this.options.width, this.options.height);
    canvas.save();
    canvas.translate(centerX, centerY);
    canvas.rotate(angle);
    canvas.translate(-centerX, -centerY);
    canvas.drawImage(this.rImg, 0, 0);
    canvas.restore();
    return this;
};

RotateIcon.prototype.getUrl = function () {
    return this.canvas.toDataURL('image/png');
};


export const paddedBounds = function (npad, spad, epad, wpad) {
    var SW = map.getBounds().getSouthWest();
    var NE = map.getBounds().getNorthEast();
    var topRight = map.getProjection().fromLatLngToPoint(NE);
    var bottomLeft = map.getProjection().fromLatLngToPoint(SW);
    var scale = Math.pow(2, map.getZoom());

    var SWtopoint = map.getProjection().fromLatLngToPoint(SW);
    var SWpoint = new google.maps.Point(((SWtopoint.x - bottomLeft.x) * scale) + wpad, ((SWtopoint.y - topRight.y) * scale) - spad);
    var SWworld = new google.maps.Point(SWpoint.x / scale + bottomLeft.x, SWpoint.y / scale + topRight.y);
    var pt1 = map.getProjection().fromPointToLatLng(SWworld);

    var NEtopoint = map.getProjection().fromLatLngToPoint(NE);
    var NEpoint = new google.maps.Point(((NEtopoint.x - bottomLeft.x) * scale) - epad, ((NEtopoint.y - topRight.y) * scale) + npad);
    var NEworld = new google.maps.Point(NEpoint.x / scale + bottomLeft.x, NEpoint.y / scale + topRight.y);
    var pt2 = map.getProjection().fromPointToLatLng(NEworld);

    return new google.maps.LatLngBounds(pt1, pt2);
}