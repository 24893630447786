import axios from 'axios';

import CONSTANTES from 'configs/defines';

axios.defaults.headers.get['Content-Type'] = 'application/json';

export default function (
    url = '',
    params = {},
    valid = function () {
    },
    error = function () {
    },
    mode = 'get'
) {
    //TODO if debug env
    //console.log(Configs.urlApi + url, params);

    params.idTotem = CONSTANTES.id;

    if (mode === 'get') {
        params = {
            params: params
        }
    }

    let urlAjax = CONSTANTES.urls.api + url;
    //var customAjax = false;
    if (url.indexOf('http://') !== -1) {
        urlAjax = url;
        //customAjax = true;
    }


    if (CONSTANTES.DEBUG) {
        console.log(url);
    }

    axios[mode](urlAjax, params)
        .then(function (datas) {
            valid(datas.data);
        })
        .catch(function (errors) {
            //TODO CATCH CANCEL REQUEST !
            console.log('error', errors);
            error(errors);
        });
}
