/*global google*/
import React, {Component} from 'react';
import moment from 'moment';

const months = [
    '',
    'janvier',
    'février',
    'mars',
    'avril',
    'mai',
    'juin',
    'juillet',
    'août',
    'septembre',
    'octobre',
    'novembre',
    'décembre'
];

class ListingSubCategory extends Component {

    state = {
        selected: ['all']
    };

    categoriesCount = 0;

    componentWillReceiveProps(props) {
        if (props.selected.length > 0) {
            this.setState({
                selected: props.selected
            });
        }
    }

    onChange(key) {
        let {selected} = this.state;
        let {onChangeCategories} = this.props;
        let indexKey = selected.indexOf(key);
        let indexKeyAll = selected.indexOf('all');


        let goAll = false;
        if (indexKey !== -1) {
            selected.splice(indexKey, 1);
        } else {
            selected.push(key);
            if (key == 'all') {
                goAll = true;
            }
        }

        if (indexKeyAll !== -1 && selected.length > 1) {
            selected.splice(indexKeyAll, 1);
        }

        if (selected.length == this.categoriesCount || goAll || selected.length === 0) {
            selected = ['all'];
        }

        this.setState({
            selected
        });

        onChangeCategories(selected);
    }

    buildSubCategory() {
        let {subcategories, association, category, articles} = this.props;
        let {selected} = this.state;

        if (typeof (association[category]) === 'undefined') {
            return (null);
        }

        let items = [];

        let isSelected = false;
        if (selected.indexOf('all') !== -1) {
            isSelected = true;
        }

        items.push(
            <label htmlFor={'filter_subcategory_all'} key={'subcategory_all'}
                   className={'nq-c-FiltersClassic-item'} data-type='all'>
                <input
                    type={'checkbox'}
                    name={'filter_subcategory'}
                    id={'filter_subcategory_all'}
                    onChange={this.onChange.bind(this, 'all')}
                    checked={isSelected}
                />
                <span className={'nq-c-Button'} data-border={true}>Tout</span>
            </label>
        );

        if (category === 'agenda') {
            let now = moment();
            let currentMonth = now.format('M');
            console.log('currentMonth', currentMonth);
            this.categoriesCount = 0;
            for (let i = 1; i < 13; i++) {

                if (i < currentMonth) {
                    continue;
                }
                this.categoriesCount++;
                let isSelected = false;
                if (selected.indexOf('month_' + i) !== -1) {
                    isSelected = true;
                }

                items.push(
                    <label htmlFor={'filter_subcategory_month_' + i} key={'subcategory_month_' + i}
                           className={'nq-c-FiltersClassic-item'} data-type={'month_' + i}>
                        <input
                            type={'checkbox'}
                            name={'filter_subcategory'}
                            id={'filter_subcategory_month_' + i}
                            onChange={this.onChange.bind(this, 'month_' + i)}
                            checked={isSelected}
                        />
                        <span className={'nq-c-Button'} data-border={true}>{months[i]}</span>
                    </label>
                );
            }
            return items;
        }
        this.categoriesCount = 0;
        for (var key in subcategories) {

            let isSubCategory = false;
            for (var i = 0, l = association[category].length; i < l; i++) {
                if (association[category][i] == key) {
                    isSubCategory = true;
                }
            }

            if (!isSubCategory) {
                continue;
            }

            let countArticle = 0;

            articles.map((article) => {
                if (article['category-blog'] == key) {
                    countArticle++;
                }
            });

            if (countArticle === 0) {
                continue;
            }

            this.categoriesCount++;
            let subcategory = subcategories[key];
            let isSelected = false;
            if (selected.indexOf(key) !== -1) {
                isSelected = true;
            }
            items.push(
                <label htmlFor={'filter_subcategory_' + key} key={'subcategory_' + key}
                       className={'nq-c-FiltersClassic-item'} data-type={subcategory.key}>
                    <input
                        type={'checkbox'}
                        name={'filter_subcategory'}
                        id={'filter_subcategory_' + key}
                        onChange={this.onChange.bind(this, key)}
                        checked={isSelected}
                    />
                    <span className={'nq-c-Button'} data-border={true}>{subcategory.name}</span>
                </label>
            );
        }

        return items;
    }

    render() {
        let {subcategories, association, category} = this.props;

        let count = 0;
        for (var key in subcategories) {
            let isSubCategory = false;
            for (var i = 0, l = association[category].length; i < l; i++) {
                if (association[category][i] == key) {
                    isSubCategory = true;
                }
            }

            if (!isSubCategory) {
                continue;
            }
            count++;
        }

        if (count <= 1) {
            return (null);
        }
        return (
            <div className={'nq-c-ListingFilters'}>
                <form className={'nq-c-FiltersClassic-list nq-c-ListingFilters-list'}>
                    {this.buildSubCategory()}
                </form>
            </div>
        );
    }
}

export default ListingSubCategory;