import Axio from 'tools/Axio';

import CONSTANTES from 'configs/defines';

let initialState = {
    isFetching: false,
    isLoaded: false,
    didErrors: false,
    data: [],
    error: null,
    defaultMode: CONSTANTES.keys.transportModes.DEFAULT
};

// Actions
const RECEIVE_SEGMENTS = 'Direction/Receive';
const RECEIVE_ERROR = 'Direction/Error';
const RECEIVE_LOAD = 'Direction/Load';
const RECEIVE_CLEAN = 'Direction/Clean';
const RECEIVE_CHANGE_MODE = 'Direction/Change/Mode';
const RECEIVE_RESET = 'Direction/';

export const changeMode = (mode) => {
    //TODO SECURITY MODE
    return (dispatch) => {
        dispatch({
            type: RECEIVE_CHANGE_MODE,
            datas: mode
        });
    }

};

//add cache 30min
export const fetch = (addresses) => {

    for (var key in addresses) {
        if (addresses[key] === 'current') {
            addresses[key] = CONSTANTES.Configs.markers.here.lat + ',' + CONSTANTES.Configs.markers.here.lng
        }
    }

    let params = {
        addresses
    };

    return (dispatch) => {
        dispatch({
            type: RECEIVE_LOAD
        });

        Axio(
            'direction/',
            params,
            function (datas) {
                //TODO MANAGE ERRORS
                if (datas) {
                    if (
                        datas[CONSTANTES.keys.transportModes.BIKE].error === true &&
                        datas[CONSTANTES.keys.transportModes.BUS].error === true &&
                        datas[CONSTANTES.keys.transportModes.CAR].error === true &&
                        datas[CONSTANTES.keys.transportModes.WALK].error === true
                    ) {
                        dispatch({
                            type: RECEIVE_ERROR,
                            datas: datas.data
                        });
                    } else {
                        datas.addresses = addresses;
                        dispatch({
                            type: RECEIVE_SEGMENTS,
                            datas: datas
                        });
                    }
                }
            },
            function () {
                dispatch({
                    type: RECEIVE_ERROR,
                    datas: 'Erreur'
                });
            },
            'post'
        );
    }

};

export const reset = () => {
    return (dispatch) => {
        dispatch({
            type: RECEIVE_CLEAN
        });
    };
};

export default function (state = initialState, action = {}) {

    switch (action.type) {
        case RECEIVE_LOAD:
            return {
                ...state,
                isFetching: true,
                isLoaded: false,
                didErrors: false,
                error: '',
                data: []
            };
        case RECEIVE_SEGMENTS:
            return {
                ...state,
                isLoaded: true,
                isFetching: false,
                didErrors: false,
                error: '',
                data: action.datas
            };
        case RECEIVE_ERROR:
            return {
                ...state,
                isFetching: false,
                isLoaded: true,
                didErrors: true,
                data: [],
                error: 'Itinéraire indisponible'
            };
        case RECEIVE_CLEAN :
            return {
                ...state,
                isFetching: false,
                isLoaded: false,
                didErrors: false,
                error: '',
                data: []
            };
        case RECEIVE_CHANGE_MODE:
            return {
                ...state,
                defaultMode: action.datas
            };
        default:
            return state;
    }
}
