import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import moment from "moment";

import * as SncfStateActions from 'reducers/sncf';

import * as Map from 'tools/Gmap';

import Loader from "components/Loader";


moment.locale('fr', {
    relativeTime : {
        future : 'dans %s',
        past : 'il y a %s',
        s : '< 1 min',
        m : 'une minute',
        mm : '%d min.',
        h : '1 h.',
        hh : '%d h.',
        d : '1 j.',
        dd : '%d j.',
        M : '1 mois',
        MM : '%d mois',
        y : '1 an',
        yy : '%d ans'
    }
});


class StopSNCF extends Component {
    constructor(props) {
        super(props);
        this.props.sncfStateActions.reset();
        this.props.sncfStateActions.fetch();
    }

    render() {
        let {marker, onClose, sncf} = this.props;

        if (typeof (marker.nqData) === 'undefined') {
            return (null);
        }

        let data = marker.nqData;
        let dataPlace = data.data;

        let infoSiri = [];

        if (sncf.isFetching) {
            infoSiri.push(
                <Loader key={'infoLoader'}/>
            );
        } else {
            if (sncf.isLoaded) {
                if (sncf.data.length > 0) {
                    sncf.data.map((info, index) => {
                        let date = moment(info.timeBruteJS);
                        infoSiri.push(
                            <tr key={'stop' + index}>
                                <td className="nq-c-MapTransportPopin-station">
                                    <div className={"nq-c-TransportNumber nq-c-MapTransportPopin-station-number" + (info.type ? ' with-type' : '')}>

                                        {info.type && (<span>{info.type}</span>)}

                                        {info.icon && (<img src={info.icon}/>)}
                                    </div>
                                    <div className="nq-c-MapTransportPopin-station-name">{info.direction}</div>
                                </td>
                                <td>
                                    <div className="nq-c-MapTransportPopin-station-time">{date.format('HH:mm')}</div>
                                </td>
                            </tr>
                        );
                    });

                    infoSiri = (
                        <table className="nq-c-MapTransportPopin-table">
                            <thead>
                            <tr>
                                <th>Prochains départs</th>
                                <th>dans</th>
                            </tr>
                            </thead>
                            <tbody>
                            {infoSiri}
                            </tbody>
                        </table>
                    );
                } else {
                    infoSiri.push(<span className="nq-c-MapTransportPopin-noresult" key={'no-result'}>Pas de prochain départ proche</span>);
                }
            }
        }

        return (
            <div className="nq-c-MapTransportPopin" data-active={true}>
                <div className="nq-c-MapTransportPopin-content">
                    <div className="nq-c-MapTransportPopin-close" onClick={onClose}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
                            <path fill="#3A3A3A" fillRule="evenodd"
                                  d="M9.2585369,7.5 L14.6402993,2.11823763 C15.1199002,1.63863665 15.1199002,0.839301701 14.6402993,0.359700729 C14.1606983,-0.119900243 13.3613633,-0.119900243 12.8817624,0.359700729 L7.5,5.7414631 L2.11823763,0.359700729 C1.63863665,-0.119900243 0.839301701,-0.119900243 0.359700729,0.359700729 C-0.119900243,0.839301701 -0.119900243,1.63863665 0.359700729,2.11823763 L5.7414631,7.5 L0.359700729,12.8817624 C-0.119900243,13.3613633 -0.119900243,14.1606983 0.359700729,14.6402993 C0.839301701,15.1199002 1.63863665,15.1199002 2.11823763,14.6402993 L7.5,9.2585369 L12.8817624,14.6402993 C13.3613633,15.1199002 14.1606983,15.1199002 14.6402993,14.6402993 C15.1199002,14.1606983 15.1199002,13.3613633 14.6402993,12.8817624 L9.2585369,7.5 Z"></path>
                        </svg>
                    </div>
                    <div className="nq-c-MapTransportPopin-title">{dataPlace.name}</div>

                    {infoSiri}
                </div>
            </div>
        );
    }
}

//

export default connect(
    state => ({
        sncf: state.sncf
    }),
    dispatch => ({
        sncfStateActions: bindActionCreators(SncfStateActions, dispatch)
    })
)(StopSNCF);