/*global google*/
import React, {Component} from 'react';

import ImageNoBG from 'assets/img/no-bg.jpg';
import BtnClose from 'assets/img/btn-close.png';

class ListingHeader extends Component {

    buildBg() {
        let {img, currentPage, changePage} = this.props;

        if(currentPage === 'mentions'){

            return (
                <div className="nq-c-ListingHeader-bg">
                    <a href="#" className="nq-c-ListingHeader-prevcustom" onClick={changePage.bind(this, 'previous')}>
                        <img src={BtnClose} />

                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
                            <path fill="#3A3A3A" fillRule="evenodd"
                                  d="M9.2585369,7.5 L14.6402993,2.11823763 C15.1199002,1.63863665 15.1199002,0.839301701 14.6402993,0.359700729 C14.1606983,-0.119900243 13.3613633,-0.119900243 12.8817624,0.359700729 L7.5,5.7414631 L2.11823763,0.359700729 C1.63863665,-0.119900243 0.839301701,-0.119900243 0.359700729,0.359700729 C-0.119900243,0.839301701 -0.119900243,1.63863665 0.359700729,2.11823763 L5.7414631,7.5 L0.359700729,12.8817624 C-0.119900243,13.3613633 -0.119900243,14.1606983 0.359700729,14.6402993 C0.839301701,15.1199002 1.63863665,15.1199002 2.11823763,14.6402993 L7.5,9.2585369 L12.8817624,14.6402993 C13.3613633,15.1199002 14.1606983,15.1199002 14.6402993,14.6402993 C15.1199002,14.1606983 15.1199002,13.3613633 14.6402993,12.8817624 L9.2585369,7.5 Z"/>
                        </svg>

                    </a>
                </div>
            );

        }
        let style = {
            backgroundImage: `url(${ImageNoBG})`
        };
        if (img) {
            style = {
                backgroundImage: `url(${img})`
            };
        }

        return (
            <div className="nq-c-ListingHeader-bg" style={style}></div>
        );
    }

    buildNavigationButton() {
        let {changePage, currentPage} = this.props;
        if (currentPage === 'listing') {
            return (
                <a href="#" className="nq-c-ListingHeader-prev" onClick={changePage.bind(this, 'discover')}>
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14">
                            <path fill="#FFF" fillRule="evenodd"
                                  d="M3.87437905,3.00605402 C3.59910078,3.03384513 3.33845329,3.15278405 3.12912069,3.34398101 L-2.49809976,8.4662428 C-2.78176527,8.7074615 -2.96076447,9.05982758 -2.99425983,9.4433427 C-3.02775519,9.82685782 -2.91366196,10.2092347 -2.67814402,10.501602 C-2.44158371,10.7950507 -2.10454268,10.972907 -1.74236406,10.99738 C-1.38018543,11.0207233 -1.02430393,10.8873311 -0.756341052,10.6272112 L4.00000005,6.29633888 L8.75634115,10.6272112 C9.02430402,10.8873254 9.38018552,11.0207148 9.74236415,10.99738 C10.1045428,10.9740367 10.4415865,10.7950677 10.6781441,10.5027118 C10.9136594,10.2092631 11.0277553,9.82796765 10.9942599,9.4433427 C10.9607646,9.05982758 10.7817734,8.70747288 10.4980999,8.4662428 L4.8708794,3.34398101 C4.59560114,3.09275968 4.23551262,2.97048276 3.87437905,3.00605402 Z"
                                  transform="matrix(0 1 1 0 -3 3)"/>
                        </svg>
                    </span>
                </a>
            );
        } else if (currentPage === 'article') {
            return (
                null
            );
        }/* else if (currentPage === 'help') {
            return (
                <a href="#" className="nq-c-ListingHeader-prev">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
                        <path fill="#3A3A3A" fillRule="evenodd"
                              d="M9.2585369,7.5 L14.6402993,2.11823763 C15.1199002,1.63863665 15.1199002,0.839301701 14.6402993,0.359700729 C14.1606983,-0.119900243 13.3613633,-0.119900243 12.8817624,0.359700729 L7.5,5.7414631 L2.11823763,0.359700729 C1.63863665,-0.119900243 0.839301701,-0.119900243 0.359700729,0.359700729 C-0.119900243,0.839301701 -0.119900243,1.63863665 0.359700729,2.11823763 L5.7414631,7.5 L0.359700729,12.8817624 C-0.119900243,13.3613633 -0.119900243,14.1606983 0.359700729,14.6402993 C0.839301701,15.1199002 1.63863665,15.1199002 2.11823763,14.6402993 L7.5,9.2585369 L12.8817624,14.6402993 C13.3613633,15.1199002 14.1606983,15.1199002 14.6402993,14.6402993 C15.1199002,14.1606983 15.1199002,13.3613633 14.6402993,12.8817624 L9.2585369,7.5 Z"/>
                    </svg>
                </a>
            );
        }*/

        return false;
    }


    render() {
        let {title, currentPage} = this.props;

        let blurBackground = (currentPage === 'article' || currentPage === 'article-hight');
        let whiteBg = currentPage === 'help' || currentPage === 'mentions';

        if (title === 'Voir l\'agenda') {
            title = 'Agenda';
        }
        if (typeof (title) !== 'undefined') {
            title = title.replace('<br/>', '');
        }

        return (
            <div className="nq-c-ListingHeader" data-blur={blurBackground} data-color={whiteBg}>
                {this.buildBg()}
                {this.buildNavigationButton(currentPage)}
                <div className="nq-c-ListingHeader-title">{title}</div>
            </div>
        );
    }
}

export default ListingHeader;