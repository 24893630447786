import Axio from 'tools/Axio';

let initialState = {
    address: {},
    type: ''
};

// Actions
const RECEIVE_SEGMENTS = 'Marker/Receive';
const RECEIVE_ERROR = 'Marker/Error';
const RECEIVE_LOAD = 'Marker/Load';

//add cache 30min
export const changeAddress = (address, typeAddress) => {
    return (dispatch) => {
        dispatch({
            type: RECEIVE_SEGMENTS,
            address,
            typeAddress
        });
    }

};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case RECEIVE_SEGMENTS:
            return {
                ...state,
                address: action.address,
                type: action.typeAddress
            };
        default:
            return state;
    }
}
