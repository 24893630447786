import Axio from 'tools/Axio';
import moment from 'moment';
import * as Ar from 'tools/Array';

let initialState = {
    isFetching: false,
    isLoaded: false,
    didErrors: false,
    data: [],
    error: null
};

// Actions
const RECEIVE_SEGMENTS = 'Articles/Receive';
const RECEIVE_ERROR = 'Articles/Error';
const RECEIVE_LOAD = 'Articles/Load';

//add cache 30min
export const fetch = (category) => {
    return (dispatch) => {
        dispatch({
            type: RECEIVE_LOAD
        });

        let cacheDate = localStorage.getItem('article-' + category + '-date');

        if (cacheDate !== null) {
            let date = moment(cacheDate);
            let now = moment();

            if (date.format('YYYY-MM-DD') == now.format('YYYY-MM-DD')) {
                let associations = JSON.parse(localStorage.getItem('article-associations'));
                let categories = JSON.parse(localStorage.getItem('article-categories'));

                let part1 = JSON.parse(localStorage.getItem('article-' + category + '-p1'));
                let part2 = JSON.parse(localStorage.getItem('article-' + category + '-p2'));
                let part3 = JSON.parse(localStorage.getItem('article-' + category + '-p3'));
                let part4 = JSON.parse(localStorage.getItem('article-' + category + '-p4'));


                let datas = {
                    association: associations,
                    categories: categories,
                    posts: part1.concat(part2).concat(part3).concat(part4)
                };
                console.log('Cache article');
                dispatch({
                    type: RECEIVE_SEGMENTS,
                    datas: datas
                });
                return;
            }
        }

        Axio(
            'articles/',
            {category},
            function (datas) {
                //TODO MANAGE ERRORS
                if (datas) {

                    let chunck = Ar.chunk(datas.posts, 4);

                    localStorage.setItem('article-associations', JSON.stringify(datas.association));
                    localStorage.setItem('article-categories', JSON.stringify(datas.categories));

                    localStorage.setItem('article-' + category + '-p1', JSON.stringify(chunck[0]));
                    localStorage.setItem('article-' + category + '-p2', JSON.stringify(chunck[1]));
                    localStorage.setItem('article-' + category + '-p3', JSON.stringify(chunck[2]));
                    localStorage.setItem('article-' + category + '-p4', JSON.stringify(chunck[3]));

                    localStorage.setItem('article-' + category + '-date', moment().format('YYYY-MM-DD HH:mm:ss'));
                    dispatch({
                        type: RECEIVE_SEGMENTS,
                        datas: datas
                    });
                }
            },
            function () {
                dispatch({
                    type: RECEIVE_ERROR,
                    datas: 'Erreur'
                });
            },
            'post'
        );
    }

};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case RECEIVE_LOAD:
            return {
                ...state,
                isFetching: true,
                isLoaded: false,
                didErrors: false,
                error: '',
                data: []
            };
        case RECEIVE_SEGMENTS:
            return {
                ...state,
                isLoaded: true,
                isFetching: false,
                didErrors: false,
                error: '',
                data: action.datas
            };
        case RECEIVE_ERROR:
            return {
                ...state,
                isFetching: false,
                isLoaded: true,
                didErrors: true,
                data: [],
                error: 'Adresse inconnue'
            };
        default:
            return state;
    }
}
