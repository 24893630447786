import here from 'assets/img/markers/you_are_here.png' ;
import velostation from 'assets/img/markers/velostation.png' ;
import velostationOn from 'assets/img/markers/velostation-on.png' ;

import CONSTANTES from 'configs/defines';

const iconsURL = [
    'ball.png',
    'bike.png',
    'bike-key.png',
    'bread.png',
    'bus-ticket.png',
    'camera.png',
    'car-key.png',
    'carabiner.png',
    'cigarette-book.png',
    'coffee.png',
    'eat.png',
    'fish-hook.png',
    'footprint.png',
    'graduate.png',
    'heart-beat.png',
    'horse.png',
    'ice-skating.png',
    'lotus.png',
    'money.png',
    'monument.png',
    'pharma-cross.png',
    'plant.png',
    'savoy.png',
    'shop.png',
    'sleep.png',
    'stethoscope.png',
    'swimmer.png',
    'target.png',
    'taxi.png',
    'unknown.png',
    'poste.png',
    'search.png',
    'test.svg',
    'tourist-office.png',
    'gare.png',
    'company-to-see.png',
    'museum.png',
    'town-hall.png',
    'service.png'
];
const MARKER_IMAGES = require.context('assets/img/markers', true);


const iconsURLTransport = [
    'bus.png',
    'bicycle.png',
    'auto.png',
    'train.png'
];

let icons = {
    'you_are_here.png': {
        icon: {
            url: here,
            size: [120 / 2, 234 / 2],
            scaledSize: [120 / 2, 234 / 2]
        }
    },
    'velostation.png': {
        icon: {
            url: velostation,
            size: [128 / 2, 146 / 2],
            scaledSize: [128 / 2, 146 / 2],
            urlActive: velostationOn,
            sizeActive: [128 / 2, 146 / 2],
            scaledSizeActive: [128 / 2, 146 / 2],
        }
    }
};

iconsURLTransport.map((url) => {
    let link = './' + url;
    try {
        let image = MARKER_IMAGES(link);
        let imageActive = MARKER_IMAGES(link.replace('.png', '-on.png'));
        icons[url] = {
            icon: {
                url: image,
                urlActive: imageActive,
                size: [120 / 2, 120 / 2],
                scaledSize: [120 / 2, 120 / 2],
                sizeActive: [120 / 2, 120 / 2],
                scaledSizeActive: [120 / 2, 120 / 2]
            }
        }
    } catch (e) {
        if (CONSTANTES.DEBUG) {
            console.log('Error image', link);
        }
    }
});

iconsURL.map((url) => {
    let link = './' + url;
    try {
        let image = MARKER_IMAGES(link);
        let imageActive = MARKER_IMAGES(link.replace('.png', '-on.png'));
        icons[url] = {
            icon: {
                url: image,
                urlActive: imageActive,
                size: [115 / 2, 149 / 2],
                scaledSize: [115 / 2, 149 / 2],
                sizeActive: [130 / 2, 169 / 2],
                scaledSizeActive: [130 / 2, 169 / 2]
            }
        }
    } catch (e) {
        if (CONSTANTES.DEBUG) {
            console.log('Error image', link);
        }
    }
});

export default icons;