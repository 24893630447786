import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import moment from "moment";

import Axio from 'tools/Axio';

import * as FavoritesStateActions from 'reducers/favorites';

import * as Validate from 'tools/Validate';
import * as Dom from 'tools/Dom';

import CONSTANTES from 'configs/defines';

class Interests extends Component {

    state = {
        open: false,
        mail: ''
    };

    timerBlur = null;
    noCloseKeyboard = false;

    constructor(props) {
        super(props);
        props.favoritesStateActions.init();

        document.addEventListener(CONSTANTES.keys.events.reset, () => {
            props.favoritesStateActions.removeAll();
            this.setState({
                mail: '',
                open: false,
                popupInOpen: false
            })
        });
    }

    onToogle() {
        this.setState({
            open: !this.state.open
        });
    }

    onRemoveFav(favorite) {
        let {favorites} = this.props;

        if (favorites.data.length === 1) {
            this.setState({
                open: false
            });
        }
        this.props.favoritesStateActions.toogle(favorite);
    }

    onRemoveAlFav() {
        this.setState({
            open: false
        });
        this.props.favoritesStateActions.removeAll();
    }

    openPopupSender(){
        document.dispatchEvent(new CustomEvent(CONSTANTES.keys.events.openPopupMail, {
            detail: {
                callback: this.sendWithEmail.bind(this)
            }
        }));
    }

    sendWithEmail(mail) {

        let {favorites, markerCategories} = this.props;

        let dataMail = [];
        favorites.data.map((favorite, indexFav) => {

            let temp = {};
            let type = '';

            if (
                typeof (markerCategories.data) !== 'undefined' &&
                favorite.data != null &&
                typeof (markerCategories.data[favorite.data.category]) !== 'undefined'
            ) {
                type = markerCategories.data[favorite.data.category].name;
            }

            if (
                typeof (markerCategories.data) !== 'undefined' &&
                favorite.data != null &&
                typeof (markerCategories.data[favorite['category-blog']]) !== 'undefined'
            ) {
                type = markerCategories.data[favorite['category-blog']].name;
            }
            temp.type = type;

            let dataFav = favorite;
            if (favorite.type && favorite.type === 'marker') {
                dataFav = favorite.data;
            }


            let displayDate = false;
            let startDisplay = '';
            let endDisplay = '';

            if (dataFav.date_end && dataFav.date_start) {
                displayDate = true;

                let start = moment(dataFav.date_start);
                let end = moment(dataFav.date_end);
                let now = moment();
                let tomorow = moment().add(1, 'd');

                startDisplay = start.format('DD') + ' ' + start.format('MMMM');
                endDisplay = end.format('DD') + ' ' + end.format('MMMM');

                let nowDisplay = now.format('DD') + ' ' + now.format('MMMM');
                let tomorowDisplay = tomorow.format('DD') + ' ' + tomorow.format('MMMM');

                if (nowDisplay === startDisplay) {
                    startDisplay = 'Aujoud\'hui';
                }

                if (nowDisplay === endDisplay) {
                    endDisplay = 'Aujoud\'hui';
                }

                if (tomorowDisplay === startDisplay) {
                    startDisplay = 'Demain';
                }

                if (tomorowDisplay === endDisplay) {
                    endDisplay = 'Demain';
                }

                if (
                    dataFav.date_start.indexOf('01-01') !== -1 &&
                    dataFav.date_end.indexOf('12-31') !== -1
                ) {
                    displayDate = false;
                }
            }


            if (displayDate) {
                if (dataFav.date_end == dataFav.date_start) {
                    temp.date = startDisplay;
                } else {
                    temp.date = startDisplay + ' - ' + endDisplay;
                }
            }

            temp.name = dataFav.name;
            temp.address = dataFav.address;
            temp.postcode = dataFav.postcode;
            temp.city = dataFav.city;
            temp.phone = dataFav.phone;
            temp.email = dataFav.email;
            temp.siteweb = dataFav.siteweb;

            dataMail.push(temp);
        });


        //MailFavorites

        //
        Axio(
            'mail-favorites/',
            {
                mail: mail,
                favorites: dataMail
            },
            function (datas) {

            },
            function () {

            },
            'post'
        );
    }

    render() {
        let {open} = this.state;
        let {favorites, markerCategories} = this.props;

        if (favorites.data.length === 0) {
            //return (null);
        }

        return (
            <div>
                <div className="nq-c-NavInterest" onClick={this.onToogle.bind(this)}>
                    <div className="nq-c-NavInterest-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18">
                            <polygon fill="#FFF" fillRule="evenodd"
                                     points="9.5 0 12.169 6.534 19 7.003 13.76 11.268 15.498 18 9.5 14.4 3.502 18 5.24 11.268 0 7.003 6.831 6.534"/>
                        </svg>
                        <div className="nq-c-NavInterest-nb">{favorites.data.length}</div>
                    </div>
                    <div className="nq-c-NavInterest-label">Ma liste d'intérêts</div>
                </div>

                <div className="nq-c-Interest" data-active={open}>

                    <div className="nq-c-Interest-mask" onClick={this.onToogle.bind(this)}></div>
                    <div className="nq-c-Interest-wrapper">
                        <div className="nq-c-Interest-close" onClick={this.onToogle.bind(this)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14">
                                <path fill="#FFF" fillRule="evenodd"
                                      d="M3.87437905,3.00605402 C3.59910078,3.03384513 3.33845329,3.15278405 3.12912069,3.34398101 L-2.49809976,8.4662428 C-2.78176527,8.7074615 -2.96076447,9.05982758 -2.99425983,9.4433427 C-3.02775519,9.82685782 -2.91366196,10.2092347 -2.67814402,10.501602 C-2.44158371,10.7950507 -2.10454268,10.972907 -1.74236406,10.99738 C-1.38018543,11.0207233 -1.02430393,10.8873311 -0.756341052,10.6272112 L4.00000005,6.29633888 L8.75634115,10.6272112 C9.02430402,10.8873254 9.38018552,11.0207148 9.74236415,10.99738 C10.1045428,10.9740367 10.4415865,10.7950677 10.6781441,10.5027118 C10.9136594,10.2092631 11.0277553,9.82796765 10.9942599,9.4433427 C10.9607646,9.05982758 10.7817734,8.70747288 10.4980999,8.4662428 L4.8708794,3.34398101 C4.59560114,3.09275968 4.23551262,2.97048276 3.87437905,3.00605402 Z"
                                      transform="rotate(90 4 7)"/>
                            </svg>
                        </div>

                        <div className="nq-c-Interest-title">Ma liste d'intérêts</div>
                        <ul className="nq-c-Interest-list">
                            {favorites.data.map((favorite, indexFav) => {
                                let type = '';

                                if (
                                    typeof (markerCategories.data) !== 'undefined' &&
                                    favorite.data != null &&
                                    typeof (markerCategories.data[favorite.data.category]) !== 'undefined'
                                ) {
                                    type = markerCategories.data[favorite.data.category].name;
                                }

                                if (
                                    typeof (markerCategories.data) !== 'undefined' &&
                                    favorite.data != null &&
                                    typeof (markerCategories.data[favorite['category-blog']]) !== 'undefined'
                                ) {
                                    type = markerCategories.data[favorite['category-blog']].name;
                                }

                                let dataFav = favorite;
                                if (favorite.type && favorite.type === 'marker') {
                                    dataFav = favorite.data;
                                }


                                let dateDom = (null);
                                let displayDate = false;
                                let startDisplay = '';
                                let endDisplay = '';

                                if (dataFav.date_end && dataFav.date_start) {
                                    displayDate = true;

                                    let start = moment(dataFav.date_start);
                                    let end = moment(dataFav.date_end);
                                    let now = moment();
                                    let tomorow = moment().add(1, 'd');

                                    startDisplay = start.format('DD') + ' ' + start.format('MMMM');
                                    endDisplay = end.format('DD') + ' ' + end.format('MMMM');

                                    let nowDisplay = now.format('DD') + ' ' + now.format('MMMM');
                                    let tomorowDisplay = tomorow.format('DD') + ' ' + tomorow.format('MMMM');

                                    if (nowDisplay === startDisplay) {
                                        startDisplay = 'Aujoud\'hui';
                                    }

                                    if (nowDisplay === endDisplay) {
                                        endDisplay = 'Aujoud\'hui';
                                    }

                                    if (tomorowDisplay === startDisplay) {
                                        startDisplay = 'Demain';
                                    }

                                    if (tomorowDisplay === endDisplay) {
                                        endDisplay = 'Demain';
                                    }

                                    if (
                                        dataFav.date_start.indexOf('01-01') !== -1 &&
                                        dataFav.date_end.indexOf('12-31') !== -1
                                    ) {
                                        displayDate = false;
                                    }
                                }


                                if (displayDate) {
                                    if (dataFav.date_end == dataFav.date_start) {
                                        dateDom = (<div className="nq-c-InterestItem-soon">{startDisplay}</div>);
                                    } else {
                                        dateDom = (<div
                                            className="nq-c-InterestItem-soon">{startDisplay} - {endDisplay}</div>);
                                    }
                                }

                                return (
                                    <li
                                        className="nq-c-InterestItem"
                                        data-active="true"
                                        key={'favorite' + indexFav}
                                    >
                                        <div className="nq-c-InterestItem-center">
                                            <div className="nq-c-InterestItem-category">{type}</div>
                                            <div className="nq-c-InterestItem-title">{dataFav.name}</div>

                                            {dateDom}

                                            <div className="nq-c-InterestItem-content">
                                                <div className="nq-c-InterestItem-info">
                                                    <div className="nq-c-InterestItem-info-icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="13"
                                                             height="18"
                                                             viewBox="0 0 13 18">
                                                            <path fill="#FFF" fillRule="evenodd"
                                                                  d="M6.50513479,0 L6.50529525,0 C10.0854728,0 13,3.13892606 13,6.99871935 C13,10.7672162 6.99931001,17.6006229 6.74545357,17.8886241 C6.68277172,17.9606245 6.59502033,18 6.50517758,18 C6.41115479,18 6.32444908,17.9606245 6.26176722,17.8886241 C6.00689987,17.6006229 0,10.7675042 0,6.99871935 C0,3.13863806 2.91773641,0 6.50513479,0 Z M6.5,9 C7.88071187,9 9,7.88071187 9,6.5 C9,5.11928813 7.88071187,4 6.5,4 C5.11928813,4 4,5.11928813 4,6.5 C4,7.88071187 5.11928813,9 6.5,9 Z"/>
                                                        </svg>
                                                    </div>
                                                    <div className="nq-c-InterestItem-info-content">
                                                        {dataFav.address}<br/>
                                                        {dataFav.postcode} {dataFav.city}
                                                    </div>
                                                </div>
                                                <div className="nq-c-InterestItem-info">
                                                    <div className="nq-c-InterestItem-info-icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                             height="16"
                                                             viewBox="0 0 16 16">
                                                            <path fill="#FFF" fillRule="evenodd"
                                                                  d="M3.10791056,0.204642262 L5.99389609,3.09998095 C6.26591936,3.37288582 6.26591936,3.86467858 5.99389609,4.138293 L4.08755703,6.0508103 C4.84978436,7.5809333 5.55818736,8.61848121 6.53794263,9.60221228 C7.51835076,10.5857978 8.66171896,11.2410606 9.96870009,11.8969783 L11.8750392,9.98446103 C12.1470624,9.71155616 12.5827168,9.71155616 12.909997,9.98446103 L15.7959825,12.8797997 C16.0680058,13.1527046 16.0680058,13.5897708 15.7959825,13.8633852 L14.5980827,15.0104408 C9.91493016,19.7635339 -3.75442043,6.10482727 0.983499513,1.35173419 L2.12684958,0.204678649 C2.39887285,-0.0682262164 2.83452718,-0.0682262164 3.10725771,0.204678649 L3.10791056,0.204642262 Z"/>
                                                        </svg>
                                                    </div>
                                                    <div className="nq-c-InterestItem-info-content">
                                                        {dataFav.phone}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="nq-c-InterestItem-right"
                                            onClick={this.onRemoveFav.bind(this, favorite)}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19"
                                                 viewBox="0 0 18 19">
                                                <g fill="none" fillRule="evenodd">
                                                    <path fill="#3A3A3A"
                                                          d="M5.93969192 0C5.4203007 0 5 .447275 5 1 5 1.55175 5.4203007 2 5.93969192 2L12.0603081 2C12.5796993 2 13 1.55175 13 1 13 .447275 12.5796993 0 12.0603081 0L5.93969192 0zM17.0374423 3L.962608283 3C.43055062 3 0 3.4294541 0 3.95806826 0 4.48761654.43055062 4.91613652.962608283 4.91613652L1.92521657 4.91613652 2.96021299 16.3652918 2.95927204 16.3652918C3.08523896 17.858537 4.34208291 19.0054884 5.84709689 18.9999795L12.1529031 18.9999795C13.6579171 19.0055938 14.9148669 17.858537 15.040728 16.3652918L16.0757244 4.91613652 17.0383327 4.91613652 17.0373917 4.91613652C17.5694494 4.91613652 18 4.48761654 18 3.95806826 18 3.4294541 17.5694494 3 17.0373917 3L17.0374423 3z"/>
                                                    <g fill="#FFF" transform="translate(5 6)">
                                                        <path
                                                            d="M-2.06030808,4.00025162 C-2.5796993,4.00025162 -3,3.97164828 -3,4.52437328 C-3,5.07612328 -2.5796993,4.99837231 -2.06030808,4.99837231 L4.06030808,4.99837231 C4.5796993,4.99837231 5,5.07612328 5,4.52437328 C5,3.97164828 4.49393955,4.00025162 3.97454834,4.00025162 L-2.06030808,4.00025162 Z"
                                                            transform="rotate(90 1 4.501)"/>
                                                        <path
                                                            d="M0.939691915,4.00025162 C0.420300701,4.00025162 0,3.97164828 0,4.52437328 C0,5.07612328 0.420300701,4.99837231 0.939691915,4.99837231 L7.06030808,4.99837231 C7.5796993,4.99837231 8,5.07612328 8,4.52437328 C8,3.97164828 7.49393955,4.00025162 6.97454834,4.00025162 L0.939691915,4.00025162 Z"
                                                            transform="rotate(90 4 4.501)"/>
                                                        <path
                                                            d="M3.93969192,4.00025162 C3.4203007,4.00025162 3,3.97164828 3,4.52437328 C3,5.07612328 3.4203007,4.99837231 3.93969192,4.99837231 L10.0603081,4.99837231 C10.5796993,4.99837231 11,5.07612328 11,4.52437328 C11,3.97164828 10.4939396,4.00025162 9.97454834,4.00025162 L3.93969192,4.00025162 Z"
                                                            transform="rotate(90 7 4.501)"/>
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                    </li>
                                );

                            })}


                        </ul>

                        <div className="nq-c-Interest-actions">


                            {favorites.data.length === 0 ? (
                                <div>
                                    <p>Vous pouvez créer une liste d'intérêts en sauvegardant des lieux ou événement qui
                                        vous intéressent grâce au bouton présent sur les fiches en question :</p>
                                    <div className="nq-c-Button" data-border={true} style={{
                                        marginTop: 20
                                    }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18"
                                             viewBox="0 0 19 18">
                                            <polygon fill="#FFF" fillRule="evenodd"
                                                     points="9.5 0 12.169 6.534 19 7.003 13.76 11.268 15.498 18 9.5 14.4 3.502 18 5.24 11.268 0 7.003 6.831 6.534"></polygon>
                                        </svg>
                                        <span>{'Ajouter à ma liste'}</span>
                                    </div>
                                    {false && (<p style={{
                                        marginTop: 20
                                    }}>Vous pouvez ensuite recevoir votre liste par email</p>)}
                                </div>
                            ) : (
                                <div className="nq-c-Links nq-c-Interest-delete"
                                     onClick={this.onRemoveAlFav.bind(this)}>Supprimer ma liste
                                </div>
                            )}

                            {favorites.data.length > 0 ? (
                                <a
                                    className="nq-c-Button nq-c-Interest-send"
                                    data-width="full"
                                    onClick={this.openPopupSender.bind(this)}
                                >
                                    Recevoir ma liste par email
                                </a>
                            ) : (null)
                            }


                            <div className="nq-c-Interest-rgpd">
                                Les données ajoutées à cette liste ne sont en aucun cas sauvegardées.
                                Elles seront automatiquement effacées au bout de 5min d’inactivité sur la
                                borne.
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        favorites: state.favorites,
        markerCategories: state.markerCategories
    }),
    dispatch => ({
        favoritesStateActions: bindActionCreators(FavoritesStateActions, dispatch)
    })
)(Interests);


/*

<li className="nq-c-InterestItem">
                                            <div className="nq-c-InterestItem-left">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="3"
                                                     viewBox="0 0 13 3">
                                                    <path fill="#3A3A3A"
                                                          d="M1.5,3 C0.671572875,3 0,2.32842712 0,1.5 C0,0.671572875 0.671572875,0 1.5,0 L11.5,0 C12.3284271,0 13,0.671572875 13,1.5 C13,2.32842712 12.3284271,3 11.5,3 L1.5,3 Z"/>
                                                </svg>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13"
                                                     viewBox="0 0 13 13">
                                                    <path fill="#3A3A3A"
                                                          d="M5,5 L5,1.5 C5,0.671572875 5.67157288,9.58043431e-16 6.5,8.8817842e-16 C7.32842712,8.18313409e-16 8,0.671572875 8,1.5 L8,5 L11.5,5 C12.3284271,5 13,5.67157288 13,6.5 C13,7.32842712 12.3284271,8 11.5,8 L8,8 L8,11.5 C8,12.3284271 7.32842712,13 6.5,13 C5.67157288,13 5,12.3284271 5,11.5 L5,8 L1.5,8 C0.671572875,8 9.09494702e-13,7.32842712 9.09494702e-13,6.5 C9.09494702e-13,5.67157288 0.671572875,5 1.5,5 L5,5 Z"/>
                                                </svg>
                                            </div>
                                            <div className="nq-c-InterestItem-center">
                                                <div className="nq-c-InterestItem-category">Spectacle</div>
                                                <div className="nq-c-InterestItem-title">Le lac des Cygnes</div>
                                                <div className="nq-c-InterestItem-soon">Aujourd'hui à 20h</div>
                                                <div className="nq-c-InterestItem-content">
                                                    <div className="nq-c-InterestItem-info">
                                                        <div className="nq-c-InterestItem-info-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="18"
                                                                 viewBox="0 0 13 18">
                                                                <path fill="#FFF" fillRule="evenodd"
                                                                      d="M6.50513479,0 L6.50529525,0 C10.0854728,0 13,3.13892606 13,6.99871935 C13,10.7672162 6.99931001,17.6006229 6.74545357,17.8886241 C6.68277172,17.9606245 6.59502033,18 6.50517758,18 C6.41115479,18 6.32444908,17.9606245 6.26176722,17.8886241 C6.00689987,17.6006229 0,10.7675042 0,6.99871935 C0,3.13863806 2.91773641,0 6.50513479,0 Z M6.5,9 C7.88071187,9 9,7.88071187 9,6.5 C9,5.11928813 7.88071187,4 6.5,4 C5.11928813,4 4,5.11928813 4,6.5 C4,7.88071187 5.11928813,9 6.5,9 Z"/>
                                                            </svg>
                                                        </div>
                                                        <div className="nq-c-InterestItem-info-content">
                                                            6 rue du Théâtre<br/>
                                                            73000 Chambéry
                                                        </div>
                                                    </div>
                                                    <div className="nq-c-InterestItem-info">
                                                        <div className="nq-c-InterestItem-info-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                                 viewBox="0 0 16 16">
                                                                <path fill="#FFF" fillRule="evenodd"
                                                                      d="M3.10791056,0.204642262 L5.99389609,3.09998095 C6.26591936,3.37288582 6.26591936,3.86467858 5.99389609,4.138293 L4.08755703,6.0508103 C4.84978436,7.5809333 5.55818736,8.61848121 6.53794263,9.60221228 C7.51835076,10.5857978 8.66171896,11.2410606 9.96870009,11.8969783 L11.8750392,9.98446103 C12.1470624,9.71155616 12.5827168,9.71155616 12.909997,9.98446103 L15.7959825,12.8797997 C16.0680058,13.1527046 16.0680058,13.5897708 15.7959825,13.8633852 L14.5980827,15.0104408 C9.91493016,19.7635339 -3.75442043,6.10482727 0.983499513,1.35173419 L2.12684958,0.204678649 C2.39887285,-0.0682262164 2.83452718,-0.0682262164 3.10725771,0.204678649 L3.10791056,0.204642262 Z"/>
                                                            </svg>
                                                        </div>
                                                        <div className="nq-c-InterestItem-info-content">
                                                            04 79 60 13 13
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="nq-c-InterestItem-right">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15"
                                                     viewBox="0 0 15 15">
                                                    <path fill="#3A3A3A" fillRule="evenodd"
                                                          d="M9.2585369,7.5 L14.6402993,2.11823763 C15.1199002,1.63863665 15.1199002,0.839301701 14.6402993,0.359700729 C14.1606983,-0.119900243 13.3613633,-0.119900243 12.8817624,0.359700729 L7.5,5.7414631 L2.11823763,0.359700729 C1.63863665,-0.119900243 0.839301701,-0.119900243 0.359700729,0.359700729 C-0.119900243,0.839301701 -0.119900243,1.63863665 0.359700729,2.11823763 L5.7414631,7.5 L0.359700729,12.8817624 C-0.119900243,13.3613633 -0.119900243,14.1606983 0.359700729,14.6402993 C0.839301701,15.1199002 1.63863665,15.1199002 2.11823763,14.6402993 L7.5,9.2585369 L12.8817624,14.6402993 C13.3613633,15.1199002 14.1606983,15.1199002 14.6402993,14.6402993 C15.1199002,14.1606983 15.1199002,13.3613633 14.6402993,12.8817624 L9.2585369,7.5 Z"/>
                                                </svg>
                                            </div>
                                        </li>




 */