import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Keyboard from "react-simple-keyboard";
import ReactGA from 'react-ga';

import Header from 'components/Header';
import Footer from 'components/Footer';
import Map from 'pages/Map';
import DiscoverHome from 'pages/DiscoverHome';
import DiscoverListing from 'pages/DiscoverListing';
import DiscoverArticle from 'pages/DiscoverArticle';
import Home from 'pages/Home';
import Mentions from 'pages/Mentions';

import * as CategoriesMarkerStateActions from 'reducers/marker-categories';

import CONSTANTES from 'configs/defines';
import 'tools/string';

import * as String from 'tools/string';
import * as Dom from 'tools/Dom';
import * as Validate from 'tools/Validate';

import 'assets/css/theme.dev.css';
import "react-simple-keyboard/build/css/index.css";
import "assets/css/keyboard.dev.css";


class Viewport extends Component {

	previousPage = false;
	previousData = false;
	previousPageDiscover = false;
	previousDataDiscover = false;

	goBackActive = false;
	idleTime = null;
	keyboardTime = null;
	noCloseKeyboard = false;
	keyboard = null;
	callbackSender = false;

	state = {
		currentPage: 'home', //'home', 'find', 'article', 'help', 'discover', 'listing', 'mentions'
		dataPage: [],
		loaded: false,
		loadedPart: [],
		openKeyboard: false,
		mail: '',
		popupInOpen: false
	};


	constructor(props) {
		super(props);
		props.categoriesMarkerStateActions.fetch();

		let html = document.querySelectorAll('html');

		if (html.length > 0) {
			[
				'mousemove',
				'click',
				'mouseup',
				'mousedown',
				'keydown',
				'keypress',
				'keyup',
				'submit',
				'change',
				'mouseenter',
				'scroll',
				'resize',
				'dblclick'
			].map((eventName) => {
				html[0].addEventListener(eventName, (event) => {

					if (eventName == 'click') {
						if (Dom.getClosest(event.target, '.keyboard-wrapper') !== null) {
							this.noCloseKeyboard = true;
							window.setTimeout(() => {
								this.noCloseKeyboard = false;
							}, 50);
						}
					}
					clearTimeout(this.idleTime);
					this.resetPage();
				}, false);
			});
		}

		document.addEventListener(CONSTANTES.keys.events.loadedApp, this.onLoadCheckApp.bind(this), false);

		document.addEventListener(CONSTANTES.keys.events.openKeyboard, this.openKeyboard.bind(this), false);
		document.addEventListener(CONSTANTES.keys.events.closeKeyboard, this.closeKeyboard.bind(this), false);


		document.addEventListener(CONSTANTES.keys.events.openPopupMail, this.openSender.bind(this), false);
		document.addEventListener(CONSTANTES.keys.events.closePopupMail, this.closeSender.bind(this), false);


		ReactGA.initialize('UA-142688585-1', {
			debug: true,
			standardImplementation: false
		});
	}


	onKeyPress(button) {
		if (button === '{enter}') {
			document.dispatchEvent(new CustomEvent(CONSTANTES.keys.events.forceCloseKeyboard, {
				detail: {}
			}));
		}
	}

	onChangeInput(value) {
		if (this.state.popupInOpen) {
			this.setState({
				mail: value
			})
			return;
		}
		document.dispatchEvent(new CustomEvent(CONSTANTES.keys.events.onKeyPress, {
			detail: value
		}));

	}

	onBlurInput() {
		if (this.noCloseKeyboard) {
			this.noCloseKeyboard = false;
			this.input.focus();
			return;
		}

		this.timerBlur = setTimeout(() => {
			if (this.noCloseKeyboard) {
				this.noCloseKeyboard = false;
				this.input.focus();
				return;
			}

			document.dispatchEvent(new CustomEvent(CONSTANTES.keys.events.closeKeyboard, {
				detail: {}
			}));

			this.setState({
				focus: false
			});

		}, 20);
	}

	onFocusInput() {
		let {address} = this.state;

		this.timerBlur = setTimeout(() => {
			document.dispatchEvent(new CustomEvent(CONSTANTES.keys.events.openKeyboard, {
				detail: this.state.mail
			}));

			this.setState({
				focus: true
			});
		}, 30);
	}

	openKeyboard(event) {
		this.keyboard.keyboard.setInput(event.detail);
		this.setState({
			openKeyboard: true
		});
	}

	closeKeyboard() {
		this.setState({
			openKeyboard: false
		});
	}

	onLoadCheckApp(event) {
		let loaded = this.state.loadedPart;

		loaded.push(event.detail.name);

		let newState = {
			loadedPart: loaded
		};

		if (loaded.length > 1) {
			newState['loaded'] = true;
		}
		this.setState(newState);
	}

	resetPage() {
		clearTimeout(this.idleTime);
		this.idleTime = setTimeout(() => {
			if (this.state.currentPage === 'home') {
				return;
			}

			/*document.dispatchEvent(new CustomEvent(CONSTANTES.keys.events.reset, {
				detail: {}
			}));

			this.setState({
				loaded: false,
				loadedPart: []
			});

			this.previousPage = false;
			this.previousData = false;
			this.goBackActive = false;

			this.changePage('home');
			let elm = document.querySelector('.nq-c-ListingArticles-list');
			if (elm !== null) {
				elm.scrollTop = 0;

			}*/

			document.location.reload();
		}, 5 * 60 * 1000);
	}

	componentDidCatch(error, errorInfo) {
		//TODO manage error
		/*this.setState({ error });
		Sentry.withScope(scope => {
			Object.keys(errorInfo).forEach(key => {
				scope.setExtra(key, errorInfo[key]);
			});
			Sentry.captureException(error);
		});



		console.log(error, errorInfo);
		Axio(
			'set-logs/',
			{message: error},
			function (datas) {
				//document.location.reload();
			},
			function () {
				//document.location.reload();
			},
			'post'
		);
		*/

	}

	changePage(pageName, data = [], back = false) {

		if (pageName === 'previous') {
			pageName = this.previousPage;
			data = this.previousData;
		}

		if (pageName === 'discover-previous') {
			pageName = 'discover';
			if (this.previousPageDiscover !== false) {
				pageName = this.previousPageDiscover;
				data = this.previousDataDiscover;
			}

			if (pageName == this.state.currentPage) {
				pageName = 'discover';
				data = [];
			}
		}


		if (
			pageName != 'home' &&
			pageName != 'find' &&
			pageName != 'article' &&
			pageName != 'help' &&
			pageName != 'discover' &&
			pageName != 'listing' &&
			pageName != 'mentions'
		) {
			pageName = 'discover';
			data = [];
		}

		this.setState({
			currentPage: pageName,
			dataPage: data
		});

		let pageNameGoogle = '/' + pageName;
		if (pageName == 'article') {
			pageNameGoogle += '/' + String.slugify(data.name);
		}

		if (pageName == 'listing') {
			pageNameGoogle += '/' + data.key;
		}

		pageNameGoogle = CONSTANTES.id + pageNameGoogle;

		ReactGA.ga('send', {
			hitType: 'pageview',
			location: 'https://totem.synchro-bus.fr',
			page: pageNameGoogle,
			title: typeof (data.name) !== 'undefined' ? data.name : data.label
		});

		if (pageName !== 'mentions') {
			this.previousPage = pageName;
			this.previousData = data;
		}

		if (pageName !== 'mentions' && pageName !== 'find') {
			this.previousPageDiscover = pageName;
			this.previousDataDiscover = data;
		}
	}

	onChangeInputMail(ev) {
		let value = ev;

		if (typeof (ev) !== 'string') {
			let {target} = ev;
			value = target.value;
		}

		this.setState({
			mail: value
		});
	}

	openSender(evt) {
		this.setState({
			popupInOpen: true,
			mail: ''
		});
		this.callbackSender = evt.detail.callback;
	}

	closeSender() {
		this.setState({
			popupInOpen: false
		});
	}

	sendWithEmail() {
		let mail = this.state.mail;

		if (mail == '' || !Validate.email(mail)) {
			alert('E-mail invalide');
			return;
		}
		this.callbackSender(mail);
		this.setState({
			mail: ''
		});
		this.closeSender();
	}

	render() {
		let {
			currentPage,
			dataPage,
			loaded,
			loadedPart
		} = this.state;

		let stylePage = {
			home: {display: currentPage === 'home' ? 'flex' : 'none'},
			map: {display: currentPage === 'find' ? 'flex' : 'none'},
			discoverHome: {display: currentPage === 'discover' ? 'flex' : 'none'},
			discoverListing: {display: currentPage === 'listing' ? 'flex' : 'none'},
			discoverArticle: {display: (currentPage === 'article' || currentPage === 'article-hight') ? 'flex' : 'none'},
			help: {display: currentPage === 'help' ? 'flex' : 'none'},
			mentions: {display: currentPage === 'mentions' ? 'flex' : 'none'}
		};

		return (
			<div>

				<div className="nq-c-InterestPopin" data-active={this.state.popupInOpen ? 'true' : 'false'}>
					<div className="nq-c-InterestPopin-mask"></div>
					<form className="nq-c-InterestPopin-wrapper">
						<div className="nq-c-InterestPopin-title">Recevoir votre liste d'intérêts</div>
						<input
							type="text"
							className="nq-c-Text nq-c-InterestPopin-input"
							placeholder="Votre adresse e-mail"
							onChange={this.onChangeInputMail.bind(this)}
							onBlur={this.onBlurInput.bind(this)}
							onFocus={this.onFocusInput.bind(this)}
							value={this.state.mail}
							ref={(el) => {
								this.input = el;
							}}
						/>
						<div className="nq-c-InterestPopin-rgpd">
							Votre adresse n’est pas enregistrée par le totem et ne sera donc pas utilisée à des fins
							commerciales.
						</div>
						<div className="nq-c-InterestPopin-actions">
							<div
								className="nq-c-Links nq-c-InterestPopin-cancel"
								onClick={this.closeSender.bind(this)}
							>
								Annuler
							</div>
							<div
								className="nq-c-Button nq-c-InterestPopin-send"
								onClick={this.sendWithEmail.bind(this)}
							>
								Envoyer
							</div>
						</div>
					</form>
				</div>

				<Header />

				<Home
					style={stylePage.home}
					currentPage={currentPage}
					changePage={this.changePage.bind(this)}
					loaded={loaded}
					loadedPart={loadedPart}
				/>


				<Map
					style={stylePage.map}
					currentPage={currentPage}
					changePage={this.changePage.bind(this)}
				/>

				<DiscoverHome
					style={stylePage.discoverHome}
					currentPage={currentPage}
					changePage={this.changePage.bind(this)}
				/>
				<DiscoverListing
					style={stylePage.discoverListing}
					currentPage={currentPage}
					changePage={this.changePage.bind(this)}
					dataPage={dataPage}
				/>
				<DiscoverArticle
					style={stylePage.discoverArticle}
					currentPage={currentPage}
					changePage={this.changePage.bind(this)}
					dataPage={dataPage}
				/>

				<Mentions
					style={stylePage.mentions}
					currentPage={currentPage}
					changePage={this.changePage.bind(this)}
				/>

				<Footer
					changePage={this.changePage.bind(this)}
				/>


				<div className={'keyboard-wrapper'} style={{
					display: this.state.openKeyboard ? 'block' : 'none'
				}}>
					<Keyboard
						ref={(el) => {
							if (el === null) {
								return
							}
							this.keyboard = el;
						}}
						onKeyPress={this.onKeyPress.bind(this)}
						onChange={this.onChangeInput.bind(this)}
						theme={"hg-theme-default hg-layout-default myTheme"}
						layoutName={"default"}
						layout={{
							default: [
								"1 2 3 4 5 6 7 8 9 0 {bksp}",
								"a z e r t y u i o p",
								"q s d f g h j k l m",
								"w x c v b n ' . {enter}",
								".fr .com @gmail.com {space} - @"
							]
						}}
						display={{
							"{enter}": 'OK',
							"{bksp}": '<',
							'{space}': ' '
						}}
					/>
				</div>

			</div>
		);
	}
}

export default connect(
	state => ({}),
	dispatch => ({
		categoriesMarkerStateActions: bindActionCreators(CategoriesMarkerStateActions, dispatch)
	})
)(Viewport);


/*

                <Help
                    style={stylePage.help}
                    currentPage={currentPage}
                    changePage={this.changePage.bind(this)}
                />
 */
