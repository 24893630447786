import React, {Component} from 'react';

import TabMain from 'components/navigations/TabMain';
import Interests from 'components/Interests';
import ListingHeader from 'components/listing/ListingHeader';
import HelpSidebar from 'components/help/HelpSidebar';
import HelpContent from 'components/help/HelpContent';

let tabs = [
    {
        title: 'Navigation dans la carte',
        content: '<b>Content 1</b>' +
            '<p>Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Sed posuere consectetur est at lobortis. Donec sed odio dui. Aenean lacinia bibendum nulla sed consectetur.</p>' +
            '<img src="https://img.newquest.fr/600/300" alt="">' +
            '<p>Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Sed posuere consectetur est at lobortis. Donec sed odio dui. Aenean lacinia bibendum nulla sed consectetur.</p>' +
            '<img src="https://img.newquest.fr/300/300" alt="">'
    },
    {
        title: 'Envoi de son itinéraire par email',
        content: '<b>Content 2</b>'
    },
    {
        title: 'Navigation dans la liste des lieux et des évènements',
        content: '<b>Content 3</b>'
    },
    {
        title: 'Ma liste d’intérêts',
        content: '<b>Content 4</b>'
    },
    {
        title: 'Activer le mode pour les Personnes à Mobilité Réduite',
        content: '<b>Content 5</b>'
    },
]

class Mentions extends Component {

    state = {
        activeIndex: 0
    }

    toggleTab(index) {
        this.setState({
            activeIndex: index
        });
    }

    render() {
        let {style, changePage, currentPage} = this.props;

        return (
            <div className={'nq-c-Body nq-c-Body-mentions'} data-pattern={true} style={style}>

                <Interests/>

                <TabMain
                    currentPage={''}
                    changePage={changePage}
                />

                <ListingHeader
                    title={'Mentions légales'}
                    currentPage={'mentions'}
                    img={false}
                    changePage={changePage}
                />

                <div className={'nq-c-Body-wrapper nq-c-Mentions'}>



                    <div className={'part'}>
                        <h2>Éditeur</h2>
                        <p>
                            (pour le compte de Grand Chambéry)<br/>
                            Keolis Chambéry<br/>
                            18 avenue des Chevaliers Tireurs<br/>
                            73026 CHAMBERY Cedex<br/><br/>
                            Contact : <span className={'link'}>info@synchro-bus.fr</span>
                        </p>
                    </div>

                    <div className={'part'}>
                        <h2>Conception</h2>
                        <p>
                            NewQuest<br/>
                            20 Avenue des Chevaliers Tireurs<br/>
                            73 000 Chambéry<br/>
                            +(33)04 79 33 50 82<br/>
                            <span className={'link'}>www.newquest-group.com</span><br/>
                            <span className={'link'}>hello@newquest.fr</span>
                        </p>
                    </div>

                    <div className={'part'}>
                        <h2>Objet</h2>
                        <p>
                            Le présent document a pour objet de définir les modalités et conditions dans lesquelles
                            d’une part Grand Chambéry (Autorité organisatrice propriétaire du site) et Keolis Chambéry
                            (exploitant du réseau Synchro Bus), mettent à la disposition de leurs utilisateurs les
                            totems digitaux et leurs services disponibles. D’autre part, la manière par laquelle
                            l’utilisateur accède à l’application et utilise ses services. L’utilisation de l’application
                            smartphone « Synchro Chambéry » implique l’acceptation de l’ensemble des conditions décrites
                            ci-après.
                        </p>
                    </div>

                    <div className={'part'}>
                        <h2>Propriété Intellectuelle</h2>
                        <p>
                            La structure générale du contenu présent sur les totems digitaux, ainsi que les textes,
                            graphiques, images, sons, base de données et vidéos la composant, sont la propriété de Grand
                            Chambéry ou de Keolis Chambéry. Toute représentation et/ou reproduction et/ou exploitation
                            partielle ou totale des contenus et services proposés par les totems digitaux par quelque
                            procédé que ce soit, sans l'autorisation préalable et par écrit de Grand Chambéry est
                            strictement interdite et serait susceptible de constituer une contrefaçon au sens des
                            articles L 335-2 et suivants du Code de la propriété intellectuelle.<br/>
                            Les marques « Synchro Bus », « Synchro Vélostation », « Territoire Mobile », « Territoire
                            Connecté » sont la propriété exclusive de Grand Chambéry agissant en qualité d’Autorité
                            Organisatrice des Transports pour la communauté d’agglomération de Grand Chambéry.<br/>
                            Toute représentation et reproduction et exploitation partielle ou totale de ces marques, de
                            quelque nature que ce soit, devra être soumise à autorisation explicite de Grand Chambéry.
                        </p>
                    </div>
                    <div className={'part'}>
                        <h2>Copyright des Images, photographies et illustrations</h2>
                        <p>
                            Sauf mention contraire, les images, photographies et illustrations sont la propriété
                            exclusive de Grand Chambéry.<br/>
                            Tous droits de reproduction, téléchargement, copie, retouche et d'utilisation quelle qu'elle
                            soit de ces images par un tiers, sans autorisation explicite de Grand Chambery est
                            strictement interdite.

                        </p>
                    </div>
                    <div className={'part'}>
                        <h2>Responsabilité de l’éditeur</h2>
                        <p>
                            Les informations et documents figurant sur les totems digitaux proviennent exclusivement de
                            Keolis Chambéry et de Grand Chambéry.<br/>
                            Si, toutefois, ces informations et documents sont susceptibles de contenir des inexactitudes
                            techniques et des erreurs typographiques, Keolis Chambéry se réserve le droit de les
                            corriger, dès que ces erreurs sont portées à sa connaissance. Toute erreur ou demande
                            d’information émanant de l’utilisateur doit être portée à la connaissance de Keolis Chambéry
                            via la procédure de contact disponible dans les totems digitaux ou par email à l’adresse
                            info@synchro-bus.fr et sera traitée au même titre que les réclamations au sujet de l’offre
                            de transport et de l’exploitation du réseau Synchro Bus.<br/>
                            Les informations et documents disponibles sur les totems digitaux sont susceptibles d’être
                            modifiés à tout moment, et peuvent avoir fait l’objet de mises à jour. En particulier, ils
                            peuvent avoir fait l’objet d’une mise à jour entre le moment de leur téléchargement et celui
                            où l’utilisateur en prend connaissance.<br/>
                            L’utilisation des informations et documents disponibles sur les totems digitaux se fait sous
                            l’entière et seule responsabilité de l’utilisateur, qui assume la totalité des conséquences
                            pouvant en découler, sans que Keolis Chambéry et Grand Chambéry puissent être poursuivis à
                            ce titre, et sans recours contre ces derniers.<br/>
                            Keolis Chambéry et Grand Chambéry ne pourront en aucun cas être tenu responsable de tout
                            dommage de quelque nature qu’il soit résultant de l’interprétation erronée ou de
                            l’utilisation mal-appropriée des informations et documents disponibles sur les totems
                            digitaux.

                        </p>
                    </div>
                    <div className={'part'}>
                        <h2>Utilisation des totems</h2>
                        <p>
                            Keolis Chambéry s’efforce de permettre l’utilisation des totems digitaux 24 heures sur 24, 7
                            jours sur 7, sauf en cas de force majeure ou d’un événement hors du contrôle de Keolis
                            Chambéry, et sous réserve des éventuelles pannes et interventions de maintenance nécessaires
                            au bon fonctionnement d’un totem et/ou de ses services.<br/>
                            Par conséquent, Keolis Chambéry ne peut garantir une disponibilité de l’application et des
                            services, une fiabilité des transmissions et des performances en termes de temps de réponse
                            ou de qualité. Il n’est prévu aucune assistance technique vis à vis de l’utilisateur que ce
                            soit par des moyens électronique ou téléphonique. Mais l’utilisateur aura toujours la
                            possibilité de faire porter à la connaissance de Keolis Chambéry le problème rencontré, par
                            email à l’adresse info@synchro-bus.fr .<br/>
                            La responsabilité de Keolis Chambéry ne saurait être engagée en cas d’impossibilité d’accès
                            à l’application et d’utilisation des services.<br/>
                            Par ailleurs, Keolis Chambéry et Grand Chambéry peuvent être amenés à interrompre
                            l’application ou une partie des services, à tout moment sans préavis, le tout sans droit à
                            indemnités. L’utilisateur reconnaît et accepte que Keolis Chambéry ne soit pas responsable
                            des interruptions, et des conséquences qui peuvent en découler pour l’utilisateur ou tout
                            tiers.
                        </p>
                    </div>
                    <div className={'part'}>
                        <h2>Calculateur d’itinéraires</h2>
                        <p>
                            Le calculateur d’itinéraire mis à disposition des utilisateurs des totems digitaux a été
                            développé par la société Instant System.<br/>
                            Keolis Chambéry ne saurait être tenu responsable des anomalies de fonctionnement du
                            calculateur d’itinéraire ni d’une pertinence dégradée des résultats fournis dans les
                            feuilles de route, du fait d’un problème technique dont l’occurrence ne relèverait pas de la
                            responsabilité de Keolis Chambéry, ni des données mises à disposition du calculateur.
                            Néanmoins toute anomalie peut être remontée par email à l’adresse info@synchro-bus.fr , afin
                            que Keolis Chambéry puisse analyser la nécessité de sa correction et l’effectuer le cas
                            échéant.
                        </p>
                    </div>

                    <div className={'part'}>
                        <h2>Données temps réel et données statiques</h2>
                        <div>
                            Les totems digitaux utilisent de nombreuses sources de données.<br/><br/>

                            <h3>Données statiques :</h3>
                            <ul>
                                <li>Les points arrêts, les lignes de bus et les horaires sont fournis par Keolis
                                    Chambéry
                                </li>
                                <li>Les points de vente des titres de transport sont fournis par Keolis Chambéry.</li>
                                <li>Les pistes cyclables et les parkings vélo sont issus de la plateforme OpenData de
                                    Grand Chambéry.
                                </li>
                                <li>Les parkings voiture sont issus de la plateforme OpenData de Grand Chambéry.</li>

                            </ul><br/><br/>
                            <h3>Données temps réel :</h3>
                            <ul>
                                <li>Les prochains passages de bus en temps réel sont fournis par Keolis Chambéry.</li>
                                <li>Les informations trafic proviennent de Keolis Chambéry.</li>
                                <li>Les informations commerciales proviennent de Keolis Chambéry.</li>
                                <li>Les données concernant l’autopartage proviennent de l’API Citiz (www.citiz.coop).
                                </li>
                                <li>Les prochains départs en gare SNCF proviennent de l’API SNCF Gare&Connexions.</li>
                                <li>Les évènements, informations touristiques, commerces et les services proviennent
                                    d’APIDAE Tourisme, base mise à jour par Grand Chambéry Tourisme.
                                </li>
                                <li>La qualité de l’air fournie par l’API Atmo</li>
                                <li>La météo fournie par l’API Meteo.fr</li>

                            </ul><br/>
                            Keolis Chambéry ne saurait être tenu responsable d’un problème client lié à un retard, à l’indisponibilité d’un service ou à l’inexactitude d’une donnée. Keolis Chambéry est néanmoins responsable du rétablissement du service ou de la correction d’une donnée dans les meilleurs délais.
                        </div>
                    </div>

                    <div className={'part'}>
                        <h2>Référencement des commerces et des services</h2>
                        <p>
                            Les données présentes sur les totems concernant les commerces et les services proviennent d’APIDAE Tourisme qui est une plateforme de mise à disposition de données touristiques. Pour être référencé sur cette base il faut contacter Grand Chambéry Tourisme.<br/><br/>
                            Adresse : <br/><br/>
                            Grand Chambéry Tourisme<br/>
                            Pôle Marketing-Communication<br/>
                            +33 (0)4 79 79 09 14<br/>
                            Le Phare - 800 avenue du Grand Arietaz<br/>
                            73000 Chambéry – France<br/>
                        </p>

                    </div>
                    <div className={'part'}>
                        <h2>Envoi des Listes d’intérêt/Résultats calcul d’itinéraire par email </h2>
                        <p>
                            Les listes d’intérêt ainsi que les résultats du calcul d’itinéraires peuvent être envoyés par email. A noter que les adresses vers lesquelles sont envoyés ces emails ne sont pas sauvegardées et ne seront pas utilisées à des fins commerciales.
                        </p>
                    </div>
                    <div className={'part'}>
                        <h2>Modification des conditions d’utilisation</h2>
                        <p>
                            Keolis Chambéry se réserve la possibilité de modifier, à tout moment et sans préavis, les présentes conditions d’utilisation afin de les adapter aux évolutions du site et/ou de son exploitation.
                        </p>
                    </div>
                    <div className={'part'}>
                        <h2>Droit applicable</h2>
                        <p>
                            Tant le présent site que les modalités et conditions de son utilisation sont régis par le droit français, quel que soit le lieu d’utilisation. En cas de contestation éventuelle, et après l’échec de toute tentative de recherche d’une solution amiable, les tribunaux français seront seuls compétents pour connaître de ce litige.<br/>
                            Pour toute question relative aux présentes conditions d’utilisation du site, vous pouvez nous écrire à l’adresse suivante : <span className={'link'}>info@synchro-bus.fr</span>

                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default Mentions;
/*

 */