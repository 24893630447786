import Axio from 'tools/Axio';

let initialState = {
    isFetching: false,
    isLoaded: false,
    didErrors: false,
    data: [],
    error: null
};

// Actions
const RECEIVE_SEGMENTS = 'Meteo/Receive';
const RECEIVE_ERROR = 'Meteo/Error';
const RECEIVE_LOAD = 'Meteo/Load';

//add cache 30min
export const fetch = (address, type) => {
    return (dispatch) => {
        dispatch({
            type: RECEIVE_LOAD,
            inputType: type
        });

        Axio(
            'meteo/',
            {},
            function (datas) {
                //TODO MANAGE ERRORS
                if (datas) {
                    dispatch({
                        type: RECEIVE_SEGMENTS,
                        datas: datas,
                        inputType: type
                    });
                }
            },
            function () {
                dispatch({
                    type: RECEIVE_ERROR,
                    datas: 'Erreur',
                    inputType: type
                });
            },
            'post'
        );
    }

};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case RECEIVE_LOAD:
            return {
                ...state,
                isFetching: true,
                isLoaded: false,
                didErrors: false,
                error: '',
                data: []
            };
        case RECEIVE_SEGMENTS:
            return {
                ...state,
                isLoaded: true,
                isFetching: false,
                didErrors: false,
                error: '',
                data: action.datas
            };
        case RECEIVE_ERROR:
            return {
                ...state,
                isFetching: false,
                isLoaded: true,
                didErrors: true,
                data: [],
                error: 'Adresse inconnue'
            };
        default:
            return state;
    }
}
