import React, {Component} from 'react';
import moment from "moment";

import IconAddress from 'assets/img/icons/listing/address.svg';
import IconPhone from 'assets/img/icons/listing/phone.svg';
import IconEmail from 'assets/img/icons/listing/email.svg';
import IconWebsite from 'assets/img/icons/listing/website.svg';

let icons = {
    address: IconAddress,
    phone: IconPhone,
    email: IconEmail,
    website: IconWebsite
};

class ArticleInfos extends Component {
    render() {
        let {dataPage} = this.props;

        let htmlOptions = '';

        if(dataPage.open !== ''){
            htmlOptions += '<h3>Période d\'ouverture</h3><p>' + dataPage.open + '</p>';
        }

        if(dataPage.price !== ''){
            htmlOptions += '<h3>Tarifs</h3><p>' + dataPage.price + '</p>';
        }

        if(dataPage.equipments !== ''){
            htmlOptions += '<h3>Équipements</h3><p>' + dataPage.equipments + '</p>';
        }

        if(dataPage.services !== ''){
            htmlOptions += '<h3>Services</h3><p>' + dataPage.services + '</p>';
        }


        let domDate = (null);
        if (dataPage.date_end && dataPage.date_start) {

            let start = moment(dataPage.date_start);
            let end = moment(dataPage.date_end);
            let now = moment();
            let tomorow = moment().add(1, 'd');

            let startDisplay = start.format('DD') + ' ' + start.format('MMMM');
            let endDisplay = end.format('DD') + ' ' + end.format('MMMM');

            let nowDisplay = now.format('DD') + ' ' + now.format('MMMM');
            let tomorowDisplay = tomorow.format('DD') + ' ' + tomorow.format('MMMM');

            if (nowDisplay === startDisplay) {
                startDisplay = 'Aujourd\'hui';
            }

            if (nowDisplay === endDisplay) {
                endDisplay = 'Aujourd\'hui';
            }

            if (tomorowDisplay === startDisplay) {
                startDisplay = 'Demain';
            }

            if (tomorowDisplay === endDisplay) {
                endDisplay = 'Demain';
            }

            if (dataPage.date_end == dataPage.date_start) {
                domDate = (
                    <div className={'nq-c-ArticleInfos-hours'}>{startDisplay}</div>
                );
            } else {
                domDate = (
                    <div className={'nq-c-ArticleInfos-hours'}>{startDisplay} au {endDisplay}</div>
                );
            }
        }

        return (
            <div className={'nq-c-ArticleInfos'}>
                {dataPage.image && (<div className={'nq-c-ArticleInfos-header'}>
                    <img src={dataPage.image} className={'nq-c-ArticleInfos-img'} alt=""/>
                </div>)}
                <div className={'nq-c-ArticleInfos-details'}>
                    {domDate}
                    <ul className={'nq-c-ArticleInfos-list'} style={{marginTop: domDate === null ? 0 : 20}}>

                        {dataPage.address && (
                            <li className={'nq-c-ArticleInfos-item'} key={'articleInfo_address'}>
                                <div className={'nq-c-ArticleInfos-icon nq-c-ArticleInfos-icon-address'}>
                                    <img src={icons['address']} alt="Adresse"/>
                                </div>
                                <div className={'nq-c-ArticleInfos-content'}>
                                    {dataPage.address}<br/>
                                    {dataPage.postcode} {dataPage.city}
                                </div>
                            </li>
                        )}

                        {dataPage.phone && (
                            <li className={'nq-c-ArticleInfos-item'} key={'articleInfo_phone'}>
                                <div className={'nq-c-ArticleInfos-icon'}>
                                    <img src={icons['phone']} alt="Téléphone"/>
                                </div>
                                <div className={'nq-c-ArticleInfos-content nq-c-ArticleInfos-content-one-line'}>
                                    {dataPage.phone}
                                </div>
                            </li>
                        )}

                        {dataPage.email && (
                            <li className={'nq-c-ArticleInfos-item'} key={'articleInfo_email'}>
                                <div className={'nq-c-ArticleInfos-icon'}>
                                    <img src={icons['email']} alt="E-mail"/>
                                </div>
                                <div className={'nq-c-ArticleInfos-content nq-c-ArticleInfos-content-one-line'}>
                                    {dataPage.email}
                                </div>
                            </li>
                        )}


                        {dataPage.website && (
                            <li className={'nq-c-ArticleInfos-item'} key={'articleInfo_website'}>
                                <div className={'nq-c-ArticleInfos-icon'}>
                                    <img src={icons['website']} alt="Site web"/>
                                </div>
                                <div className={'nq-c-ArticleInfos-content'}>
                                    {dataPage.siteweb}
                                </div>
                            </li>
                        )}
                    </ul>
                </div>
                <div className={'nq-c-ArticleInfos-data-sup'} dangerouslySetInnerHTML={{__html:htmlOptions}}/>
            </div>
        );
    }
}

export default ArticleInfos;

/*

                    <div className={'nq-c-ArticleInfos-hours'}>{article.date.day} à {article.date.hour}</div>

{article.infos && }
                    <ArticleContent article={article}/>


                    ID: 8214
category-blog: "3202"
data: "{"patrimoineCulturelType":{"elementReferenceType":"PatrimoineCulturelType","id":3202,"libelleFr":"Parc et jardin","ordre":5,"description":"Parcs et jardins possu00e9dant un intu00e9ru00eat particulier"},"themes":[{"elementReferenceType":"PatrimoineCulturelTheme","id":3458,"libelleFr":"Parc","ordre":26},{"elementReferenceType":"PatrimoineCulturelTheme","id":3459,"libelleFr":"Jardin","ordre":5},{"elementReferenceType":"PatrimoineCulturelTheme","id":3464,"libelleFr":"Arboretum","ordre":10,"description":"Plantation d'arbres de nombreuses espu00e8ces sur un mu00eame terrain, en vue de leur u00e9tude botanique","parent":{"elementReferenceType":"PatrimoineCulturelTheme","id":3459,"libelleFr":"Jardin","ordre":5}}],"categories":[{"elementReferenceType":"PatrimoineCulturelCategorie","id":3242,"libelleFr":"Collection de vu00e9gu00e9taux","ordre":11}]}"
description: "Arboretum dans une forêt municipale plantée en essences originaires d'autres pays (Amérique en particulier) et verger conservatoire"
description_full: "Site inscrit le 2/05/1974. Le verger conservatoire regroupe diverses variétés anciennes de pommes et de poires. Un sentier parcourt l'arboretum où diverses espèces d'arbres communs ou plus rares sont identifiées par un panneau."

fax: "04 79 28 47 40"
gallerie: false
image: "https://keolis.newquest.fr/wp-content/uploads/2019/05/2096405-5-300x200.jpg"
latlng: {address: "Chateau de Chaffardon 73230 Saint-Jean-d'Arvey", lat: 45.589176, lng: 5.978494}

subcategory-blog: "3202"


address: "Chateau de Chaffardon"
city: "Saint-Jean-d'Arvey"
name: "Arboretum et verger conservatoire du château de Chaffardon"
phone: "04 79 28 40 61"
postcode: "73230"
email: "accueil@mairie-saintjeandarvey.fr"
siteweb: "http://www.mairie-saintjeandarvey.fr"

 */