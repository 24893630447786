import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import ImageLoader from 'react-loading-image';
import moment from 'moment';
import 'moment/locale/fr';
import Modal from 'react-modal';

import * as BackgroundMarkerStateActions from 'reducers/background';
import {addFunction} from 'tools/EnterFrame';
import HomeCovid from './HomeCovid';

import Configs from 'configs/defines';

import LoaderGif from 'assets/img/gifs/Spin.gif';

Modal.setAppElement('#root');
const customStyles = {
	content: {
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		padding: 0,
	},
	overlay: {
		zIndex: 9999999,
		padding: 0,
	}
};

class Home extends Component {

	state = {
		currentHour: this.getCurrentHour(),
		imageLoaded: false,
		openModal: true
	}

	constructor(props) {
		super(props);
		props.backgroundMarkerStateActions.fetch();

		addFunction({
			function: this.updateHour.bind(this),
			timing: 5
		});
	}

	updateHour() {
		this.setState({
			currentHour: this.getCurrentHour()
		});
	}

	getCurrentHour() {
		return (moment()).format('HH:mm');
	}

	onChangePage(pageName) {
		let {loaded} = this.props;
		if (!loaded) {
			return;
		}
		let {changePage} = this.props;
		changePage(pageName);
	}

	onLoadImage() {
		this.setState({
			imageLoaded: true
		});
	}

	onCloseCovid() {
		this.setState({
			openModal: false
		});
	}

	render() {
		let {background, style, loaded, loadedPart} = this.props;
		let {currentHour, imageLoaded} = this.state;

		let image = background;
		if (background.isLoaded) {
			let temp = background.data;
			if (temp && typeof (temp.url) !== 'undefined' && temp.url !== '') {
				image = temp.url;
			}
		}


		if (
			imageLoaded &&
			loadedPart.indexOf('image') === -1
		) {
			loadedPart.push('image');
		}

		return (
			<div className="nq-c-Home" style={style}>

				<Modal
					isOpen={this.state.openModal}
					style={customStyles}
				>
					<HomeCovid onClose={this.onCloseCovid.bind(this)} />
				</Modal>

				{!loaded && (
					<div className="nq-c-Home-progress">
						<div
							className="nq-c-Home-progress-bar"
							style={{
								width: parseInt(loadedPart.length * 100 / 3, 10) + '%'
							}}
						></div>
					</div>
				)}

				{!loaded && (
					<img src={LoaderGif} className="nq-c-Home-loader" />
				)}

				{image && (
					<ImageLoader
						src={image}
						image={props => <img className="nq-c-Home-bg" {...props} />}
						onLoad={this.onLoadImage.bind(this)}
					/>
				)}

				<div className="nq-c-Home-content">
					<div className="nq-c-HomeHeader">
						<div className="nq-c-HomeHeader-title">{Configs.titles.home}</div>
						<div className="nq-c-HomeHeader-hour">{currentHour}</div>
					</div>

					<div className="nq-c-HomeBody">
						<a
							key={'link-find'}
							className="nq-c-HomeAction"
							onClick={this.onChangePage.bind(this, 'find')}
							style={{
								visibility: (loaded ? 'visible' : 'hidden')
							}}
						>
							<div className="nq-c-HomeAction-title">Trouver</div>
							<div className="nq-c-HomeAction-subtitle">un lieu ou un itinéraire</div>
							<div className="nq-c-Nav nq-c-HomeAction-icon" data-type="full">
								<svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14">
									<path fill="#FFF" fillRule="evenodd"
									      d="M3.87437905,3.00605402 C3.59910078,3.03384513 3.33845329,3.15278405 3.12912069,3.34398101 L-2.49809976,8.4662428 C-2.78176527,8.7074615 -2.96076447,9.05982758 -2.99425983,9.4433427 C-3.02775519,9.82685782 -2.91366196,10.2092347 -2.67814402,10.501602 C-2.44158371,10.7950507 -2.10454268,10.972907 -1.74236406,10.99738 C-1.38018543,11.0207233 -1.02430393,10.8873311 -0.756341052,10.6272112 L4.00000005,6.29633888 L8.75634115,10.6272112 C9.02430402,10.8873254 9.38018552,11.0207148 9.74236415,10.99738 C10.1045428,10.9740367 10.4415865,10.7950677 10.6781441,10.5027118 C10.9136594,10.2092631 11.0277553,9.82796765 10.9942599,9.4433427 C10.9607646,9.05982758 10.7817734,8.70747288 10.4980999,8.4662428 L4.8708794,3.34398101 C4.59560114,3.09275968 4.23551262,2.97048276 3.87437905,3.00605402 Z"
									      transform="rotate(90 4 7)"></path>
								</svg>
							</div>
						</a>
						<a
							key={'link-discover'}
							className="nq-c-HomeAction"
							onClick={this.onChangePage.bind(this, 'discover')}
							style={{
								visibility: (loaded ? 'visible' : 'hidden')
							}}
						>
							<div className="nq-c-HomeAction-title">Découvrir</div>
							<div className="nq-c-HomeAction-subtitle">Chambéry, sortir et se divertir</div>
							<div className="nq-c-Nav nq-c-HomeAction-icon" data-type="full">
								<svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14">
									<path fill="#FFF" fillRule="evenodd"
									      d="M3.87437905,3.00605402 C3.59910078,3.03384513 3.33845329,3.15278405 3.12912069,3.34398101 L-2.49809976,8.4662428 C-2.78176527,8.7074615 -2.96076447,9.05982758 -2.99425983,9.4433427 C-3.02775519,9.82685782 -2.91366196,10.2092347 -2.67814402,10.501602 C-2.44158371,10.7950507 -2.10454268,10.972907 -1.74236406,10.99738 C-1.38018543,11.0207233 -1.02430393,10.8873311 -0.756341052,10.6272112 L4.00000005,6.29633888 L8.75634115,10.6272112 C9.02430402,10.8873254 9.38018552,11.0207148 9.74236415,10.99738 C10.1045428,10.9740367 10.4415865,10.7950677 10.6781441,10.5027118 C10.9136594,10.2092631 11.0277553,9.82796765 10.9942599,9.4433427 C10.9607646,9.05982758 10.7817734,8.70747288 10.4980999,8.4662428 L4.8708794,3.34398101 C4.59560114,3.09275968 4.23551262,2.97048276 3.87437905,3.00605402 Z"
									      transform="rotate(90 4 7)"></path>
								</svg>
							</div>
						</a>
					</div>
				</div>
			</div>
		);
	}
}

export default connect(
	state => ({
		background: state.background
	}),
	dispatch => ({
		backgroundMarkerStateActions: bindActionCreators(BackgroundMarkerStateActions, dispatch)
	})
)(Home);
