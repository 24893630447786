import Axio from 'tools/Axio';
import moment from 'moment';

let initialState = {
    isFetching: false,
    isLoaded: false,
    didErrors: false,
    data: [],
    error: null
};

// Actions
const RECEIVE_SEGMENTS = 'CategoriesMarker/Receive';
const RECEIVE_ERROR = 'CategoriesMarker/Error';
const RECEIVE_LOAD = 'CategoriesMarker/Load';

//add cache 30min
export const fetch = () => {
    return (dispatch) => {
        dispatch({
            type: RECEIVE_LOAD
        });

        let cacheDate = localStorage.getItem('categories-marker-date');
        if (cacheDate !== null) {
            let date = moment(cacheDate);
            let now = moment();

            if (date.format('YYYY-MM-DD') == now.format('YYYY-MM-DD')) {
                let datas = JSON.parse(localStorage.getItem('categories-marker'));

                console.log('Marker categories layer');
                dispatch({
                    type: RECEIVE_SEGMENTS,
                    datas: datas
                });
                return;
            }
        }

        //CUURENT SEARCH A ESQUIVE
        Axio(
            'categories/',
            {},
            function (datas) {
                //TODO MANAGE ERRORS
                if (datas) {

                    localStorage.setItem('categories-marker', JSON.stringify(datas));
                    localStorage.setItem('categories-marker-date', moment().format('YYYY-MM-DD HH:mm:ss'));


                    dispatch({
                        type: RECEIVE_SEGMENTS,
                        datas: datas
                    });
                }
            },
            function () {
                dispatch({
                    type: RECEIVE_ERROR,
                    datas: 'Erreur'
                });
            },
            'post'
        );
    }

};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case RECEIVE_LOAD:
            return {
                ...state,
                isFetching: true,
                isLoaded: false,
                didErrors: false,
                error: '',
                data: []
            };
        case RECEIVE_SEGMENTS:
            return {
                ...state,
                isLoaded: true,
                isFetching: false,
                didErrors: false,
                error: '',
                data: action.datas
            };
        case RECEIVE_ERROR:
            return {
                ...state,
                isFetching: false,
                isLoaded: true,
                didErrors: true,
                data: [],
                error: 'Error'
            };
        default:
            return state;
    }
}
