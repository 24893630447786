import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import CONSTANTES from 'configs/defines';
import Translate from 'configs/translates';
import Humanize from 'tools/Humanize';

import * as DirectionStateActions from 'reducers/direction';

import Axio from 'tools/Axio';

import LogoBus from 'assets/img/icons/map/transport/bus-white.png';
import LogoVelo from 'assets/img/icons/map/transport/bicycle-white.png';
import LogoAuto from 'assets/img/icons/map/transport/auto-white.png';
import LogoWalk from 'assets/img/icons/map/transport/foot-white.svg';
import moment from "../Interests";

const IMAGES = require.context('assets/img/lines', true);

const LOGOS = {
    BUS: LogoBus,
    WALK: LogoWalk,
    BIKE: LogoVelo,
    CAR: LogoAuto
};

class DirectionIndications extends Component {

    state = {
        instructions: [],
        times: [],
        visible: true,
        scrollIndicator: false
    };

    defaultMode = '';

    constructor(props) {
        super(props);
        for (var key in CONSTANTES.keys.transportModes) {
            this.state.times[key] = -1;
        }

        document.addEventListener(CONSTANTES.keys.events.actionMap, (event) => {
            this.goActionMap(event.detail);
        }, false);
    }

    componentWillReceiveProps(props) {
        let {defaultMode} = props.direction;
        this.defaultMode = defaultMode;

        if(props.current === ''){
            return;
        }

        let visible = false;

        if (props.current === 'itinerary' && props.current !== '') {
            visible = true;
        }

        this.setState({
            visible
        });
    }

    goActionMap(eventData) {
        let {action, data, current} = eventData;


        if (action === 'open-marker' || action === 'close-direction') {
            this.setState({
                visible: false
            });
        }

        if (action === 'clean-direction') {
            this.setState({
                instructions: []
            });
            return false;
        }

        if (action === 'direction') {
            let indications = [];
            let times = [];

            for (var key in CONSTANTES.keys.transportModes) {
                if (key === 'DEFAULT') {
                    continue
                }
                if (typeof (data[key]) !== 'undefined' && typeof (data[key].data.journeys) !== 'undefined') {
                    let journey = data[key].data.journeys[0];
                    if (typeof (journey) !== 'undefined' && typeof (journey.totaltime) !== 'undefined') {
                        times[key] = journey.totaltime;
                        continue;
                    }
                }
                times[key] = -1;
            }

            if (
                typeof (data[this.defaultMode]) !== 'undefined' &&
                typeof (data[this.defaultMode].data.journeys) !== 'undefined' &&
                typeof (data[this.defaultMode].data.journeys[0]) !== 'undefined'
            ) {
                let journey = data[this.defaultMode].data.journeys[0];

                for (let y = 0, ly = journey.paths.length; y < ly; y++) {

                    let path = journey.paths[y];


                    if (
                        path.mode === CONSTANTES.keys.transportModes.WALK ||
                        path.mode === CONSTANTES.keys.transportModes.CAR ||
                        path.mode === CONSTANTES.keys.transportModes.BIKE
                    ) {

                        for (let z = 0, lz = path.indications.length; z < lz; z++) {
                            let indication = path.indications[z];
                            indications.push({
                                message: indication.instruction,
                                messageText: indication.instruction,
                                distance: indication.distance,
                                imageReplace: false,
                                duration: indication.duration,
                                image: CONSTANTES.images.icons.number + (indications.length + 1),
                                latlng: {
                                    lat: indication.lat_start,
                                    lng: indication.lon_start
                                }
                            });
                        }
                    }

                    if (path.mode === CONSTANTES.keys.transportModes.BUS) {
                        let lineId = path.vehiclejourney.lineid;
                        let directionName = path.vehiclejourney.destinationdisplay;

                        lineId = lineId.rtrim('R');
                        lineId = lineId.ltrim('0');

                        let image = false;
                        try {
                            let link = './' + lineId + '.png';
                            if (CONSTANTES.DEBUG) {
                                //console.log(link);
                            }
                            image = IMAGES(link);
                        } catch (e) {
                            if (CONSTANTES.DEBUG) {
                                console.log(e);
                            }
                            image = false;
                        }

                        let message = (
                            <div>
                                {Translate('TAKE_LINE')}
                                {image && (<img src={image} className="nq-c-ItineraryStep-content-image-line"/>)}
                                {!image && (<span>{lineId}</span>)}
                                <br/>
                                {Translate('DIRECTION')} {directionName}
                                <br/>
                                {Translate('TAKE_BUS')} {path.start.name}
                            </div>
                        );

                        let messageText = Translate('TAKE_LINE') +
                            ' ' +
                            ((image ? 'IMAGE_REPLACE' : lineId)) +
                            ' ' +
                            Translate('DIRECTION') + directionName +
                            ' ' +
                            Translate('TAKE_BUS') + path.start.name


                        indications.push({
                            message: message,
                            messageText: messageText,
                            imageReplace: image,
                            distance: path.distance,
                            duration: path.duration,
                            image: CONSTANTES.images.icons.number + (indications.length + 1),
                            latlng: {
                                lat: path.start.lat,
                                lng: path.start.lon
                            }
                        });

                        indications.push({
                            message: Translate('EXIT_BUS') + path.end.name,
                            messageText: Translate('EXIT_BUS') + path.end.name,
                            distance: false,
                            imageReplace: false,
                            duration: false,
                            image: CONSTANTES.images.icons.number + (indications.length + 1),
                            latlng: {
                                lat: path.end.lat,
                                lng: path.end.lon
                            }
                        });
                    }

                }


                indications.push({
                    image: CONSTANTES.images.icons.endDirection,
                    message: 'Vous êtes arrivé à destination',
                    messageText: 'Vous êtes arrivé à destination',
                    imageReplace: false,
                    distance: 0,
                    latlng: {
                        lat: journey.paths[journey.paths.length - 1].end.lat,
                        lng: journey.paths[journey.paths.length - 1].end.lon
                    },
                    className: 'last'
                });

            }

            this.setState({
                instructions: indications,
                times: times,
                visible: current === 'itinary' ? true : this.state.visible
            })

        }
    }

    onChangeMode(mode) {
        let elm = document.querySelector('.nq-c-Itinerary-body');
        if (elm !== null) {
            elm.scrollTop = 0;
        }
        this.props.directionStateActions.changeMode(mode);
    }

    onMoveToMarker(latlng) {
        document.dispatchEvent(new CustomEvent(CONSTANTES.keys.events.actionMap, {
            detail: {
                action: 'move-and-zoom',
                data: {
                    latlng: latlng,
                    zoom: 19
                }
            }
        }));
    }

    buildIndication(instruction, index) {
        let items = [];

        items.push(
            <li
                className={'nq-c-ItineraryStep ' + (typeof (instruction.className) !== 'undefined' ? instruction.className : '')}
                key={'instruction' + index}
                onClick={this.onMoveToMarker.bind(this, instruction.latlng)}
            >
                <div className={'nq-c-ItineraryStep-number'}><img src={instruction.image}/></div>
                <div className={'nq-c-ItineraryStep-content'}>{instruction.message}</div>
                <div className={'nq-c-ItineraryStep-distance'}>{Humanize.displayDistance(instruction.distance)} - {instruction.duration ? Humanize.displayDuration(instruction.duration) : ''}</div>
            </li>
        );

        if (false && index === 0) {
            items.push(
                <li
                    key={'instruction-add-' + index}
                    className="nq-c-ItineraryStep" data-type="direction">
                    <div className="nq-c-ItineraryStep-content">
                        <div className="nq-c-Button nq-c-ItineraryStep-direction" data-size="xs" data-border="true">Dans
                            quelle direction partir ?
                        </div>
                    </div>
                </li>
            )
        }
        return items;
    }

    buildModes(times) {
        let modes = [];
        for (let key in CONSTANTES.keys.transportModes) {
            if (
                key === 'DEFAULT' ||
                typeof (times[key]) === 'undefined' ||
                (typeof (times[key]) !== 'undefined' && times[key] === -1)
            ) {
                continue;
            }

            modes.push(
                <label
                    htmlFor={'itinerary_' + key.toLocaleLowerCase()}
                    className={'nq-c-Itinerary-filter'}
                    data-type={key.toLocaleLowerCase()}
                    key={'mode' + key.toLocaleLowerCase()}
                    onClick={this.onChangeMode.bind(this, key)}
                >
                    <input
                        type="radio"
                        name="itinerary"
                        id={'itinerary_' + key.toLocaleLowerCase()}
                        defaultChecked={this.defaultMode === key}
                    />
                    <span className="nq-c-Button">
                        <img src={LOGOS[key]} alt=""/>
                        <span>{Humanize.displayDuration(times[key])}</span>
                    </span>
                </label>
            );
        }

        return modes;
    }

    toggleVisibility() {
        this.setState({
            visible: !this.state.visible
        });
    }

    addEventScroll() {
        if (typeof (this.body) === 'undefined') {
            return;
        }

        this.body.removeEventListener('scroll', () => this.handleScroll());
        this.body.addEventListener('scroll', () => this.handleScroll());
        this.handleScroll();
    }

    handleScroll(event) {
        if (typeof (this.list) === 'undefined') {
            return;
        }

        if (this.list.offsetHeight > 360) {
            if (this.body.scrollTop === 0 && this.state.scrollIndicator !== 'bottom') {
                this.setState({
                    scrollIndicator: 'bottom'
                });
            } else if (this.body.scrollTop > 0 && this.body.scrollTop + 360 < this.list.offsetHeight && this.state.scrollIndicator !== 'both') {
                this.setState({
                    scrollIndicator: 'both'
                });
            } else if (this.body.scrollTop > 0 && this.body.scrollTop + 360 > this.list.offsetHeight && this.state.scrollIndicator !== 'top') {
                this.setState({
                    scrollIndicator: 'top'
                });
            }
        }
    }

    onMoveList(way) {
        let valueStepper = 50;
        let stepper = valueStepper;
        if (way == 'top') {
            stepper = -valueStepper;
        }
        document.querySelector('.nq-c-Itinerary-body').scrollTop += stepper;
    }


    openPopupSender(){
        document.dispatchEvent(new CustomEvent(CONSTANTES.keys.events.openPopupMail, {
            detail: {
                callback: this.sendWithEmail.bind(this)
            }
        }));
    }


    sendWithEmail(mail) {
        let {instructions, times} = this.state;

        let routes = [];
        instructions.map((instruction, index) => {
            routes.push({
                message: instruction.messageText,
                imageReplace: instruction.imageReplace,
                distance: instruction.distance,
                duration: instruction.duration,
                lat:  instruction.latlng.lat,
                lng:  instruction.latlng.lng,
                step: index + 1
            });
        });

        Axio(
            'mail-itinerary/',
            {
                mail: mail,
                routes: routes,
                distance: 0,
                time: times[this.defaultMode],
                type: this.defaultMode

            },
            function (datas) {

            },
            function () {

            },
            'post'
        );
    }

    render() {
        let {instructions, times, visible} = this.state;
        let {direction} = this.props;

        if (instructions.length === 0) {
            return (null);
        }

        if (typeof (direction.data.addresses) !== 'undefined') {
            if (direction.data.addresses.start === 'ADDRESS|' + CONSTANTES.markers.here.lat + ',' + CONSTANTES.markers.here.lng) {
                instructions[0].image = CONSTANTES.images.icons.here + '&color=' + CONSTANTES.colors.red.replace('#', '');
            }

            if (direction.data.addresses.stop === 'ADDRESS|' + CONSTANTES.markers.here.lat + ',' + CONSTANTES.markers.here.lng) {
                instructions[instructions.length - 1].image = CONSTANTES.images.icons.here + '&color=' + CONSTANTES.colors.red.replace('#', '');
            }
        }
        let modes = this.buildModes(times);


        return (
            <div className="nq-c-Itinerary" data-active="true" data-visible={visible}>
                <div className="nq-c-Itinerary-reduce" onClick={() => this.toggleVisibility()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14">
                        <path fill="#FFF" fillRule="evenodd"
                              d="M3.87437905,3.00605402 C3.59910078,3.03384513 3.33845329,3.15278405 3.12912069,3.34398101 L-2.49809976,8.4662428 C-2.78176527,8.7074615 -2.96076447,9.05982758 -2.99425983,9.4433427 C-3.02775519,9.82685782 -2.91366196,10.2092347 -2.67814402,10.501602 C-2.44158371,10.7950507 -2.10454268,10.972907 -1.74236406,10.99738 C-1.38018543,11.0207233 -1.02430393,10.8873311 -0.756341052,10.6272112 L4.00000005,6.29633888 L8.75634115,10.6272112 C9.02430402,10.8873254 9.38018552,11.0207148 9.74236415,10.99738 C10.1045428,10.9740367 10.4415865,10.7950677 10.6781441,10.5027118 C10.9136594,10.2092631 11.0277553,9.82796765 10.9942599,9.4433427 C10.9607646,9.05982758 10.7817734,8.70747288 10.4980999,8.4662428 L4.8708794,3.34398101 C4.59560114,3.09275968 4.23551262,2.97048276 3.87437905,3.00605402 Z"
                              transform="rotate(90 4 7)"/>
                    </svg>
                </div>
                <form action="" className="nq-c-Itinerary-header">
                    {modes}
                </form>

                <div className="nq-c-Itinerary-wrapper" data-scroll={this.state.scrollIndicator}>
                    <div className="nq-c-Itinerary-wrapper-scroll" onClick={this.onMoveList.bind(this, 'top')}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8">
                            <path fill="#FFF" fillRule="evenodd"
                                  d="M6.87437905,0.00605401757 C6.59910078,0.0338451335 6.33845329,0.152784052 6.12912069,0.343981011 L0.501900236,5.4662428 C0.218234733,5.7074615 0.0392355296,6.05982758 0.00574016979,6.4433427 C-0.0277551901,6.82685782 0.0863380445,7.20923467 0.321855978,7.50160199 C0.558416287,7.79505067 0.895457316,7.97290698 1.25763594,7.99738001 C1.61981457,8.0207233 1.97569607,7.88733106 2.24365895,7.62721123 L7.00000005,3.29633888 L11.7563411,7.62721123 C12.024304,7.88732537 12.3801855,8.02071476 12.7423642,7.99738001 C13.1045428,7.97403673 13.4415865,7.79506775 13.6781441,7.50271181 C13.9136594,7.20926312 14.0277553,6.82796765 13.9942599,6.4433427 C13.9607646,6.05982758 13.7817734,5.70747288 13.4980999,5.4662428 L7.8708794,0.343981011 C7.59560114,0.0927596812 7.23551262,-0.0295172449 6.87437905,0.00605401757 Z"
                                  transform="matrix(-1 0 0 1 14 0)"/>
                        </svg>
                    </div>
                    <div className="nq-c-Itinerary-body" ref={(el) => {
                        if (el !== null) {
                            this.body = el;
                            this.addEventScroll();
                        }
                    }}>
                        <ul className="nq-c-Itinerary-list" ref={(el) => {
                            if (el !== null) {
                                this.list = el;
                            }
                        }}>
                            {instructions.map((instruction, index) => this.buildIndication(instruction, index))}
                        </ul>
                    </div>
                    <div className="nq-c-Itinerary-wrapper-scroll" onClick={this.onMoveList.bind(this, 'down')}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8">
                            <path fill="#FFF" fillRule="evenodd"
                                  d="M6.87437905,0.00605401757 C6.59910078,0.0338451335 6.33845329,0.152784052 6.12912069,0.343981011 L0.501900236,5.4662428 C0.218234733,5.7074615 0.0392355296,6.05982758 0.00574016979,6.4433427 C-0.0277551901,6.82685782 0.0863380445,7.20923467 0.321855978,7.50160199 C0.558416287,7.79505067 0.895457316,7.97290698 1.25763594,7.99738001 C1.61981457,8.0207233 1.97569607,7.88733106 2.24365895,7.62721123 L7.00000005,3.29633888 L11.7563411,7.62721123 C12.024304,7.88732537 12.3801855,8.02071476 12.7423642,7.99738001 C13.1045428,7.97403673 13.4415865,7.79506775 13.6781441,7.50271181 C13.9136594,7.20926312 14.0277553,6.82796765 13.9942599,6.4433427 C13.9607646,6.05982758 13.7817734,5.70747288 13.4980999,5.4662428 L7.8708794,0.343981011 C7.59560114,0.0927596812 7.23551262,-0.0295172449 6.87437905,0.00605401757 Z"
                                  transform="rotate(-180 7 4)"/>
                        </svg>
                    </div>
                </div>
                <div className="nq-c-Itinerary-actions">
                    <div
                        className="nq-c-Button nq-c-Itinerary-send"
                        data-width="full"
                        onClick={this.openPopupSender.bind(this)}
                    >
                        Recevoir l'itinéraire par email
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        direction: state.direction
    }),
    dispatch => ({
        directionStateActions: bindActionCreators(DirectionStateActions, dispatch)
    })
)(DirectionIndications);