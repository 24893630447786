import Axio from 'tools/Axio';

import CONSTANTES from 'configs/defines';

let initialState = {
    isFetching: false,
    isLoaded: false,
    didErrors: false,
    data: [],
    error: null
};

// Actions
const RECEIVE_SEGMENTS = 'Siri/Receive';
const RECEIVE_ERROR = 'Siri/Error';
const RECEIVE_LOAD = 'Siri/Load';
const RECEIVE_RESET = 'Siri/Reset';

//add cache 30min
export const fetch = (id) => {


    return (dispatch) => {
        dispatch({
            type: RECEIVE_LOAD
        });

        Axio(
            'siri/',
            {
                id
            },
            function (datas) {
                //TODO MANAGE ERRORS
                if (datas) {
                    dispatch({
                        type: RECEIVE_SEGMENTS,
                        datas: datas
                    });
                }
            },
            function () {
                dispatch({
                    type: RECEIVE_ERROR,
                    datas: 'Erreur'
                });
            },
            'post'
        );
    }

};

export const reset = () => {
    return (dispatch) => {
        dispatch({
            type: RECEIVE_RESET
        });
    };
};

export default function (state = initialState, action = {}) {

    switch (action.type) {
        case RECEIVE_RESET:
            return {
                ...state,
                isFetching: false,
                isLoaded: false,
                didErrors: false,
                data: [],
                error: null
            };
        case RECEIVE_LOAD:
            return {
                ...state,
                isFetching: true,
                isLoaded: false,
                didErrors: false,
                error: '',
                data: []
            };
        case RECEIVE_SEGMENTS:
            return {
                ...state,
                isLoaded: true,
                isFetching: false,
                didErrors: false,
                error: '',
                data: action.datas
            };
        case RECEIVE_ERROR:
            return {
                ...state,
                isFetching: false,
                isLoaded: true,
                didErrors: true,
                data: [],
                error: 'Erreur'
            };
        default:
            return state;
    }
}
