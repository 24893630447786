let favorites = [];

let initialState = {
    data: []
};

// Actions
const ADD_FAVORITE = 'Favorites/add';

const FAVORITE_STORAGE_NAME = 'NQ/favorites';

export const init = () => {
    return (dispatch) => {
        /*let items = localStorage.getItem(FAVORITE_STORAGE_NAME);
        if (items !== null) {
            favorites = JSON.parse(items);
        }*/

        dispatch({
            type: ADD_FAVORITE,
            favorites: []
        });
    }
};

export const toogle = (favorite) => {
    return (dispatch) => {

        let indexFav = -1;
        favorites.map((fav, index) => {
            if (
                typeof (fav.ID) !== 'undefined' &&
                typeof (favorite.ID) !== 'undefined'
            ) {
                if (fav.ID === favorite.ID) {
                    indexFav = index;
                }
            }
        });

        if (indexFav !== -1) {
            favorites.splice(indexFav, 1);
        } else {
            favorites.push(favorite);
        }


        //localStorage.setItem(FAVORITE_STORAGE_NAME, JSON.stringify(favorites));


        dispatch({
            type: ADD_FAVORITE,
            favorites
        });
    }
};

export const removeAll = () => {
    return (dispatch) => {
        favorites = [];
        localStorage.setItem(FAVORITE_STORAGE_NAME, JSON.stringify(favorites));
        dispatch({
            type: ADD_FAVORITE,
            favorites
        });
    }
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case ADD_FAVORITE:
            return {
                ...state,
                data: action.favorites
            };
        default:
            return state;
    }
}
