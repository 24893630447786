import * as Dom from 'tools/Dom';

export const isPMR = () => {
    return document.querySelector('body').hasAttribute('data-pmr');
};

export const isPMRWithFilter = () => {
    return document.querySelector('body').hasAttribute('data-pmr') &&
        document.querySelector('.nq-c-SearchHome').getAttribute('data-position') === 'left';
}

export const getCurrentTabFilter = (position) => {
    if(position === 'center'){
        return document.querySelector('.nq-c-SearchHome').getAttribute('data-current');
    }
    let elm = document.querySelector('.nq-c-MapNav');
    if(elm === null){
        return '';
    }
    return  elm.getAttribute('data-current');
}

export const closeFilterIfPMR = () => {
    if(isPMR()){
        let position = document.querySelector('.nq-c-SearchHome').getAttribute('data-position');

        let current = getCurrentTabFilter(position);
        console.log(current);
        if(current === ''){
            return;
        }
        if(position === 'center'){
            window.setTimeout(() => {
                console.log('.nq-c-MapNav-item[data-type="' + current + '"]');
                Dom.simulateClick(document.querySelector('.nq-c-MapNav-item[data-type="' + current + '"]'));
            }, 100);
            return;
        }
        Dom.simulateClick(document.querySelector('.nq-c-MapNav-item[data-type="' + current + '"]'));
    }
}
