import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import * as Dom from 'tools/Dom';
import * as Map from 'tools/Gmap';

import MyPosition from 'components/buttons/MyPosition';

import CONSTANTES from 'configs/defines';

import * as SearchStateActions from 'reducers/search';

class SearchInput extends Component {

    timerBlur = null;
    noCloseKeyboard = false;

    static defautlProps = {
        onChange: (type, value) => {
            console.log('[onChange] - default value...');
        },
        onBlur: (type, value) => {
            console.log('[onBlur] - default value...');
        },
        type: '',
        icon: (null),
        placeholder: '',
        value: ''
    }

    constructor(props) {
        super(props);
        this.state = {
            address: props.value,
            value: '',
            addresses: [],
            focused: false
        };


        document.addEventListener(CONSTANTES.keys.events.goTo, this.onGoTo.bind(this));

        document.addEventListener(CONSTANTES.keys.events.onKeyPress, this.onKeyPress.bind(this));

        document.addEventListener(CONSTANTES.keys.events.forceCloseKeyboard, this.onForceCloseKeyboard.bind(this), false);

        document.addEventListener(CONSTANTES.keys.events.reset, () => {
            this.setState({
                addresses: [],
                focused: false
            });
        });

        let html = document.querySelector('html');
        html.addEventListener('click', (event) => {
            if (Dom.getClosest(event.target, '.keyboard-wrapper') !== null) {
                this.noCloseKeyboard = true;
                window.setTimeout(() => {
                    this.noCloseKeyboard = false;
                }, 50);
            }
        });
    }

    onGoTo(e) {
        let {onChange, type} = this.props;

        let data = e.detail;

        if (data.type === type) {
            let address = {
                label: data.name,
                id: data.id
            };

            this.setState({
                address: address,
                addresses: []
            });

            onChange(type, address);
        }

        if (data.type === 'stop' && type == 'start' && this.state.address === '') {
            this.setState({
                address: 'current',
                addresses: []
            });

            onChange(type, 'current');
        }
    }

    onKeyPress(event) {
        if (this.state.focused === false) {
            return;
        }
        this.noCloseKeyboard = true;
        this.onChangeInput(event.detail);
    }

    onChangeInput(event) {
        let {onChange, type} = this.props;
        let value = event;

        if (typeof (event) !== 'string') {
            let {target} = event;
            value = target.value;
        }

        let newSate = {
            address: value
        };

        if (value.trim() === '') {
            newSate['addresses'] = [];
        }
        this.setState(newSate);

        onChange(type, value);
    }

    componentWillReceiveProps(nextProps) {
        if (typeof (nextProps.value) && nextProps.value !== this.state.address) {
            this.setState({
                address: nextProps.value
            })
        }

        if (nextProps.search.isLoaded && nextProps.search.currentType === nextProps.type) {
            let addresses = [];
            if (typeof (nextProps.search.data.places) !== 'undefined' && nextProps.search.data.places.length > 0) {
                nextProps.search.data.places.map((place) => {
                    let lat = false;
                    let lng = false;
                    let id = place.id;

                    if (typeof (place.stopArea) !== 'undefined') {
                        lat = place.stopArea.lat;
                        lng = place.stopArea.lon;
                    } else if (typeof (place.address) !== 'undefined') {
                        lat = place.address.lat;
                        lng = place.address.lon;
                    } else if (typeof (place.lng) !== 'undefined') {
                        lat = place.lat;
                        lng = place.lng;
                        place.id = 'ADDRESS|' + place.lat + ',' + place.lng;

                        let markerSearch = Map.findMarker('ID' + id);
                        if (markerSearch === false) {
                            return;
                        }
                    }

                    addresses.push({
                        type: place.type,
                        label: place.label,
                        lat,
                        lng,
                        id: place.id,
                        idBrute: id
                    });
                });
            }

            this.setState({
                addresses
            });
        }
    }

    onClickAddress(address) {
        let {onChange, type} = this.props;

        this.setState({
            address: address,
            //addresses: []
        });

        onChange(type, address);
    }

    onForceCloseKeyboard() {
        this.noCloseKeyboard = false;
        this.onBlurInput();
    }

    onBlurInput() {
        if (this.noCloseKeyboard) {
            this.noCloseKeyboard = false;
            this.input.focus();
            return;
        }

        this.timerBlur = setTimeout(() => {
            if (this.noCloseKeyboard) {
                this.noCloseKeyboard = false;
                this.input.focus();
                return;
            }

            this.setState({
                focused: false
            });

            document.dispatchEvent(new CustomEvent(CONSTANTES.keys.events.closeKeyboard, {
                detail: {}
            }));

        }, 20);
    }

    onFocusInput() {
        let {address} = this.state;

        setTimeout(() => {
            this.input.setSelectionRange(this.input.value.length, this.input.value.length);
        });

        this.setState({
            focused: true
        });

        this.timerBlur = setTimeout(() => {
            document.dispatchEvent(new CustomEvent(CONSTANTES.keys.events.openKeyboard, {
                detail: (address === 'current' ? CONSTANTES.markers.here.name : address)
            }));
        }, 30);
    }

    onReset() {
        clearInterval(this.timerBlur);
        let {onChange, type} = this.props;

        this.setState({
            address: '',
            addresses: []
        });

        onChange(type, '');
        this.input.focus();
        document.dispatchEvent(new CustomEvent(CONSTANTES.keys.events.openKeyboard, {
            detail: ''
        }));
    }

    onAddCurrentPosition() {
        //clearInterval(this.timerBlur);
        let {onChange, type} = this.props;
        let value = 'current';

        this.setState({
            address: value,
            focus: false
        });

        onChange(type, value);
        //this.input.focus();
    }

    render() {

        let {address, focused, addresses} = this.state;
        let {placeholder, className, classNameWrapper} = this.props;

        if (typeof (address) !== 'string') {
            address = address.label;
        }

        let autoComplete = [];

        if (addresses.length > 0 && focused) {

            autoComplete.push(<div key={'title-lieux'} className="nq-c-Autocomplete-label">Lieu(x)</div>);

            let hasPlace = false;
            autoComplete.push(
                <ul key={'liste-place'}>
                    {addresses.map((address, index) => {
                        if (address.type === 'PLACE') {
                            hasPlace = true;
                            return (
                                <li
                                    key={'place' + index}
                                    onClick={this.onClickAddress.bind(this, address)}
                                >
                                    {address.label}
                                </li>
                            );
                        }

                    })}
                </ul>
            );

            if (!hasPlace) {
                autoComplete.push(<div key={'title-indication-place'} className="nq-c-Autocomplete-message">Aucuns lieux
                    trouvés</div>);
            }

            autoComplete.push(<div key={'title-address'} className="nq-c-Autocomplete-label">Adresse(s)</div>);

            let hasAddress = false;
            autoComplete.push(
                <ul key={'liste-address'}>
                    {addresses.map((address, index) => {
                        if (address.type === 'ADDRESS') {
                            hasAddress = true;
                            return (
                                <li
                                    key={'address' + index}
                                    onClick={this.onClickAddress.bind(this, address)}
                                >
                                    {address.label}
                                </li>
                            );
                        }

                    })}
                </ul>
            );

            if (!hasAddress) {
                autoComplete.push(<div key={'title-indication-address'} className="nq-c-Autocomplete-message">Pas d'adresse
                    ou demande pas assez précise</div>);
            }

            autoComplete.push(<div key={'title-stop'} className="nq-c-Autocomplete-label">Arrêt(s) de bus</div>);


            let hasStop = false;
            autoComplete.push(
                <ul key={'liste-stop'}>
                    {addresses.map((address, index) => {
                        if (address.type === 'STOPAREA') {
                            hasStop = true;
                            return (
                                <li
                                    key={'stop' + index}
                                    onClick={this.onClickAddress.bind(this, address)}
                                >
                                    {address.label}
                                </li>
                            );
                        }

                    })}
                </ul>
            );

            if (!hasStop) {
                autoComplete.push(<div key={'title-indication-stop'} className="nq-c-Autocomplete-message">Pas d'arrêt
                    de bus ou demande pas assez précise</div>);
            }



        }

        return (
            <div className={classNameWrapper}>

                <input
                    type="text"
                    data-size="xl"
                    data-active={focused}
                    className={className}
                    placeholder={placeholder}
                    onChange={this.onChangeInput.bind(this)}
                    onBlur={this.onBlurInput.bind(this)}
                    onFocus={this.onFocusInput.bind(this)}
                    value={address === 'current' ? CONSTANTES.markers.here.name : address}
                    ref={(el) => {
                        this.input = el;
                    }}
                />

                <div
                    className={'nq-c-Search-actions'}
                    data-active={focused}>

                    <div className={'nq-c-Search-actions-buttons'}>
                        {address !== 'current' && <MyPosition
                            absolute={false}
                            onClick={this.onAddCurrentPosition.bind(this)}
                        />}

                        <div className="nq-c-Button nq-c-SearchItinerary-reset" data-border={true}
                             onClick={this.onReset.bind(this)}>
                            Effacer
                        </div>
                    </div>

                    {autoComplete.length > 0 ? (
                        <div className={'nq-c-Autocomplete'}>
                            <div className={'nq-c-Autocomplete-label-big'}>Choisir :</div>
                            <div className={'nq-c-Autocomplete-result'}>
                                {autoComplete}
                            </div>
                        </div>
                    ) : ''}


                </div>
            </div>
        );
    }
}


export default connect(
    state => ({
        search: state.search,
        main: state.main
    }),
    dispatch => ({
        searchStateActions: bindActionCreators(SearchStateActions, dispatch)
    })
)(SearchInput);


/*


                    {focused && (
                        <div className={'keyboard-wrapper'}>
                            <Keyboard
                                ref={(el) => {
                                    if (el === null) {
                                        return
                                    }
                                    ;el.keyboard.setInput(address === 'current' ? CONSTANTES.markers.here.name : address);
                                }}
                                onKeyPress={this.onKeyPress.bind(this)}
                                onChange={this.onChangeInput.bind(this)}
                                theme={"hg-theme-default hg-layout-default myTheme"}
                                layoutName={"default"}
                                layout={{
                                    default: [
                                        "1 2 3 4 5 6 7 8 9 0 {bksp}",
                                        "a z e r t y u i o p",
                                        "q s d f g h j k l m",
                                        "w x c v b n ' {enter}",
                                        ".fr .com {space} - @"
                                    ]
                                }}
                                display={{
                                    "{enter}": 'OK',
                                    "{bksp}": '<',
                                    '{space}': ' '
                                }}
                            />
                        </div>
                    )}
 */
