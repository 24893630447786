let functions = [];
let intervalTime = 1000;

setInterval(() => {
    functions.forEach((func) => {

        if (func.count % func.timing === 0) {
            func.function();
        }

        func.count += 1;
        if (func.count >= 100) {
            func.count = 0;
        }
    });



}, intervalTime);


export let addFunction = function (func) {
    if (typeof func.timing === 'undefined') {
        func.timing = 1;
    }

    if (typeof func.function === 'undefined') {
        console.log('Error : function is required');
        return;
    }

    func.count = 0;
    functions.push(func);
}