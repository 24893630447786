export default {
    DEBUG: false,
    id: 'totem-gare',
    urls: {
        api: 'https://totem.synchro-bus.fr/api-keolis/',
        markerClusterer: 'https://totem.synchro-bus.fr/wp-content/themes/nq-keolis/assets/js/markerClusterer.js',
        spiderClusterer: 'https://totem.synchro-bus.fr/wp-content/themes/nq-keolis/assets/js/overlappingMarkerSpiderfier.js'
    },
    keys: {
        googlemap: 'AIzaSyA-0esHh32X82-135ayYHN2QGErVC_E2Co',
        stepStateName: 'step',
        transportModes: {
            BUS: 'BUS',
            WALK: 'WALK',
            CAR: 'CAR',
            BIKE: 'BIKE',
            DEFAULT: 'BUS'
        },
        events: {
            closeInfoBulle: 'NQ/closeInfoBulle',
            goTo: 'NQ/goTo',
            reset: 'NQ/reset',
            actionMap: 'NQ/actionMap',
            loadedApp: 'NQ/loadedApp',
            openKeyboard: 'NQ/keyboard/Open',
            closeKeyboard: 'NQ/keyboard/Close',
            onKeyPress: 'NQ/keyboard/key/Press',
            forceCloseKeyboard: 'NQ/keyboard/key/Press/Force',
            openPopupMail: 'NQ/popup/mail/open',
            closePopupMail: 'NQ/popup/mail/close',
        }
    },
    limits: {
        step: 5
    },
    images: {
        icons: {
            number: 'https://totem.synchro-bus.fr/api-keolis/icon/?name=number&number=',
            endDirection: 'https://totem.synchro-bus.fr/api-keolis/icon/?name=endDirection',
            here: 'https://totem.synchro-bus.fr/api-keolis/icon/?name=here',
        }
    },
    markers: {
        here: {
            url: 'you_are_here.png',
            lat: 45.570071,
            lng: 5.920051,
            name: 'Gare de Chambéry - Challes-les-Eaux, Place de la Gare, Chambéry'
        }
    },
    colors: {
        red: '#FC2D43',
        bus: '#54BBAB',
        bike: '#00AFEA',
        walk: '#8B572A',
        car: '#EF7911'
    },
    titles: {
        home: 'Place de la gare'
    }
}
