/*global google*/
import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import * as Map from 'tools/Gmap';
import * as Keolis from 'tools/Keolis';

import * as DirectionStateActions from 'reducers/direction';

import CONSTANTES from 'configs/defines';
import MARKERS_DEF from 'configs/markers';

const idWrapperGoogleMap = 'wrapper-googlemap';
const DEFAULT_ZOOM = 19;

class Gmap extends Component {
    map = null;
    loaded = [];
    hereMarker = null;
    defaultMode = '';
    searchPlace = false;

    constructor(props) {
        super(props);

        Map.load(this.init.bind(this, 'map'), CONSTANTES.keys.googlemap);
        Map.loadScript(
            this.init.bind(this, 'markerClusterer'),
            CONSTANTES.urls.markerClusterer
        );
        Map.loadScript(
            this.init.bind(this, 'spiderClusterer'),
            CONSTANTES.urls.spiderClusterer
        );

        document.addEventListener(CONSTANTES.keys.events.actionMap, (event) => {
            this.goActionMap(event.detail);
        }, false);

        document.addEventListener(CONSTANTES.keys.events.reset, () => {
            this.goActionMap({
                action: 'move',
                data: {
                    lat: CONSTANTES.markers.here.lat,
                    lng: CONSTANTES.markers.here.lng
                }
            });
            this.map.setZoom(DEFAULT_ZOOM);
            this.hereMarker.setVisible(false);
        }, false);
    }

    onClicMarker(marker) {
        /*
        if (false && typeof (marker.nqData.stops) !== 'undefined') {
            marker.nqData.stopOptions.zIndex = 1;
            marker.nqData.stops.map((latlng) => {
                Map.addMarker(
                    latlng,
                    '',
                    marker.nqData.stopOptions
                );
            });
        }*/

        Keolis.closeFilterIfPMR();
        document.dispatchEvent(new CustomEvent(CONSTANTES.keys.events.actionMap, {
            detail: {
                action: 'open-marker',
                data: marker
            }
        }));

        this.map.setCenter(Map.setCenterWithOffset(marker.getPosition(), 200));
    }

    componentWillReceiveProps(props) {
        let {defaultMode} = props.direction;
        this.defaultMode = defaultMode;

        if (props.currentPage === 'find') {
            google.maps.event.trigger(this.map, 'resize');
        }
    }

    //TODO LAOD ALL LAYER INIT
    goActionMap(eventData) {
        if (this.map === null) {
            return;
        }

        let {action, data} = eventData;

        if (action === 'markers-init') {
            Map.removeMarkers();

            let allMarkers = [];
            let markersByStop = {};
            for (let category in data.markers) {
                for (let subcategory in data.markers[category]) {
                    let markers = [];
                    let markersDataByType = data.markers[category][subcategory];
                    for (var key in markersDataByType) {
                        let markersData = markersDataByType[key];

                        markersData.map((markerData) => {
                            if (markerData.img === false) {
                                if (CONSTANTES.debug) {
                                    console.log('MARKER WITHOUT IMAGE', markerData.img);
                                }
                                return;
                            }


                            if (
                                typeof (MARKERS_DEF[markerData.img]) === 'undefined' &&
                                markerData.img.indexOf('http') === -1
                            ) {
                                if (CONSTANTES.debug) {
                                    console.log('MARKER WITHOUT IMAGE - config JS', markerData.img);
                                }
                                return;
                            }

                            let infoMarkerIcon = false;
                            let width = 0;
                            let height = 0;
                            let scaledSizeWidth = 0;
                            let scaledSizeHeight = 0;
                            let originX = 0;
                            let originY = 0;
                            let anchorX = 0;
                            let anchorY = 0;

                            if (markerData.img.indexOf('http') !== -1) {
                                infoMarkerIcon = markerData.img;
                                width = markerData.width / 2;
                                height = markerData.height / 2;
                                scaledSizeWidth = markerData.width / 2;
                                scaledSizeHeight = markerData.height / 2;
                                anchorX = 128 / 2 / 2;
                                anchorY = height / 2;
                            } else {
                                let icon = MARKERS_DEF[markerData.img].icon;
                                infoMarkerIcon = icon.url;
                                width = icon.size[0];
                                height = icon.size[1];
                                scaledSizeWidth = icon.scaledSize[0];
                                scaledSizeHeight = icon.scaledSize[1];
                                anchorX = width / 2;
                                anchorY = height / 2;
                            }

                            if (markerData.img.indexOf('http') !== -1 && markerData.img.indexOf('icon/?name=bus-stop&') !== -1) {
                                let idSiri = markerData.data.id_siri;
                                idSiri = idSiri.substring(0, idSiri.length - 1);
                                if (typeof (markersByStop[idSiri]) === 'undefined') {
                                    markersByStop[idSiri] = [];
                                }

                                markersByStop[idSiri].push({
                                    latlng: {
                                        lat: parseFloat(markerData.lat, 10),
                                        lng: parseFloat(markerData.lng, 10)
                                    },
                                    options: {
                                        icon: {
                                            url: infoMarkerIcon,
                                            size: new google.maps.Size(width, height),
                                            scaledSize: new google.maps.Size(scaledSizeWidth, scaledSizeHeight),
                                            origin: new google.maps.Point(originX, originY),
                                            anchor: new google.maps.Point(anchorX, anchorY)
                                        },
                                        type: idSiri,
                                        zIndex: 9,
                                        click: this.onClicMarker.bind(this),
                                        data: markerData,
                                        id: markerData.id
                                    }
                                });
                            } else {

                                let marker = Map.addMarker(
                                    {
                                        lat: parseFloat(markerData.lat, 10),
                                        lng: parseFloat(markerData.lng, 10)
                                    },
                                    '',
                                    {
                                        icon: {
                                            url: infoMarkerIcon,
                                            size: new google.maps.Size(width, height),
                                            scaledSize: new google.maps.Size(scaledSizeWidth, scaledSizeHeight),
                                            origin: new google.maps.Point(originX, originY),
                                            anchor: new google.maps.Point(anchorX, anchorY)
                                        },
                                        type: key,
                                        zIndex: 9,
                                        click: this.onClicMarker.bind(this),
                                        data: markerData,
                                        id: markerData.id
                                    }
                                );

                                //markers.push(marker);
                                allMarkers.push(marker);
                            }

                        });

                        //console.log(key);
                        if (markers.length > 0) {
                            markers.map((marker) => {
                                marker.nqType = key;
                            });
                            //Map.getCluster(key, markers);
                        }
                    }
                }
            }

            for (let keyStop in markersByStop) {
                let latlngs = [];

                let markersTemp = markersByStop[keyStop];
                markersTemp.map((marker) => {
                    latlngs.push(marker.latlng);
                });

                if (latlngs.length > 0) {
                    let centerMarkers = Map.averageGeolocation(latlngs);

                    markersTemp[0].options.data.stops = latlngs;
                    markersTemp[0].options.data.stopOptions = markersTemp[0].options;
                    let marker = Map.addMarker(
                        {
                            lat: parseFloat(centerMarkers.lat, 10),
                            lng: parseFloat(centerMarkers.lng, 10)
                        },
                        '',
                        markersTemp[0].options
                    );
                    marker.nqType = 'stop';
                    allMarkers.push(marker);
                }
            }

            /*for(let keyStop in markersByStop){
                Map.getSpider(keyStop, markersByStop[keyStop]);
            }*/


            Map.initSpider();

            let clusterAll = Map.getCluster('all', allMarkers);
            clusterAll.hide();

            document.dispatchEvent(new CustomEvent(CONSTANTES.keys.events.loadedApp, {
                detail: {
                    name: 'markers-init'
                }
            }));

            //action = 'markers-update';
            //data = data.categories;
        }

        if (action === 'markers-update') {
            this.hereMarker.setVisible(true);
            let clusterAll = Map.getCluster('all');
            clusterAll.show();
            let markers = Map.getAllMarkers();
            let markersDisplayed = [];

            clusterAll.clearMarkers();

            let bound = new google.maps.LatLngBounds();
            let boundsFull = new google.maps.LatLngBounds();

            for (var category in data) {
                for (var key in data[category]) {
                    if (key === 'all' || key === 'data') {
                        continue;
                    }

                    data[category][key].data.types.map((name) => {


                        //let cluster = Map.getCluster(name);
                        let hide = false;
                        if (data[category][key].display === false && data[category]['all'].display === false) {
                            hide = true;
                            //cluster.hide();
                        }/* else {

                            cluster.show();
                        }*/

                        markers.map((marker) => {
                            if (marker.nqType == name) {
                                marker.setVisible(!hide);

                                if (!hide) {
                                    marker.setMap(null);

                                    let positionMarker = marker.getPosition();
                                    let distance = Map.getDistance(
                                        positionMarker.lat(),
                                        positionMarker.lng(),
                                        CONSTANTES.markers.here.lat,
                                        CONSTANTES.markers.here.lng
                                    );

                                    if (distance < 5) {
                                        bound.extend(positionMarker);
                                    }
                                    boundsFull.extend(positionMarker);
                                    markersDisplayed.push(marker);
                                } else {
                                    marker.setMap(this.map);
                                }
                            }
                        });

                    });
                }
            }

            let office1 = Map.findMarker('ID17360');
            office1.setMap(this.map);
            office1.setVisible(true);

            let office2 = Map.findMarker('ID17358');
            office2.setMap(this.map);
            office2.setVisible(true);

            bound.extend(office1.getPosition());
            boundsFull.extend(office1.getPosition());
            bound.extend(office2.getPosition());
            boundsFull.extend(office2.getPosition());

            markersDisplayed.push(office1);
            markersDisplayed.push(office2);

            clusterAll.addMarkers(markersDisplayed);
            this.map.fitBounds(bound);

            let latBound = bound.getCenter().lat();
            let lngBound = bound.getCenter().lng();

            let distance = Map.getDistance(
                latBound,
                lngBound,
                CONSTANTES.markers.here.lat,
                CONSTANTES.markers.here.lng
            );

            if (markersDisplayed.length < 5 && markersDisplayed.length > 0) {
                this.map.fitBounds(boundsFull);

            } else if (distance > 10) {
                this.map.setCenter({
                    lat: CONSTANTES.markers.here.lat,
                    lng: CONSTANTES.markers.here.lng
                });
                this.map.setZoom(DEFAULT_ZOOM);
            }

        }

        if (action === 'layers-init' || action === 'layers-update') {
            let collection = data;
            if (action === 'layers-init') {
                Map.removeLayers();
                collection = data.categories;
            }

            for (let category in collection) {
                for (let subcategory in collection[category]) {
                    if (subcategory === 'all' || subcategory === 'data') {
                        continue;
                    }
                    collection[category][subcategory].data.layers.map((layerInfo) => {

                        for (let key in layerInfo) {
                            let layer = Map.addLayer(layerInfo[key].url, key, 'kml');
                            if (action === 'layers-init') {
                                layer.setOptions({
                                    preserveViewport: true,
                                    clickable: false,
                                    suppressInfoWindows: true
                                });
                            }

                            Map.setVisibility(layer, layerInfo[key].display);
                        }


                    });

                }
            }

            if (action === 'layers-init') {
                document.dispatchEvent(new CustomEvent(CONSTANTES.keys.events.loadedApp, {
                    detail: {
                        name: 'layers-init'
                    }
                }));
            }
        }

        if (action === 'move') {
            this.map.panTo(data);
        }

        if (action === 'move-and-zoom') {
            this.map.panTo(data.latlng);
            this.map.setZoom(data.zoom);
        }

        if (action === 'go-search') {

            Map.removeMarkers('search');
            if (data.type === 'PLACE') {
                let markerSearch = Map.findMarker('ID' + data.idBrute);
                if (markerSearch !== false) {
                    markerSearch.setVisible(true);
                    markerSearch.setMap(this.map);
                    document.dispatchEvent(new CustomEvent(CONSTANTES.keys.events.actionMap, {
                        detail: {
                            action: 'open-marker',
                            data: markerSearch
                        }
                    }));
                    this.map.panTo(markerSearch.getPosition());
                    this.map.setZoom(19);
                    //DISPLAY CATEGORY !
                    return;
                }
                //OPEN ARTICLE
            }


            let icon = MARKERS_DEF['search.png'].icon;
            let infoMarkerIcon = icon.url;
            let width = icon.size[0];
            let height = icon.size[1];
            let scaledSizeWidth = icon.scaledSize[0];
            let scaledSizeHeight = icon.scaledSize[1];
            let anchorX = width / 2;
            let anchorY = height / 2;

            Map.addMarker({
                    lat: data.lat,
                    lng: data.lng
                }, '',
                {
                    icon: {
                        url: infoMarkerIcon,
                        size: new google.maps.Size(width, height),
                        scaledSize: new google.maps.Size(scaledSizeWidth, scaledSizeHeight),
                        origin: new google.maps.Point(0, 0),
                        anchor: new google.maps.Point(anchorX, anchorY)
                    },
                    type: 'search',
                    zIndex: 99999
                });
            this.map.panTo(data);
        }

        if (action === 'clean-search') {
            Map.removeMarkers('search');
        }

        if (action === 'move-default-position') {
            this.map.panTo({
                lat: CONSTANTES.markers.here.lat,
                lng: CONSTANTES.markers.here.lng
            });
            this.map.setZoom(DEFAULT_ZOOM)
        }

        if (action === 'zoom') {
            let wantedZoom = data.plus ? this.map.zoom + 1 : this.map.zoom - 1;
            this.map.setZoom(wantedZoom);
        }

        if (action === 'clean-direction' || action === 'direction') {
            document.dispatchEvent(new CustomEvent(CONSTANTES.keys.events.actionMap, {
                detail: {
                    action: 'close-marker'
                }
            }));
        }

        if (action === 'clean-direction') {
            Map.removeMarkers('direction');
            Map.removePolylines('direction');
        }

        if (action === 'direction') {
            Map.removeMarkers('direction');
            Map.removePolylines('direction');
            let elm = document.querySelector('.nq-c-Itinerary-body');
            if (elm !== null) {
                elm.scrollTop = 0;
            }

            let markers = [];
            let strokeColor = CONSTANTES.colors[this.defaultMode.toLowerCase()];
            if (
                typeof (data[this.defaultMode]) !== 'undefined' &&
                typeof (data[this.defaultMode].data.journeys) !== 'undefined'
            ) {
                if (data[this.defaultMode].data.journeycount > 0) {


                    let journey = data[this.defaultMode].data.journeys[0];

                    let lastLatLng = false;
                    for (let y = 0, ly = journey.paths.length; y < ly; y++) {
                        let path = journey.paths[y];

                        if (path.mode === CONSTANTES.keys.transportModes.BUS) {
                            /*let temp = [{
                                lat: path.start.lat,
                                lng: path.start.lon
                            }];

                            temp.push({
                                lat: path.end.lat,
                                lng: path.end.lon
                            });


                            Map.addPolyline(temp, {
                                    type: 'direction',
                                    zIndex: 9999,
                                    strokeOpacity: 0,
                                    strokeColor: strokeColor,
                                    icons: [{
                                        icon: {
                                            path: 'M 0,-1 0,1',
                                            strokeOpacity: 1,
                                            scale: 4
                                        },
                                        offset: '0',
                                        repeat: '20px'
                                    }],
                                }
                            );*/

                            let temp = [{
                                lat: path.start.lat,
                                lng: path.start.lon
                            }];

                            path.stoppoints.map((stop) => {
                                temp.push({
                                    lat: stop.lat,
                                    lng: stop.lon
                                });
                            });

                            temp.push({
                                lat: path.end.lat,
                                lng: path.end.lon
                            });

                            Map.addPolyline(temp, {
                                    type: 'direction',
                                    zIndex: 9999,
                                    strokeOpacity: 0,
                                    strokeColor: strokeColor,
                                    icons: [{
                                        icon: {
                                            path: 'M 0,-1 0,1',
                                            strokeOpacity: 1,
                                            scale: 4
                                        },
                                        offset: '0',
                                        repeat: '20px'
                                    }],
                                }
                            );

                            if (y === (journey.paths.length - 1)) {
                                lastLatLng = {
                                    lat: path.end.lat,
                                    lng: path.end.lon
                                };
                            }

                        } else {
                            if (path.shapes.length > 1) {
                                path.shapes.map((shape) => {
                                    Map.addPolyline(shape, {
                                            geodesic: true,
                                            strokeColor: strokeColor,
                                            strokeOpacity: 1.0,
                                            strokeWeight: 6,
                                            type: 'direction',
                                            zIndex: 9999
                                        }
                                    );
                                });
                            } else if (path.shape) {
                                let polyline = Map.addPolyline(path.shape, {
                                        geodesic: true,
                                        strokeColor: strokeColor,
                                        strokeOpacity: 1.0,
                                        strokeWeight: 6,
                                        type: 'direction',
                                        zIndex: 9999
                                    }
                                );

                                let p = polyline.getPath();

                                if (y < (journey.paths.length - 1)) {

                                    p.push(new google.maps.LatLng(path.end.lat, path.end.lon));
                                    polyline.setPath(p);

                                }

                                lastLatLng = {
                                    lat: p.getAt(p.getLength() - 1).lat(),
                                    lng: p.getAt(p.getLength() - 1).lng()
                                };

                            }
                        }

                        if (
                            path.mode === CONSTANTES.keys.transportModes.WALK ||
                            path.mode === CONSTANTES.keys.transportModes.CAR ||
                            path.mode === CONSTANTES.keys.transportModes.BIKE
                        ) {

                            for (let z = 0, lz = path.indications.length; z < lz; z++) {
                                let indication = path.indications[z];

                                markers.push({
                                    lat: indication.lat_start,
                                    lng: indication.lon_start,
                                    icon: CONSTANTES.images.icons.number
                                });
                            }
                        }

                        if (path.mode === CONSTANTES.keys.transportModes.BUS) {
                            markers.push({
                                lat: path.start.lat,
                                lng: path.start.lon,
                                icon: CONSTANTES.images.icons.number
                            });

                            markers.push({
                                lat: path.end.lat,
                                lng: path.end.lon,
                                icon: CONSTANTES.images.icons.number
                            });
                        }
                    }

                    if (lastLatLng !== false) {
                        Map.addPolyline([
                                lastLatLng,
                                {
                                    lat: data[this.defaultMode].data.to.lat,
                                    lng: data[this.defaultMode].data.to.lon
                                }], {
                                geodesic: true,
                                strokeColor: strokeColor,
                                strokeOpacity: 1.0,
                                strokeWeight: 6,
                                type: 'direction',
                                zIndex: 9999
                            }
                        );
                    }
                    markers.push({
                        lat: data[this.defaultMode].data.to.lat,
                        lng: data[this.defaultMode].data.to.lon,
                        icon: CONSTANTES.images.icons.endDirection
                    });

                } else {
                    let nextMode = false;
                    for (let mode in data) {
                        if (
                            typeof (data[mode]) !== 'undefined' &&
                            typeof (data[mode].data.journeys) !== 'undefined'
                        ) {
                            if (data[mode].data.journeycount > 0) {
                                nextMode = mode;
                                break;
                            }
                        }
                    }

                    if (nextMode === false) {
                        document.dispatchEvent(new CustomEvent(CONSTANTES.keys.events.actionMap, {
                            detail: {
                                action: 'clean-direction',
                                data: true
                            }
                        }));
                        alert('Itinéraire introuvable');
                    } else {
                        if (CONSTANTES.debug) {
                            console.log('change mode ', nextMode);
                        }
                        this.props.directionStateActions.changeMode(nextMode);

                    }

                }

            }


            let increment = 0;


            if (markers.length > 0) {
                this.map.setCenter({
                    lat: markers[0].lat,
                    lng: markers[0].lng
                });
            }

            if (typeof (data.addresses) !== 'undefined') {
                if (data.addresses.start === 'ADDRESS|' + CONSTANTES.markers.here.lat + ',' + CONSTANTES.markers.here.lng) {
                    markers.shift();
                    increment = 1;
                }

                if (data.addresses.stop === 'ADDRESS|' + CONSTANTES.markers.here.lat + ',' + CONSTANTES.markers.here.lng) {
                    markers.pop();
                }
            }

            let bound = new google.maps.LatLngBounds();
            for (var i = 0, l = markers.length; i < l; i++) {


                let marker = markers[i];
                let icon = marker.icon;
                if (icon.indexOf(CONSTANTES.images.icons.number) !== -1) {
                    icon += (i + 1 + increment);
                }

                let m = Map.addMarker({
                    lat: markers[i].lat,
                    lng: markers[i].lng
                }, icon, {
                    type: 'direction',
                    zIndex: 9999
                });

                bound.extend(m.getPosition());
            }
            this.map.fitBounds(bound);
        }

    }

    init(key) {

        this.loaded.push(key);
        if (key === 'map') {
            this.initMap();
        }

        if (this.loaded.length === 3) {
            this.props.updateMap(this.map);
        }
    }

    initMap() {

        //return true;

        var mapOptions = {
            zoom: DEFAULT_ZOOM,
            //minZoom: 15,
            center: {
                lat: CONSTANTES.markers.here.lat,
                lng: CONSTANTES.markers.here.lng
            },
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            disableDefaultUI: true,
            scaleControl: true,
            styles: [
                {
                    featureType: "poi",
                    stylers: [
                        {visibility: "off"}
                    ]
                },
                {
                    featureType: 'poi.business',
                    stylers: [{visibility: 'off'}]
                },
                {
                    featureType: 'transit',
                    elementType: 'labels.icon',
                    stylers: [{visibility: 'off'}]
                }
            ]
        };

        this.map = window.map = new google.maps.Map(document.getElementById(idWrapperGoogleMap), mapOptions);
        Map.setMap(this.map);


        /*this.map.addListener('zoom_changed', (e) => {
            //20 = 100 = 5
            //16 = 0 = 0
            let opacity = (this.map.getZoom() - 16) * 100 / 5;
            console.log(this.map.getZoom(), opacity);
            Map.setOpacityOnMarkers(opacity/100);
        });*/

        let infoMarkerIcon = MARKERS_DEF[CONSTANTES.markers.here.url].icon;

        this.hereMarker = Map.addMarker(
            {lat: parseFloat(CONSTANTES.markers.here.lat), lng: parseFloat(CONSTANTES.markers.here.lng)},
            '',
            {
                icon: {
                    url: infoMarkerIcon.url,
                    size: new google.maps.Size(infoMarkerIcon.size[0], infoMarkerIcon.size[1]),
                    scaledSize: new google.maps.Size(infoMarkerIcon.scaledSize[0], infoMarkerIcon.scaledSize[1])
                },
                id: 'here',
                zIndex: 999999
            },
            true
        );
        this.hereMarker.setVisible(false);
    }

    render() {
        return (<div className="nq-c-Map" id={idWrapperGoogleMap}></div>);
    }
}

export default connect(
    state => ({
        direction: state.direction,
        layer: state.layer
    }),
    dispatch => ({
        directionStateActions: bindActionCreators(DirectionStateActions, dispatch)
    })
)(Gmap);