/*global google*/
import React, {Component} from 'react';
import moment from 'moment';

import ListingScrollIndicator from 'components/listing/ListingScrollIndicator';

import NoImage from 'assets/img/no-image.jpg';

class ListingArticle extends Component {

    state = {
        scrollIndicator: true
    };


    constructor(props) {
        super(props);
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentWillReceiveProps(props) {
        let {articles} = props;

        if (typeof (articles) === 'undefined') {
            alert('Connexion internet indisponible');
            document.location.reload();
            return;
        }

        this.setState({
            scrollIndicator: articles.length > 6
        })
    }

    addEventScroll() {
        if (typeof (this.list) === 'undefined') {
            return;
        }
        this.list.removeEventListener('scroll', this.handleScroll);
        this.list.addEventListener('scroll', this.handleScroll);
    }

    handleScroll(event) {
        let {articles} = this.props;

        if (articles.length <= 6) {
            this.setState({
                scrollIndicator: false
            });
            return true;
        }

        if (this.state.scrollIndicator && this.list.scrollTop > 0) {
            this.setState({
                scrollIndicator: false
            });
        } else if (!this.state.scrollIndicator && this.list.scrollTop === 0) {
            this.setState({
                scrollIndicator: true
            });
        }
    }

    buildDate(article) {
        if (article.date_end && article.date_start) {

            let start = moment(article.date_start);
            let end = moment(article.date_end);
            let now = moment();
            let tomorow = moment().add(1, 'd');

            let startDisplay = start.format('DD') + ' ' + start.format('MMM');
            let endDisplay = end.format('DD') + ' ' + end.format('MMM');

            let nowDisplay = now.format('DD') + ' ' + now.format('MMM');
            let tomorowDisplay = tomorow.format('DD') + ' ' + tomorow.format('MMM');

            if (nowDisplay === startDisplay) {
                startDisplay = 'Auj.';
            }

            if (nowDisplay === endDisplay) {
                endDisplay = 'Auj.';
            }

            if (tomorowDisplay === startDisplay) {
                startDisplay = 'Dem.';
            }

            if (tomorowDisplay === endDisplay) {
                endDisplay = 'Dem.';
            }

            if (
                article.date_start.indexOf('01-01') !== -1 &&
                article.date_end.indexOf('12-31') !== -1
            ) {
                return false;
            }

            if (article.date_end == article.date_start) {
                return (
                    <div className="nq-c-ListingArticlesItem-date">
                        <span className="nq-c-ListingArticlesItem-date-from">{startDisplay}</span>
                    </div>
                );
            } else {
                return (
                    <div className="nq-c-ListingArticlesItem-date">
                        <span className="nq-c-ListingArticlesItem-date-from">{startDisplay}</span>
                        <span className="nq-c-ListingArticlesItem-date-sep">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8"><path
                            fill="#FFF" fillRule="evenodd"
                            d="M6.87437905,0.00605401757 C6.59910078,0.0338451335 6.33845329,0.152784052 6.12912069,0.343981011 L0.501900236,5.4662428 C0.218234733,5.7074615 0.0392355296,6.05982758 0.00574016979,6.4433427 C-0.0277551901,6.82685782 0.0863380445,7.20923467 0.321855978,7.50160199 C0.558416287,7.79505067 0.895457316,7.97290698 1.25763594,7.99738001 C1.61981457,8.0207233 1.97569607,7.88733106 2.24365895,7.62721123 L7.00000005,3.29633888 L11.7563411,7.62721123 C12.024304,7.88732537 12.3801855,8.02071476 12.7423642,7.99738001 C13.1045428,7.97403673 13.4415865,7.79506775 13.6781441,7.50271181 C13.9136594,7.20926312 14.0277553,6.82796765 13.9942599,6.4433427 C13.9607646,6.05982758 13.7817734,5.70747288 13.4980999,5.4662428 L7.8708794,0.343981011 C7.59560114,0.0927596812 7.23551262,-0.0295172449 6.87437905,0.00605401757 Z"
                            transform="rotate(-180 7 4)"/></svg>
                    </span>
                        <span className="nq-c-ListingArticlesItem-date-to">{endDisplay}</span>
                    </div>
                );
            }
        }
        return false;
    }

    buildArticle(article, index) {
        let {subcategories, changePage, category} = this.props;

        let subcategory = '';
        if (typeof (subcategories[article['category-blog']]) !== 'undefined') {
            subcategory = subcategories[article['category-blog']].name;
        }

        let name = article.name;

        if (name.length > 50) {
            name = name.substr(0, 50) + '...';
        }

        let dateDom = this.buildDate(article);
        if (dateDom === false && category === 'agenda') {
            return (null);
        }

        let image = article.image ? article.image : NoImage;
        return (
            <li
                className="nq-c-ListingArticlesItem"
                key={'article_' + index}
                onClick={changePage.bind(this, 'article', article)}
            >
                <div className="nq-c-ListingArticlesItem-header">
                    {dateDom}

                    <div className="nq-c-ListingArticlesItem-img" style={{
                        backgroundImage: `url(${image})`,
                        backgroundSize: 'cover'
                    }}>

                    </div>
                </div>
                <div className="nq-c-ListingArticlesItem-content">
                    <div className="nq-c-ListingArticlesItem-category">{subcategory}</div>

                    {category !== 'agenda' && (
                        <div className="nq-c-ListingArticlesItem-distance">{article.distanceHumanize}</div>)}
                    <div className="nq-c-ListingArticlesItem-title">{name}</div>
                </div>
            </li>
        );
    }

    render() {
        let {scrollIndicator} = this.state;
        let {articles, isFetching, isLoaded} = this.props;

        let listDom = articles.map((article, index) => this.buildArticle(article, index));
        let countArticles = 0;
        listDom.map((item) => {
            if (item !== null) {
                countArticles++;
            }
        });


        if (isFetching) {
            return (
                <div className="nq-c-ListingArticles">
                    <div className="nq-c-ListingArticles-no-result">Chargement...</div>
                </div>
            );
        }

        if ((articles.length === 0 || countArticles === 0) && isLoaded) {
            return (
                <div className="nq-c-ListingArticles">
                    <div className="nq-c-ListingArticles-no-result">Aucun résultat</div>
                </div>
            );
        }

        return (
            <div className="nq-c-ListingArticles" ref={(el) => {
                if (el !== null) {
                    this.list = el;
                    this.addEventScroll();
                }
            }}>
                <ul className="nq-c-ListingArticles-list">
                    {listDom}
                </ul>
                {scrollIndicator && articles.length > 9 && <ListingScrollIndicator/>}
            </div>
        );
    }
}

export default ListingArticle;