import direction from 'reducers/direction';
import search from 'reducers/search';
import marker from 'reducers/marker';
import layer from 'reducers/layer';
import markerCategories from 'reducers/marker-categories';
import main from 'reducers/main';
import siri from 'reducers/siri';
import meteo from 'reducers/meteo';
import favorites from 'reducers/favorites';
import articles from 'reducers/articles';
import background from 'reducers/background';
import sncf from 'reducers/sncf';
import hightarticles from 'reducers/hightarticles';

const reducers = {
    direction,
    search,
    marker,
    layer,
    markerCategories,
    main,
    siri,
    meteo,
    favorites,
    articles,
    background,
    sncf,
    hightarticles
}

export default reducers;