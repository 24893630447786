import React, {Component} from 'react';

import TabMain from 'components/navigations/TabMain';
import Interests from 'components/Interests';
import SliderDiscoverHome from 'components/sliders/SliderDiscoverHome';
import ListingCategory from 'components/listing/ListingCategory';

import ImagePMR from 'assets/img/text-PMR.png';

class DiscoverHome extends Component {

    static defaultProps = {
        style: {},
        changePage: function () {
        }
    };

    render() {
        let {style, changePage, currentPage} = this.props;

        return (
            <div className={'nq-c-Body nq-c-Body-discover'} style={style} data-pattern="true">

                <img src={ImagePMR} className="image-pmr image-pmr-home" />

                <TabMain
                    currentPage={currentPage}
                    changePage={changePage}
                />
                <Interests/>

                <SliderDiscoverHome
                    changePage={changePage}
                />
                
                <ListingCategory
                    changePage={changePage}
                />
            </div>
        );
    }
}

export default DiscoverHome;
