import moment from 'moment';

export default {
    displayDuration: (time) => {
        if (time < 0) {
            return '';
        }

        if (time < 60) {
            return time + ' s';
        }

        if (time >= 60 && time < 60 * 60) {
            let seconds = parseInt(time % 60);
            let minute = parseInt(time / 60, 10) + (seconds > 0 ? 1 : 0) + ' min';

            // if (seconds > 0) {
            //     return minute + ' ' + seconds + ' s'
            // }
            return minute;
        }

        if (time >= 60 * 60) {
            let hour = parseInt(time / (60 * 60)) + ' h.';
            let minute = parseInt(time % (60 * 60) / 60);

            if (minute > 0) {

                if(minute < 10){
                    minute = '0' + minute;
                }
                return hour + minute + ' m';
            }
            return hour;
        }
        //return time;
    },
    displayDistance: (distance) => {
        if (distance === false) {
            return '';
        }
        let formated = distance;

        if (distance < 1000) {
            formated = distance + ' m'
        } else {
            formated = (distance / 1000).toFixed(2) + ' km'
        }

        return formated;
    }
}