import Axio from 'tools/Axio';

let initialState = {
    isFetching: false,
    isLoaded: false,
    didErrors: false,
    data: [],
    error: null,
    currentType: ''
};

// Actions
const RECEIVE_SEGMENTS = 'Search/Receive';
const RECEIVE_ERROR = 'Search/Error';
const RECEIVE_LOAD = 'Search/Load';
const RECEIVE_RESET = 'Search/Reset';

export const reset = () => {
    return (dispatch) => {
        dispatch({
            type: RECEIVE_RESET
        });
    };
};

//add cache 30min
export const fetch = (address, type) => {
    return (dispatch) => {
        dispatch({
            type: RECEIVE_LOAD,
            inputType: type
        });

        //CUURENT SEARCH A ESQUIVE
        Axio(
            'search/',
            {
                address
            },
            function (datas) {
                //TODO MANAGE ERRORS
                if (datas) {
                    if (
                        datas.error === true
                    ) {
                        dispatch({
                            type: RECEIVE_ERROR,
                            datas: datas.data,
                            inputType: type
                        });
                    } else {
                        dispatch({
                            type: RECEIVE_SEGMENTS,
                            datas: datas.data,
                            inputType: type
                        });
                    }
                }
            },
            function () {
                dispatch({
                    type: RECEIVE_ERROR,
                    datas: 'Erreur',
                    inputType: type
                });
            },
            'post'
        );
    }

};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case RECEIVE_RESET:
            return {
                ...state,
                isFetching: false,
                isLoaded: false,
                didErrors: false,
                data: [],
                error: null,
                currentType: ''
            };
        case RECEIVE_LOAD:
            return {
                ...state,
                isFetching: true,
                isLoaded: false,
                didErrors: false,
                error: '',
                data: [],
                currentType: action.inputType
            };
        case RECEIVE_SEGMENTS:
            return {
                ...state,
                isLoaded: true,
                isFetching: false,
                didErrors: false,
                error: '',
                data: action.datas,
                currentType: action.inputType
            };
        case RECEIVE_ERROR:
            return {
                ...state,
                isFetching: false,
                isLoaded: true,
                didErrors: true,
                data: [],
                error: 'Adresse inconnue',
                currentType: action.inputType
            };
        default:
            return state;
    }
}
