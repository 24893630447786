import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import * as FavoritesStateActions from 'reducers/favorites';

import CONSTANTES from 'configs/defines';

class ArticleContent extends Component {

    onFav() {
        let {dataPage} = this.props;

        dataPage.nqId = dataPage.ID;
        this.props.favoritesStateActions.toogle(dataPage);
    }

    onGoTo() {
        let {changePage, dataPage} = this.props;

        changePage('find');

        document.dispatchEvent(new CustomEvent(CONSTANTES.keys.events.goTo, {
            detail: {
                name: dataPage.name,
                id: 'ADDRESS|' + dataPage.latlng.lat + ',' + dataPage.latlng.lng,
                type: 'stop'
            }
        }));
    }

    render() {
        let {dataPage, favorites} = this.props;

        let isFavorite = false;
        if (favorites.data.length > 0) {
            favorites.data.map((favorite) => {
                if (typeof (favorite.nqId) !== 'undefined') {
                    if (favorite.nqId === dataPage.ID) {
                        isFavorite = true;
                    }
                }
            });
        }

        let html = '' +
            dataPage.description +
            '<br/>' +
            dataPage.description_full
        ;

        return (
            <div className={'nq-c-ArticleContent'} data-full={false}>

                <div className={'nq-c-ArticleContent-header'}>
                    <div
                        onClick={this.onGoTo.bind(this)}
                        className={'nq-c-Button'}
                    ><span>Y aller</span></div>

                    <div className={'nq-c-Button'} data-border={!isFavorite} onClick={this.onFav.bind(this)}>
                        {
                            !isFavorite ? (
                                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18">
                                    <polygon fill="#FFF" fillRule="evenodd"
                                             points="9.5 0 12.169 6.534 19 7.003 13.76 11.268 15.498 18 9.5 14.4 3.502 18 5.24 11.268 0 7.003 6.831 6.534"></polygon>
                                </svg>
                            ) : (null)
                        }

                        {
                            isFavorite ? (
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19">
                                    <g fill="none" fillRule="evenodd">
                                        <path fill="#FFFFFF"
                                              d="M5.93969192 0C5.4203007 0 5 .447275 5 1 5 1.55175 5.4203007 2 5.93969192 2L12.0603081 2C12.5796993 2 13 1.55175 13 1 13 .447275 12.5796993 0 12.0603081 0L5.93969192 0zM17.0374423 3L.962608283 3C.43055062 3 0 3.4294541 0 3.95806826 0 4.48761654.43055062 4.91613652.962608283 4.91613652L1.92521657 4.91613652 2.96021299 16.3652918 2.95927204 16.3652918C3.08523896 17.858537 4.34208291 19.0054884 5.84709689 18.9999795L12.1529031 18.9999795C13.6579171 19.0055938 14.9148669 17.858537 15.040728 16.3652918L16.0757244 4.91613652 17.0383327 4.91613652 17.0373917 4.91613652C17.5694494 4.91613652 18 4.48761654 18 3.95806826 18 3.4294541 17.5694494 3 17.0373917 3L17.0374423 3z"></path>
                                        <g fill="#FFF" transform="translate(5 6)">
                                            <path
                                                d="M-2.06030808,4.00025162 C-2.5796993,4.00025162 -3,3.97164828 -3,4.52437328 C-3,5.07612328 -2.5796993,4.99837231 -2.06030808,4.99837231 L4.06030808,4.99837231 C4.5796993,4.99837231 5,5.07612328 5,4.52437328 C5,3.97164828 4.49393955,4.00025162 3.97454834,4.00025162 L-2.06030808,4.00025162 Z"
                                                transform="rotate(90 1 4.501)"></path>
                                            <path
                                                d="M0.939691915,4.00025162 C0.420300701,4.00025162 0,3.97164828 0,4.52437328 C0,5.07612328 0.420300701,4.99837231 0.939691915,4.99837231 L7.06030808,4.99837231 C7.5796993,4.99837231 8,5.07612328 8,4.52437328 C8,3.97164828 7.49393955,4.00025162 6.97454834,4.00025162 L0.939691915,4.00025162 Z"
                                                transform="rotate(90 4 4.501)"></path>
                                            <path
                                                d="M3.93969192,4.00025162 C3.4203007,4.00025162 3,3.97164828 3,4.52437328 C3,5.07612328 3.4203007,4.99837231 3.93969192,4.99837231 L10.0603081,4.99837231 C10.5796993,4.99837231 11,5.07612328 11,4.52437328 C11,3.97164828 10.4939396,4.00025162 9.97454834,4.00025162 L3.93969192,4.00025162 Z"
                                                transform="rotate(90 7 4.501)"></path>
                                        </g>
                                    </g>
                                </svg>
                            ) : (null)
                        }
                        <span>{isFavorite ? 'Retirer de ma liste' : 'Ajouter à ma liste'}</span>
                    </div>
                </div>

                <div className={'nq-c-ArticleContent-text'}
                     dangerouslySetInnerHTML={{
                         __html: html
                     }}
                />
            </div>
        );
    }
}

export default connect(
    state => ({
        favorites: state.favorites
    }),
    dispatch => ({
        favoritesStateActions: bindActionCreators(FavoritesStateActions, dispatch)
    })
)(ArticleContent);