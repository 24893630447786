import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import moment from "moment";

import * as SiriStateActions from 'reducers/siri';

import * as Map from 'tools/Gmap';

import Loader from "components/Loader";


moment.locale('fr', {
    relativeTime : {
        future : 'dans %s',
        past : 'il y a %s',
        s : '< 1 min',
        m : 'une minute',
        mm : '%d min.',
        h : '1 h.',
        hh : '%d h.',
        d : '1 j.',
        dd : '%d j.',
        M : '1 mois',
        MM : '%d mois',
        y : '1 an',
        yy : '%d ans'
    }
});

class Stop extends Component {
    prevSiriID = false;

    constructor(props) {
        super(props);

        let {marker} = props;
        let data = marker.nqData;
        let dataPlace = data.data;

        this.init(dataPlace.id_siri);
    }

    componentWillReceiveProps(props) {
        let {marker} = props;
        let data = marker.nqData;
        let dataPlace = data.data;

        if (this.prevSiriID !== dataPlace.id_siri) {
            this.init(dataPlace.id_siri);
        }
    }

    init(idSiri) {
        this.prevSiriID = idSiri;
        this.props.siriStateActions.reset();

        this.props.siriStateActions.fetch(idSiri.substring(0, idSiri.length - 1));
    }

    render() {
        let {marker, onClose, goTo, siri} = this.props;

        if (typeof (marker.nqData) === 'undefined') {
            return (null);
        }

        let data = marker.nqData;
        let dataPlace = data.data;
        console.log(dataPlace, data);

        let markerTicketNearly = Map.findMarker(dataPlace.ticketNearly);
        let markerTicketNearlyData = false;
        let markerTicketNearlyDataPlace = false;
        if (markerTicketNearly !== false) {
            markerTicketNearlyData = markerTicketNearly.nqData;
            markerTicketNearlyDataPlace = markerTicketNearly.nqData.data;
        }

        let infoSiri = [];

        if (siri.isFetching) {
            infoSiri.push(
                <Loader key={'infoLoader'}/>
            );
        } else {
            if (siri.isLoaded) {
                if (siri.data.length > 0) {
                    siri.data.map((info, index) => {
                        let date = moment(info.timeBruteJS);
                        infoSiri.push(
                            <tr key={'stop' + index}>
                                <td className="nq-c-MapTransportPopin-station">
                                    <div className="nq-c-TransportNumber nq-c-MapTransportPopin-station-number">
                                        <img src={info.icon}/>
                                    </div>
                                    <div className="nq-c-MapTransportPopin-station-name">{info.direction}</div>
                                </td>
                                <td>
                                    <div className="nq-c-MapTransportPopin-station-time">{date.fromNow(true)}</div>
                                </td>
                            </tr>
                        );
                    });

                    infoSiri = (
                        <table className="nq-c-MapTransportPopin-table">
                            <thead>
                            <tr>
                                <th>Prochains départs</th>
                                <th>dans</th>
                            </tr>
                            </thead>
                            <tbody>
                            {infoSiri}
                            </tbody>
                        </table>
                    );
                } else {
                    infoSiri.push(<span className="nq-c-MapTransportPopin-noresult" key={'no-result'}>Pas de prochain départ proche</span>);
                }
            }
        }

        return (
            <div className="nq-c-MapTransportPopin" data-active={true}>
                {markerTicketNearlyData && (<div className="nq-c-MapTransportPopin-more">
                    <div className="nq-c-MapTransportPopin-near">
                        <div className="nq-c-MapTransportPopin-near-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="57" height="74" viewBox="0 0 57 74">
                                <path fill="#3A3A3A" fillRule="evenodd" stroke="#FFF" strokeWidth="2"
                                      d="M27.5217241,0 C12.3442694,0 0,12.5545522 0,27.9948774 C0,43.070017 25.4138071,70.4024914 26.4920921,71.5544966 C26.7572846,71.8424979 27.1241164,72 27.5219051,72 C27.9020091,72 28.273265,71.8424979 28.5384574,71.5544966 C29.6124654,70.4024914 55,43.0688649 55,27.9948774 C55,12.5557042 42.6693081,0 27.522403,0 L27.5217241,0 Z"
                                      transform="translate(1 1)"></path>
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                                <path fill="#FFF" fillRule="evenodd"
                                      d="M19.5,7 C18.671461,7 18,6.32853899 18,5.5 C18,4.67146101 18.671461,4 19.5,4 C20.328539,4 21,4.67146101 21,5.5 C21,6.32853899 20.328539,7 19.5,7 M24.3604547,9.12595994e-06 L24.3574749,9.12595994e-06 L14.6123246,9.12595994e-06 C14.0755833,9.12595994e-06 13.5608183,0.211693746 13.1797729,0.588931365 L0.470067642,13.1750516 C0.166870288,13.4726749 0,13.868514 0,14.288907 C0,14.7096721 0.166870288,15.1058832 0.470067642,15.4027625 L9.77271373,24.5401719 C10.0848506,24.846724 10.4945767,25 10.9050478,25 C11.3166364,25 11.7297148,24.8456079 12.0448315,24.5357075 L24.4111118,12.0615681 C24.7884323,11.6809823 25,11.1672099 25,10.6314879 L25,0.630970666 C24.999255,0.280891179 24.7113293,-0.00185102009 24.3604547,9.12595994e-06"></path>
                            </svg>
                        </div>
                        <div className="nq-c-MapTransportPopin-near-content">
                            <div className="nq-c-MapTransportPopin-near-label">Point de vente le + proche</div>
                            <div className="nq-c-MapTransportPopin-near-address">
                                <p>{markerTicketNearlyData.name}<br/>{markerTicketNearlyDataPlace.address}<br/>{markerTicketNearlyDataPlace.postcode} {markerTicketNearlyDataPlace.city}
                                </p>
                                <div
                                    className="nq-c-Button"
                                    data-border="true"
                                    onClick={goTo.bind(this, markerTicketNearlyDataPlace)}
                                >
                                    Y aller
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)}
                <div className="nq-c-MapTransportPopin-content">
                    <div className="nq-c-MapTransportPopin-close" onClick={onClose}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
                            <path fill="#3A3A3A" fillRule="evenodd"
                                  d="M9.2585369,7.5 L14.6402993,2.11823763 C15.1199002,1.63863665 15.1199002,0.839301701 14.6402993,0.359700729 C14.1606983,-0.119900243 13.3613633,-0.119900243 12.8817624,0.359700729 L7.5,5.7414631 L2.11823763,0.359700729 C1.63863665,-0.119900243 0.839301701,-0.119900243 0.359700729,0.359700729 C-0.119900243,0.839301701 -0.119900243,1.63863665 0.359700729,2.11823763 L5.7414631,7.5 L0.359700729,12.8817624 C-0.119900243,13.3613633 -0.119900243,14.1606983 0.359700729,14.6402993 C0.839301701,15.1199002 1.63863665,15.1199002 2.11823763,14.6402993 L7.5,9.2585369 L12.8817624,14.6402993 C13.3613633,15.1199002 14.1606983,15.1199002 14.6402993,14.6402993 C15.1199002,14.1606983 15.1199002,13.3613633 14.6402993,12.8817624 L9.2585369,7.5 Z"></path>
                        </svg>
                    </div>
                    <div className="nq-c-MapTransportPopin-title">{data.name}</div>

                    {infoSiri}
                </div>
            </div>
        );
    }
}

//

export default connect(
    state => ({
        siri: state.siri
    }),
    dispatch => ({
        siriStateActions: bindActionCreators(SiriStateActions, dispatch)
    })
)(Stop);

/*
<div className="nq-c-MapTransportPopin-work">
                        <div className="nq-c-MapTransportPopin-work-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="31" height="27" viewBox="0 0 31 27">
                                <g fill="none" fillRule="evenodd">
                                    <path fill="#FF7900"
                                          d="M17.3283701,1.03830148 C16.9527788,0.395345253 16.2543478,0 15.501055,0 C14.7477623,0 14.0514413,0.395345253 13.6737399,1.03830148 L0.283276044,23.8788533 C-0.094425348,24.5238903 -0.094425348,25.3166615 0.283276044,25.9596178 C0.660977436,26.6046547 1.35729844,27 2.11059116,27 L28.8894088,27 C29.6427016,27 30.3390226,26.6046547 30.716724,25.9596178 C31.0944253,25.3166615 31.0944253,24.5238903 30.716724,23.8788533 L17.3283701,1.03830148 Z"></path>
                                    <circle cx="15.5" cy="20.5" r="1.5" fill="#FFF"></circle>
                                    <path fill="#FFF"
                                          d="M15.5,8 C14.6715729,8 14,8.67157288 14,9.5 L14,15.5 C14,16.3284271 14.6715729,17 15.5,17 C16.3284271,17 17,16.3284271 17,15.5 L17,9.5 C17,8.67157288 16.3284271,8 15.5,8 Z"></path>
                                </g>
                            </svg>
                        </div>
                        <div className="nq-c-MapTransportPopin-work-content">
                            Ligne A : Travaux jusqu'au 22 juin 2019.
                            <a href="#">En savoir plus</a>
                        </div>
                    </div>
 */
