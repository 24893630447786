/*global google*/
import React, {Component} from 'react';

class Loader extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        return (
            <div className={'nq-c-Loader'}>
                <div className={'nq-c-Loader-wrapper'} data-number={'1'}>
                    <div className={'nq-c-Loader-ball'}>
                    </div>
                </div>
                <div className={'nq-c-Loader-wrapper'} data-number={'2'}>
                    <div className={'nq-c-Loader-ball'}>
                    </div>
                </div>
                <div className={'nq-c-Loader-wrapper'} data-number={'3'}>
                    <div className={'nq-c-Loader-ball'}>
                    </div>
                </div>
                <div className={'nq-c-Loader-wrapper'} data-number={'4'}>
                    <div className={'nq-c-Loader-ball'}>
                    </div>
                </div>
                <div className={'nq-c-Loader-wrapper'} data-number={'5'}>
                    <div className={'nq-c-Loader-ball'}>
                    </div>
                </div>
            </div>
        );
    }
}

export default Loader;