/*global google*/
import React, {Component} from 'react';

import LogoCulture from 'assets/img/icons/listing/culture.svg';
import LogoEntertainment from 'assets/img/icons/listing/entertainment.svg';
import LogoEat from 'assets/img/icons/map/eat.svg';
import LogoAgenda from 'assets/img/icons/listing/agenda.svg';
import LogoFreshAir from 'assets/img/icons/listing/fresh_air.svg';
import LogoFun from 'assets/img/icons/listing/fun.svg';
import LogoShop from 'assets/img/icons/map/shop.svg';
import LogoNews from 'assets/img/icons/listing/news.svg';


import ImgShop from 'assets/img/banners/shop.jpg';
import ImgFreshAir from 'assets/img/banners/fresh-air.jpg';
import ImgFun from 'assets/img/banners/fun.jpg';
import ImgEntertainment from 'assets/img/banners/entertainment.jpg';
import ImgEat from 'assets/img/banners/eat.jpg';
import ImgCulture from 'assets/img/banners/culture.jpg';
import ImgAgenda from 'assets/img/banners/agenda.jpg';

let categories = {
    culture: {
        label: 'Culture <br/>& patrimoine',
        icon: LogoCulture,
        special: false,
        img: ImgCulture
    },
    entertainment: {
        label: 'Divertissement',
        icon: LogoEntertainment,
        special: false,
        img: ImgEntertainment
    },
    eat: {
        label: 'Restaurants <br/>& bars',
        icon: LogoEat,
        special: false,
        img: ImgEat
    },
    agenda: {
        label: 'Voir l\'agenda',
        icon: LogoAgenda,
        special: true,
        img: ImgAgenda
    },
    fresh_air: {
        label: 'Plein air <br/>& aventure',
        icon: LogoFreshAir,
        special: false,
        img: ImgFreshAir
    },
    fun: {
        label: 'Fun & loisirs',
        icon: LogoFun,
        special: false,
        img: ImgFun
    },
    shop: {
        label: 'Shopping',
        icon: LogoShop,
        special: false,
        img: ImgShop
    },
    agendaa: {
        label: '',
        icon: false,
        special: true,
        img: false
    }
};

class ListingCategory extends Component {
    buildCategory(key) {
        let {changePage} = this.props;

        let category = categories[key];
        category.key = key;
        if(key === 'agendaa'){
            category = categories['agenda'];
            category.key = 'agenda';
        }

        return (
            <li
                className="nq-c-ListingCategoriesItem"
                key={'category_' + key}
                data-special={category.special}
                onClick={changePage.bind(this, 'listing', category)}
            >
                <a href="#">
                    <div className="nq-c-ListingCategoriesItem-icon">
                        {key !== 'agendaa' && category.icon && (<img src={category.icon} alt=""/>)}
                    </div>
                    <div className="nq-c-ListingCategoriesItem-title">
                        {key !== 'agendaa' && (<span dangerouslySetInnerHTML={{__html: category.label}}></span>)}
                    </div>
                </a>
            </li>
        );
    }

    render() {
        return (
            <div className="nq-c-ListingCategories">
                <div className="nq-c-ListingCategories-title">J'ai envie de...</div>
                <ul className="nq-c-ListingCategories-list">
                    {Object.keys(categories).map((category, index) => this.buildCategory(category))}
                </ul>
            </div>
        );
    }
}

export default ListingCategory;