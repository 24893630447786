import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import ImageLoader from 'react-loading-image';
import 'moment/locale/fr';

import * as BackgroundMarkerStateActions from 'reducers/background';

import BackgroundImg from 'assets/img/covid.jpg';

import IconWash from 'assets/img/icons/covid/icon_wash.png';
import IconTouch from 'assets/img/icons/covid/icon_tap.png';

class Home extends Component {

	render() {
		let {style} = this.props;

		return (
			<div className="nq-c-Home" style={style}>

				<ImageLoader
					src={BackgroundImg}
					image={props => <img className="nq-c-Home-bg" {...props} />}
				/>

				<div className="nq-c-Home-content">
					<p style={{
						fontSize: 28,
						color: '#FFFFFF',
						textTransform: 'uppercase',
						textAlign: 'center',
						width: 926,
						maringBottom: 14
					}}>Visiteurs ou habitants de Chambéry </p>
					<p style={{
						fontSize: 72,
						fontWeight: 'bold',
						color: '#FFFFFF',
						textAlign: 'center',
						width: 926,
						marginBottom: 46
					}}>Retrouvez ici toutes <br />les infos pratiques <br />pour profiter pleinement <br />de la ville !
					</p>
					<p style={{
						fontSize: 28,
						fontWeight: 'bold',
						color: '#FFFFFF',
						textAlign: 'center',
						width: 926,
						marginBottom: 84
					}}>Que vous soyez de passage ou résident, ce totem vous donne les adresses utiles, les évènements à
						venir, les itinéraires et moyens de transport pour optimiser vos déplacements et votre
						découverte de la ville.</p>

					<div style={{
						width: 926,
						height: 567,
						backgroundColor: 'white'
					}}>
						<div style={{
							height: 130,
							width: '100%',
							borderBottom: '1px #E5E5E5 solid',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center'
						}}>
							<span style={{
								fontSize: 34,
								color: '#000000',
								fontFamily: 'Montserrat',
								fontWeight: 700,
							}}>Respectons les gestes barrières</span>
						</div>
						<div style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'center',
							height: 435
						}}>
							<div style={{
								width: 275,
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								justifyContent: 'center'
							}}>
								<img src={IconWash} style={{
									width: 156,
									height: 161,
									marginBottom: 45
								}} />
								<p style={{
									fontSize: 20,
									textAlign: 'center',
									color: '#000000',
									fontFamily: 'Montserrat',
									fontWeight: 500,
								}}>Lavez-vous les mains<br /> au gel hydro-alcoolique<br /> avant de toucher l’écran</p>
							</div>
							<div style={{
								width: 275,
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								justifyContent: 'center'
							}}>
								<img src={IconTouch} style={{
									width: 149,
									height: 166,
									marginBottom: 30
								}} />
								<p style={{
									fontSize: 20,
									textAlign: 'center',
									color: '#000000',
									fontFamily: 'Montserrat',
									fontWeight: 500,
								}}>Parcourez<br /> l’écran tactile</p>
							</div>
							<div style={{
								width: 275,
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								justifyContent: 'center'
							}}>
								<img src={IconWash} style={{
									width: 156,
									height: 161,
									marginBottom: 45
								}} />
								<p style={{
									fontSize: 20,
									textAlign: 'center',
									color: '#000000',
									fontFamily: 'Montserrat',
									fontWeight: 500,
								}}>Lavez-vous<br /> à nouveau les mains<br /> après utilisation</p>
							</div>
						</div>
					</div>

					<div
						onClick={this.props.onClose}
						className={'nq-c-Button'}
						style={{
							width: 424,
							height: 100,
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							marginTop: 100
						}}
					><span style={{
						fontSize: 28,
						textAlign: 'center'
					}}>Accéder au contenu</span></div>

				</div>
			</div>
		);
	}
}

export default connect(
	state => ({
		background: state.background
	}),
	dispatch => ({
		backgroundMarkerStateActions: bindActionCreators(BackgroundMarkerStateActions, dispatch)
	})
)(Home);
