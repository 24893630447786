import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import * as HightArticlesStateActions from 'reducers/hightarticles';

import {addFunction} from 'tools/EnterFrame';


import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from 'react-responsive-carousel';

class SliderDiscoverHome extends Component {

    slider = null;

    constructor(props) {
        super(props);

        addFunction({
            function: this.updateArticle.bind(this),
            timing: 60 * 10
        });

    }

    updateArticle(props) {
        if (typeof (props) === 'undefined') {
            props = this.props;
        }
        props.hightarticlesStateActions.fetch();
    }

    onChange(num) {
        if (typeof (num) !== 'undefined') {
            let {hightarticles} = this.props;
            let article = hightarticles.data[num];
            document.getElementById('slider-name-article').innerHTML = article.name;
        }
    }

    onPrev() {
        this.slider.onClickPrev();
    }

    onNext() {
        this.slider.onClickNext();
    }

    render() {
        let {hightarticles, changePage} = this.props;


        if (hightarticles.isLoaded === false && hightarticles.firstLoad === false) {
            return (null);
        }

        if(hightarticles.data.length <= 0){
            return (null);
        }

        //<div className="nq-c-ListingSliderItem-date">Aujourd'hui</div>
        let firstArticle = hightarticles.data[0];
        return (
            <div>
                <div>
                    <div className="nq-c-ListingSlider-bg" style={{backgroundImage: `url(${firstArticle.image})`}}></div>
                    <div className="nq-c-ListingSlider">
                        <Carousel
                            ref={(el) => {
                                if (el === null) {
                                    return;
                                }
                                this.slider = el;
                            }}
                            width={'600px'}
                            centerMode={true}
                            centerSlidePercentage={100}
                            showThumbs={false}
                            infiniteLoop={true}
                            showIndicators={false}
                            showStatus={false}
                            onChange={this.onChange.bind(this)}
                        >
                            {hightarticles.data.map((article, index) => {
                                return (
                                    <div
                                        key={'slide' + index}
                                        className="nq-c-ListingSliderItem"
                                         onClick={changePage.bind(this, 'article', article)}
                                    >
                                        <img src={article.image ? article.image : ''} alt=""
                                             className="nq-c-ListingSliderItem-img"/>

                                    </div>
                                );
                            })}
                        </Carousel>
                        <div className="nq-c-ListingSliderItem-content">
                            <div className="nq-c-ListingSliderItem-title" id={"slider-name-article"}>{firstArticle.name}</div>
                        </div>

                        {hightarticles.data.length > 1 && (
                            <div className="nq-c-ListingSlider-nav">
                                <div className="nq-c-ListingSlider-nav-prev" onClick={this.onPrev.bind(this)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14">
                                        <path fill="#FFF" fillRule="evenodd"
                                              d="M3.87437905,3.00605402 C3.59910078,3.03384513 3.33845329,3.15278405 3.12912069,3.34398101 L-2.49809976,8.4662428 C-2.78176527,8.7074615 -2.96076447,9.05982758 -2.99425983,9.4433427 C-3.02775519,9.82685782 -2.91366196,10.2092347 -2.67814402,10.501602 C-2.44158371,10.7950507 -2.10454268,10.972907 -1.74236406,10.99738 C-1.38018543,11.0207233 -1.02430393,10.8873311 -0.756341052,10.6272112 L4.00000005,6.29633888 L8.75634115,10.6272112 C9.02430402,10.8873254 9.38018552,11.0207148 9.74236415,10.99738 C10.1045428,10.9740367 10.4415865,10.7950677 10.6781441,10.5027118 C10.9136594,10.2092631 11.0277553,9.82796765 10.9942599,9.4433427 C10.9607646,9.05982758 10.7817734,8.70747288 10.4980999,8.4662428 L4.8708794,3.34398101 C4.59560114,3.09275968 4.23551262,2.97048276 3.87437905,3.00605402 Z"
                                              transform="matrix(0 1 1 0 -3 3)"/>
                                    </svg>
                                </div>
                                <div className="nq-c-ListingSlider-nav-next" onClick={this.onNext.bind(this)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14">
                                        <path fill="#FFF" fillRule="evenodd"
                                              d="M3.87437905,3.00605402 C3.59910078,3.03384513 3.33845329,3.15278405 3.12912069,3.34398101 L-2.49809976,8.4662428 C-2.78176527,8.7074615 -2.96076447,9.05982758 -2.99425983,9.4433427 C-3.02775519,9.82685782 -2.91366196,10.2092347 -2.67814402,10.501602 C-2.44158371,10.7950507 -2.10454268,10.972907 -1.74236406,10.99738 C-1.38018543,11.0207233 -1.02430393,10.8873311 -0.756341052,10.6272112 L4.00000005,6.29633888 L8.75634115,10.6272112 C9.02430402,10.8873254 9.38018552,11.0207148 9.74236415,10.99738 C10.1045428,10.9740367 10.4415865,10.7950677 10.6781441,10.5027118 C10.9136594,10.2092631 11.0277553,9.82796765 10.9942599,9.4433427 C10.9607646,9.05982758 10.7817734,8.70747288 10.4980999,8.4662428 L4.8708794,3.34398101 C4.59560114,3.09275968 4.23551262,2.97048276 3.87437905,3.00605402 Z"
                                              transform="rotate(90 4 7)"/>
                                    </svg>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

        );
    }
}

export default connect(
    state => ({
        hightarticles: state.hightarticles
    }),
    dispatch => ({
        hightarticlesStateActions: bindActionCreators(HightArticlesStateActions, dispatch)
    })
)(SliderDiscoverHome);



