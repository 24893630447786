import React, {Component} from 'react';

import TabMain from 'components/navigations/TabMain';
import TabMap from 'components/navigations/TabMap';
import Gmap from 'components/Gmap';
import InfoBulle from 'components/map/InfoBulle';

import Interests from 'components/Interests';

import ImagePMR from 'assets/img/text-PMR.png';

class Map extends Component {

    static defaultProps = {
        style: {},
        changePage: function () {
        }
    };

    state = {
        map: null
    };


    updateMap(map) {
        this.setState({
            map
        });
    }

    render() {
        let {style, changePage, currentPage} = this.props;

        return (
            <div className="nq-c-Body nq-c-Body-map" style={style}>
                <TabMain
                    currentPage={currentPage}
                    changePage={changePage}
                />

                <Interests/>

                <img src={ImagePMR} className="image-pmr image-pmr-map" />

                <Gmap
                    currentPage={currentPage}
                    updateMap={this.updateMap.bind(this)}
                />


                <TabMap gmap={this.state.map}/>

                <InfoBulle
                    changePage={changePage}
                />
            </div>
        );
    }
}

export default Map;
