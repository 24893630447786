import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducers from 'reducers';

import * as serviceWorker from './serviceWorker';

import Viewport from 'pages/Viewport';

const middleware = [
    thunk
];

const rootReducer = combineReducers({
    ...reducers
});

const composedEnhancers = compose(
    applyMiddleware(...middleware)
);

const store = createStore(
    rootReducer,
    composedEnhancers
);

ReactDOM.render((
    <Provider store={store}>
        <Viewport />
    </Provider>
), document.getElementById('root'));

serviceWorker.unregister();
