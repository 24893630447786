import React, {Component} from 'react';

import {getClosest} from 'tools/Dom';

class TabMain extends Component {

    state = {
        currentTab: 'find'
    }

    static defaultProps = {
        changePage: function () {
        }
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.setState({
            currentTab: this.props.currentPage
        });
    }

    componentWillReceiveProps(props) {
        if (props.currentPage !== this.state.currentTab) {
            this.setState({
                currentTab: props.currentPage
            });
        }
    }

    changePage(event) {
        const {target} = event;
        let {changePage} = this.props;

        let parent = getClosest(target, '.nq-c-Navbar-item');
        let nextCurrentTab = parent.getAttribute('data-type');
        this.setState({
            currentTab: nextCurrentTab
        });

        if (nextCurrentTab === 'discover') {
            nextCurrentTab = 'discover-previous';
        }
        changePage(nextCurrentTab);
    }

    render() {
        let {currentTab} = this.state;

        let active = currentTab !== 'find' ? 'discover' : 'find';
        if (currentTab === '') {
            active = '';
        }
        return (
            <div className="nq-c-Navbar" data-current={active}>
                <div className="nq-c-Navbar-content">
                    <div className="nq-c-Navbar-item" data-type="find" onClick={this.changePage.bind(this)}>
                        <span>Trouver</span>
                    </div>
                    <div className="nq-c-Navbar-item" data-type="discover" onClick={this.changePage.bind(this)}>
                        <span>Découvrir</span>
                    </div>
                </div>
            </div>
        );
    }
}

export default TabMain;
