import React, {Component} from 'react';

let contents = [
    'Navigation dans la carte',
    'Envoi de son itinéraire par email',
    'Navigation dans la liste des lieux et des évènements',
    'Ma liste d’intérêts',
    'Activer le mode pour les Personnes à Mobilité Réduite'
];

class HelpContent extends Component {

    buildContent(tab, index) {
        let {activeIndex, toggleTab} = this.props;
        let activeTab = index === activeIndex;

        return (
            <div className={'nq-c-HelpContent-tab'} key={'helpcontent_' + index} data-active={activeTab}>
                <div className={'nq-c-HelpContent-title'}>{tab.title}</div>
                <div className={'nq-c-HelpContent-text'} dangerouslySetInnerHTML={{__html: tab.content}}/>
            </div>
        )
    }

    render() {
        let {tabs} = this.props;

        return (
            <div className={'nq-c-HelpContent'}>
                {tabs.map((tab, index) => this.buildContent(tab, index))}
            </div>
        );
    }
}

export default HelpContent;
