import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import {getClosest} from 'tools/Dom';

import FiltersBig from 'components/map/FiltersBig';
import Toogle from 'components/map/Toogle';
import Filters from 'components/map/Filters';
import Search from 'components/map/Search';
import DirectionIndications from 'components/map/DirectionIndications';

import * as Keolis from 'tools/Keolis';

import * as DirectionStateActions from 'reducers/direction';
import * as SearchStateActions from 'reducers/search';
import * as MarkerStateActions from 'reducers/marker';
import * as LayerStateActions from 'reducers/layer';

import CONSTANTES from 'configs/defines';

let isAllChecked = true;

const initialState = {
    currentTab: 'search',//search itinerary filters
    big: true,
    addresses: {
        stop: '',
        start: 'current',
        search: ''
    },
    searchDirection: false,
    category: 'transports',
    categories: {
        transports: {
            all: {
                display: isAllChecked
            }
        },
        sleep: {
            all: {
                display: isAllChecked
            }
        },
        eat: {
            all: {
                display: isAllChecked
            }
        },
        services: {
            all: {
                display: isAllChecked
            }
        },
        visit: {
            all: {
                display: isAllChecked
            }
        },
        shop: {
            all: {
                display: isAllChecked
            }
        }
    }
};

class TabMap extends Component {

    state = initialState;

    timeoutSearch = null;
    loaded = [];
    currentDirection = false;

    constructor(props) {
        super(props);

        for (let i = 0; i < CONSTANTES.limits.step; i++) {
            this.state.addresses[CONSTANTES.keys.stepStateName + i] = '';
        }

        document.addEventListener(CONSTANTES.keys.events.actionMap, (event) => {
            this.goActionMap(event.detail);
        }, false);

        document.addEventListener(CONSTANTES.keys.events.reset, () => {


            this.setState({
                currentTab: 'search',
                addresses: {
                    stop: '',
                    start: 'current',
                    search: ''
                },
                big: true
            }, () => {
                this.props.layerStateActions.reset();
                this.props.markerStateActions.reset();
                this.props.searchStateActions.reset();
                this.props.directionStateActions.reset();

                this.loaded = ['map'];
                this.props.markerStateActions.fetch(() => {
                    this.props.layerStateActions.fetch();
                });

                this.onResetDirection();
                this.currentDirection = '';
            });


        }, false);

    }

    goActionMap(eventData) {
        let {action, data} = eventData;
        if (action === 'open-marker') {
            this.setState({
                big: false
            });
        }
    }

    preFilter(filterKey, tab = 'filters') {
        let categories = this.state.categories;

        for (let categoryKey in categories) {
            let value = true;
            if (categoryKey !== filterKey) {
                value = false;
            }
            for (let subcategoryKey in categories[categoryKey]) {
                if (typeof (categories[categoryKey][subcategoryKey].display) !== 'undefined') {
                    if (subcategoryKey === 'all') {
                        categories[categoryKey][subcategoryKey].display = value;
                    }
                }
            }
        }

        document.dispatchEvent(new CustomEvent(CONSTANTES.keys.events.actionMap, {
            detail: {
                action: 'layers-update',
                data: categories
            }
        }));

        document.dispatchEvent(new CustomEvent(CONSTANTES.keys.events.actionMap, {
            detail: {
                action: 'markers-update',
                data: categories
            }
        }));

        this.setState({
            categories,
            big: false,
            category: filterKey,
            currentTab: tab
        });

    }

    componentWillReceiveProps(props) {
        let {direction, gmap} = props;

        if (gmap !== null && this.loaded.indexOf('map') === -1) {
            this.loaded.push('map');
            this.props.markerStateActions.fetch(() => {
                this.props.layerStateActions.fetch();
            });
        }

        if (props.marker.isLoaded && this.loaded.indexOf('marker') === -1) {
            this.loaded.push('marker');

            let categories = this.state.categories;
            for (let category in props.marker.data) {
                for (let subcategory in props.marker.data[category]) {
                    if (typeof (categories[category][subcategory]) === 'undefined') {

                        let markers = props.marker.data[category][subcategory];

                        categories[category][subcategory] = {
                            display: false,
                            data: {
                                types: Object.keys(props.marker.data[category][subcategory]),
                                layers: []
                            }

                        };
                    }
                }
            }

            this.setState({
                categories: categories
            });

            document.dispatchEvent(new CustomEvent(CONSTANTES.keys.events.actionMap, {
                detail: {
                    action: 'markers-init',
                    data: {
                        categories: this.state.categories,
                        markers: props.marker.data
                    }
                }
            }));
        }

        if (props.layer.isLoaded && this.loaded.indexOf('layer') === -1) {

            this.loaded.push('layer');

            let categories = this.state.categories;

            for (let category in props.layer.data) {
                for (let subcategory in props.layer.data[category]) {
                    if (
                        typeof (categories[category]) !== 'undefined' &&
                        typeof (categories[category][subcategory]) !== 'undefined'
                    ) {
                        categories[category][subcategory].data.layers = [];
                    }
                }
            }

            for (let category in props.layer.data) {
                for (let subcategory in props.layer.data[category]) {
                    let dataLayer = props.layer.data[category][subcategory];
                    if (
                        typeof (categories[category]) !== 'undefined' &&
                        typeof (categories[category][subcategory]) !== 'undefined'
                    ) {
                        categories[category][subcategory].data.layers.push(dataLayer);
                    }
                }
            }

            this.setState({
                categories: categories
            });


            document.dispatchEvent(new CustomEvent(CONSTANTES.keys.events.actionMap, {
                detail: {
                    action: 'layers-init',
                    data: {
                        categories
                    }
                }
            }));

        }


        this.setState({
            searchDirection: direction.isFetching
        });

        if (direction.didErrors) {
            this.props.directionStateActions.reset();
            alert(direction.error);
            return;
        }

        if (!direction.isFetching && !direction.didErrors && direction.isLoaded) {
            this.loaded.push('direction_' + direction.defaultMode);
            this.setState({
                big: false
            });

            document.dispatchEvent(new CustomEvent(CONSTANTES.keys.events.actionMap, {
                detail: {
                    action: 'direction',
                    data: direction.data
                }
            }));

        }
    }

    onChangeCategory(event) {
        const {target} = event;
        let parent = getClosest(target, '.nq-c-FiltersHomeHeader');

        if (parent !== null) {
            let nextCategory = parent.getAttribute('data-type');
            this.setState({
                category: nextCategory
            });
        }
    }

    onChangeSubCategory(event) {

        let categories = this.state.categories;

        if (typeof (event) === 'string' && event === 'all') {

            for (let category in categories) {
                for (let subCategory in categories[category]) {
                    if (key !== 'data' && key !== 'all') {
                        categories[category][subCategory].display = false;
                    }
                }
            }

        } else {
            const {target} = event;

            //TOOD mettre des attribut pour category sous cateogrie ...
            let category = target.getAttribute('name');
            let subCategory = target.getAttribute('id').replace(category + '_', '');

            let keyLayer = false;
            if (category.indexOf('display') !== -1) {
                let temp = category.replace('display_', '').split('_');
                category = temp[0];
                subCategory = temp[1];
                keyLayer = temp[2];
            }

            if (keyLayer === false) {
                categories[category][subCategory].display = target.checked;

                if (subCategory === 'all' && target.checked) {
                    for (var key in categories[category]) {
                        if (key !== 'data' && key !== 'all') {
                            categories[category][key].display = false;
                        }
                    }
                }

                if (subCategory !== 'all') {
                    let allSuCatChecked = true;
                    for (var key in categories[category]) {
                        if (key !== 'data' && key !== 'all' && categories[category][key].display === false) {
                            allSuCatChecked = false;
                        }
                    }

                    if (allSuCatChecked) {
                        for (var key in categories[category]) {
                            if (key !== 'data' && key !== 'all') {
                                categories[category][key].display = false;
                            }
                        }
                        categories[category]['all'].display = true;
                    } else {
                        categories[category]['all'].display = false;
                    }
                }

            }

            if (keyLayer !== false) {
                categories[category][subCategory].data.layers.map((layers) => {

                    if (typeof (layers[keyLayer]) !== 'undefined') {
                        layers[keyLayer].display = target.checked;
                    }
                });
            }
        }

        document.dispatchEvent(new CustomEvent(CONSTANTES.keys.events.actionMap, {
            detail: {
                action: 'layers-update',
                data: categories
            }
        }));

        document.dispatchEvent(new CustomEvent(CONSTANTES.keys.events.actionMap, {
            detail: {
                action: 'markers-update',
                data: categories
            }
        }));

        this.setState({
            categories
        });

        document.dispatchEvent(new CustomEvent(CONSTANTES.keys.events.actionMap, {
            detail: {
                action: 'close-marker'
            }
        }));

        document.dispatchEvent(new CustomEvent(CONSTANTES.keys.events.actionMap, {
            detail: {
                action: 'close-direction'
            }
        }));


    }

    onChangeAddresses(type, value) {
        if (this.timeoutSearch !== null) {
            window.clearTimeout(this.timeoutSearch);
        }
        let addresses = this.state.addresses;
        addresses[type] = value;

        if (type === 'stop' && value === 'current' && addresses.start === 'current') {
            addresses.start = '';
        }

        if (type === 'start' && value === 'current' && addresses.stop === 'current') {
            addresses.stop = '';
        }

        this.setState({
            addresses
        });

        this.launchAction(type !== 'search' ? 'direction' : type);
        this.timeoutSearch = setTimeout(() => {
            if (typeof (value.id) === 'undefined') {
                this.props.searchStateActions.fetch(value, type);
            }

        }, 200);
    }

    onChangeTab(tab, big = true) {
        if (tab === this.state.currentTab) {
            this.setState({
                currentTab: '',
                big
            });
            return;
        }


        document.dispatchEvent(new CustomEvent(CONSTANTES.keys.events.actionMap, {
            detail: {
                action: 'close-marker'
            }
        }));

        this.setState({
            currentTab: tab,
            big
        })
    }

    onReverseDirection() {
        let addresses = this.state.addresses;
        let stop = addresses.stop;
        addresses.stop = addresses.start;
        addresses.start = stop;

        this.setState({
            addresses
        });
        this.launchAction('direction');
    }


    onResetDirection() {
        let addresses = this.state.addresses;
        addresses.start = '';
        addresses.stop = '';
        this.setState({
            addresses
        });

        this.props.directionStateActions.reset();
        document.dispatchEvent(new CustomEvent(CONSTANTES.keys.events.actionMap, {
            detail: {
                action: 'clean-direction',
                data: true
            }
        }));
    }

    onResetSearch() {
        let addresses = this.state.addresses;
        addresses.search = '';
        this.setState({
            addresses
        });
        this.props.searchStateActions.reset();
        document.dispatchEvent(new CustomEvent(CONSTANTES.keys.events.actionMap, {
            detail: {
                action: 'clean-search',
                data: true
            }
        }));

    }

    launchAction(type) {
        if (type == 'search') {
            let addresses = this.state.addresses;
            if (typeof (addresses.search.id) !== 'undefined') {


                if (this.state.big === true) {
                    this.preFilter('transports', 'search');
                }
                this.setState({
                    big: false
                });
                Keolis.closeFilterIfPMR();
                document.dispatchEvent(new CustomEvent(CONSTANTES.keys.events.actionMap, {
                    detail: {
                        action: 'go-search',
                        data: addresses.search
                    }
                }));
            }
        }

        if (type == 'direction') {
            let addresses = this.state.addresses;
            if (
                (typeof (addresses.start.id) !== 'undefined' || addresses.start === 'current') &&
                (typeof (addresses.stop.id) !== 'undefined' || addresses.stop === 'current')
            ) {
                let options = {
                    start: typeof (addresses.start.id) !== 'undefined' ? addresses.start.id : 'ADDRESS|' + CONSTANTES.markers.here.lat + ',' + CONSTANTES.markers.here.lng,
                    stop: typeof (addresses.stop.id) !== 'undefined' ? addresses.stop.id : 'ADDRESS|' + CONSTANTES.markers.here.lat + ',' + CONSTANTES.markers.here.lng
                };
                if (this.currentDirection !== JSON.stringify(options)) {


                    if (this.state.big === true) {
                        this.preFilter('transports', 'itinerary');
                    }

                    this.onResetSearch();
                    if (this.state.currentTab !== 'direction') {
                        this.setState({
                            currentTab: 'itinerary'
                        })
                    }
                    let temp = [];
                    this.loaded.map((key) => {
                        if (key.indexOf('direction_') === -1) {
                            temp.push(key);
                        }
                    });
                    this.loaded = temp;

                    this.currentDirection = JSON.stringify(options);

                    this.props.directionStateActions.fetch(options);

                    Keolis.closeFilterIfPMR();

                }


            }
        }

    }

    render() {
        let {
            currentTab,
            big,
            addresses,
            searchDirection,
            category,
            categories
        } = this.state;

        return (
            <div>
                <Toogle current={currentTab} big={big} onChangeTab={this.onChangeTab.bind(this)}/>
                <Search
                    current={currentTab}
                    big={big}
                    addresses={addresses}
                    fetching={searchDirection}
                    onChangeTab={this.onChangeTab.bind(this)}
                    onChangeAddresses={this.onChangeAddresses.bind(this)}
                    onReverseDirection={this.onReverseDirection.bind(this)}
                    onResetDirection={this.onResetDirection.bind(this)}
                    onResetSearch={this.onResetSearch.bind(this)}
                />

                <FiltersBig
                    current={currentTab}
                    big={big}
                    onChangeTab={this.onChangeTab.bind(this)}
                    preFilter={this.preFilter.bind(this)}
                />

                <Filters
                    current={currentTab}
                    big={big}
                    category={category}
                    categories={categories}
                    onChangeCategory={this.onChangeCategory.bind(this)}
                    onChangeSubCategory={this.onChangeSubCategory.bind(this)}
                />
                <DirectionIndications
                    current={currentTab}
                />
            </div>
        );
    }
}

export default connect(
    state => ({
        direction: state.direction,
        search: state.search,
        marker: state.marker,
        layer: state.layer
    }),
    dispatch => ({
        directionStateActions: bindActionCreators(DirectionStateActions, dispatch),
        searchStateActions: bindActionCreators(SearchStateActions, dispatch),
        markerStateActions: bindActionCreators(MarkerStateActions, dispatch),
        layerStateActions: bindActionCreators(LayerStateActions, dispatch)
    })
)(TabMap);


